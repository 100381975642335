<template lang="pug">
  .user-certificates.text-align-center
    h3.pane-title {{ certificationTitle }}

    //- loading screen
    p.large-margin-top.loading-tab.flex-middle-center(
      v-loading="true"
      v-if="certifications === null"
    )

    //- no certificate
    p.large-margin-top.no-content.flex-middle-center(v-else-if='!hasCertificate')
      strong Aucune {{ certificationLabel }} pour l'instant

    //- certificates
    table.large-margin-top.width-full(v-else)
      tr(
        v-for="lesson in certifications"
        v-if="lesson.course && lesson.class"
        :key="lesson.id"
      )
        td
          PiBadge(:conf='badgeParcours(lesson)', :class="{'disabled': !(isStart || isCompleted)}")

        td

          .from(v-if="lesson.classProgram.openingBoundaries.from")
            p.text-align-left.tc-success Ouverture :
              br
              | {{ lesson.classProgram.openingBoundaries.from | date }}
          .to(v-if="lesson.classProgram.openingBoundaries.to")
            p.text-align-left.tc-danger Fermeture :
              br
              | {{ lesson.classProgram.openingBoundaries.to | date }}

        td
          p
            strong {{ lesson.course.title }}
          p(:class='[`tc-${getProgressColor(lesson.scoreRatio)}`]') {{ lesson.scoreRatio * 1000 | round }} / 1000

        td
          p
            strong {{ lesson.class.name }}
          .flex-middle-center
            el-progress(
              type="circle"
              :width="20"
              :stroke-width="3",
              :percentage="lesson.percentProgression * 100",
              :show-text="false"
              :class="[`stroke-${getProgressColor(lesson.percentProgression)}`]"
              )
            span &nbsp;{{ lesson.percentProgression * 100 | round }}% accompli

        td
          a.btn__square.bg-quinary(
            :href="contextCertification + lesson.certificateUrl",
            rel="noopener",
            target="_blank"
            )
            | {{ certificationLabel }}
            i.el-icon-pi-fleche-bouton

</template>

<script>

export default {
  name: 'user-certificates',
  components: {
    PiBadge: () => import('@/components/Ui/PiBadge'),
    RateWithInfo: () => import('@/components/Ui/RateWithInfo'),

  },
  data () {
    return {
    }
  },
  computed: {
    hasCertificate () {
      return this.certifications?.length > 0
    },

  },
  methods: {
    getProgressColor (ratio) {
      if (ratio < 0.50) {
        return 'danger'
      } else if (ratio < 1) {
        return 'primary'
      }
      return 'success'
    },
    badgeParcours (lesson) {
      return {
        level: '',
        type: 'metier',
        icon: lesson?.course?.category?.icon || '',
        size: 'md',
      }
    },
  },
  asyncComputed: {
    certifications: {
      get () {
        return this.currentUserRecord.loadCertifications()
      },
      lazy: false,
      default: null,
    },
  },
}
</script>
<style lang="sass">
.user-certificates
  p
    margin: 0 0 5px
  .tc-success
    color: #13ce66 !important
  .tc-danger
    color: #ff4949 !important
.stroke-primary
  .el-progress-circle__path
    stroke: #20a0ff
.stroke-danger
  .el-progress-circle__path
    stroke: #ff4949
.stroke-success
  .el-progress-circle__path
    stroke: #13ce66

</style>
