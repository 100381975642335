<template lang="pug">
.pi-wysiwyg.wysiwyg-style
  .exerciceType.interactif(v-html="html", :class="`column-${_get(row.data, 'settings.column')}`")

</template>

<script>
import { get } from 'lodash'
import utils from '@/helpers/utils.js'

export default {
  name: 'pi-wysiwyg',
  components: {},
  mixins: [],
  props: ['row'],
  data () {
    return {}
  },
  computed: {
    html () {
      const $html = document.createElement('div')
      $html.innerHTML = get(this.row, 'data.html', '')

      const attachementsImages = $html.querySelectorAll('img[data-file-id]')

      if (attachementsImages.length) {
        for (const img of attachementsImages) {
          img.src = utils.getFilePath(img.attributes['data-file-id'].value)
        }
      }

      return $html.innerHTML
    },
  },
  watch: {},
  created () {
    window._vm_wysiwyg = this
  },
  methods: {},
}
</script>
