<template lang="pug">
  doctype html
  .pi-account.pi-form-content
    el-form(ref="form", :model="form", :rules="rules", label-position="top", v-if="form")
      h1.text-align-center {{ $t('components.account.title') }}
      el-row
        el-col(:span="24")
          el-form-item(
            v-if="contextAllowUserToSetLastname"
            :label="$t('attributes.name.title')" prop="lastname")
            el-input(v-model="form.lastname", data-semantic="about-lastname")
          el-form-item(
            v-if="contextAllowUserToSetFirstname"
            :label="$t('attributes.firstname.title')" prop="firstname")
            el-input(v-model="form.firstname", data-semantic="about-firstname")
          el-form-item.mail-input(
            v-if="contextAllowUserToSetMail"
            :label="$t('attributes.email.title')" prop="email")
            el-input(
              :disabled='!USE_REGISTER_BY_INVITATION && form.email'
              v-model="form.email"
              data-semantic="about-email"
            )
          el-form-item.username-input(
            v-if="USE_REGISTER_BY_INVITATION || contextAllowUserToChangeUsername"
            :label="$t('attributes.username.title')"
            prop="username"
          )
            el-input(
              :readonly="!contextAllowUserToChangeUsername"
              v-model="form.username"
              data-semantic="about-username"
            )
          el-form-item(
            v-if="contextAllowUserToSetBirthday"
            :label="$t('attributes.birthday.title')", prop="birthday")
            el-date-picker(
              format="dd MM yyyy"
              @focus="focusBirthday"
              ref='birthday'
              popper-class="date-picker-in-account",
              placeholder="Sélectionnez une date",
              type="date"
              v-model="form.birthday")
      el-row.dark.bg-tertiary(v-if="showPwd", style="padding: 20px 20px 5px 20px; margin-bottom:10px")
        el-col(:span="24")
          h3 {{$t('attributes.password.title')}}
          p
            template(v-if="!contextSimplePasswordForInvitationCode || !getCurrentUser.isFromInvitationCode")
              | Votre mot de passe doit contenir au moins 6 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.
            template(v-else)
              | Votre mot de passe doit contenir au moins 6 caractères.

          el-form-item(:label="$t('attributes.password.new')" prop="newpassword")
            el-input(v-model="form.newpassword" :type="passwordType").iconPadding
            .pwdHelper.hover(@click="seePassword")
              i.el-icon-fa-eye
      el-row
        el-col.actions(:span="24")
          a.btn__square.bg-tertiary(v-if="!showPwd", @click="showPwd = true") {{ $t('actions.change.password') }}
          a.btn__square.bg-tertiary(v-else="showPwd", @click="showPwd = false") {{$t('attributes.password.notChange')}}
          a.btn__square.bg-primary(@click="onUpdate('form')") {{ $t('actions.record.action') }}
      el-row.large-margin-top
        el-col(:span='24')
          el-alert(
            v-if="!_isEmpty(alert)",
            :title="alert.title",
            :type="alert.type",
            :description="alert.description",
            @close="closeAlert",
            show-icon)
</template>

<script>
import api from '@/api'
import { get } from 'lodash'

const customRuleNewPassword = (rule, value, callback) => {
  if (!(value && value.length > 0)) {
    return callback(new Error('Entrez votre nouveau mot de passe pour le changer'))
  } else if (/\s/.test(value)) {
    return callback(new Error('Les espaces ne sont pas autorisés'))
  } else {
    callback()
  }
}

export default {
  name: 'pi-account',
  components: {
  },
  mixins: [
  ],
  data () {
    return {
      alert: {},
      showPwd: false,
      lastemail: '',
      isEmailEdited: false,
      passwordType: 'password',
      form: {
        lastname: '',
        firstname: '',
        mail: '',
        password: '',
        newpassword: '',
        birthday: '',
        username: '',
      },
    }
  },
  computed: {
    rules () {
      const newpassword = [
        { min: 6, max: 254, message: 'Taille requise entre 6 et 254 caractères', trigger: 'blur' },
        { validator: customRuleNewPassword, trigger: 'blur' },
      ]
      if (!this.contextSimplePasswordForInvitationCode || !this.getCurrentUser.isFromInvitationCode) {
        newpassword.push({
          pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
          message: 'Doit contenir au moins : une majuscule, une minuscule, un chiffre et un caractère spécial',
          trigger: 'blur',
        })
      }
      return {
        lastname: [
          { required: true, message: 'Entrez un nom', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
        firstname: [
          { required: true, message: 'Entrez un prénom', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
        email: [
          { required: !this.USE_REGISTER_BY_INVITATION, type: 'email', message: 'Saisissez un e-mail valide', trigger: 'blur' },
          { min: 3, max: 254, message: 'Taille requise entre 3 et 254 caractères', trigger: 'blur' },
        ],
        newpassword,
        birthday: [
          { type: 'date', required: true, message: 'Choisissez votre date d\'anniversaire', trigger: 'change' },
        ],
        username: [
          { required: this.USE_REGISTER_BY_INVITATION, message: 'Entrez un pseudonyme', trigger: 'blur' },
          { min: 3, max: 60, message: 'Taille requise entre 3 et 60 caractères', trigger: 'blur' },
        ],
      }
    },
  },
  created () {
    this.getUserFromStore()
    this.setLayoutBackground(false)
  },
  methods: {
    closeAlert () {
      this.$set(this, 'alert', {})
    },
    focusBirthday () {
      this.$nextTick(() => {
        this.$refs.birthday.picker.currentView = 'year'
      })
    },
    getUserFromStore () {
      this.form.lastname = this.getCurrentUser.lastname
      this.form.firstname = this.getCurrentUser.firstname
      this.form.email = this.getCurrentUser.email
      this.form.username = this.getCurrentUser.username
      this.form.birthday = this.getCurrentUser.birthday ? this.moment(this.getCurrentUser.birthday).toDate() : undefined
    },
    seePassword () {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    onUpdate () {
      this.closeAlert()
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveDatasInUser()
        }
      })
    },
    saveDatasInUser () {
      const user = api.get('AppUser', this.getCurrentUser.id)
      user.lastname = this.form.lastname
      user.firstname = this.form.firstname
      user.username = this.form.username
      user.email = this.form.email
      user.birthday = this.form.birthday ? this.form.birthday.toISOString() : undefined
      if (this.showPwd) {
        user.password = this.form.newpassword
      }
      user.token = undefined
      user.save()
        .then(() => {
          this.$set(this, 'alert', {
            type: 'success',
            title: 'Merci',
            description: 'Vos données ont été mises à jour',
          })
          this.$store.dispatch('loadUser')
        }).catch(e => {
          const errorCode = get(e.response, 'data.code', 'UNDEFINED')

          this.$set(this, 'alert', {
            type: 'error',
            title: 'Erreur',
            description: this.$tc(`errors.${errorCode}.title`),
          })
        })
    },
  },
}
</script>
<style lang="sass">
html.loaded:not(.bordered-style)
  .pi-account
    .el-form-item label
      color: black
</style>
