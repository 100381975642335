<template lang="pug">
  .pi-auth-portal
    transition(name="fade")
      | {{ display }}
      PiRecover(
        v-if="display === 'recover'"
        @screen="switchDisplay"
      )
      PiRegisterWithInvitationCode(
        v-else-if="display === 'registerWithInvitationCode' && USE_REGISTER_BY_INVITATION"
        @screen="switchDisplay"
      )
      PiSignin(
        v-else
        @screen="switchDisplay"
      )
    el-row
      el-col.textAlign-justify(:span='24').display-flex.align-items-center.justify-content-center.large-margin
        router-link.medium-padding(:to='{ name : "LegalMentions"}') Mentions légales
        router-link.medium-padding(:to='{ name : "LegalDataProtection"}') Protection des données
  </template>

<script>
const PiRecover = () => import('./Recover')
const PiSignin = () => import('./SignIn')
const PiRegisterWithInvitationCode = () => import('./RegisterWithInvitationCode')

export default {
  components: {
    PiSignin,
    PiRecover,
    PiRegisterWithInvitationCode,
  },
  data() {
    return {
      display: 'signin',
    }
  },
  created() {
    this.setLayoutBackground(false)
  },
  methods: {
    switchDisplay(value) {
      this.display = value
    },
  },
}
</script>
