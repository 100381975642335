<template lang="pug">
  doctype html
  .pi-widget-section-title
    .pi-widget.interactif(v-if="type")
      h3.name {{sectionName}}.

</template>

<script>
export default {
  name: 'pi-widget-section-title',
  components: {},
  mixins: [],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'sectionName', 'dataset', 'datasetsAlreadyComputed'],
  data () {
    return {
      type: null,
    }
  },
  created () {
    window._vm_widget_section_title = this
  },
}
</script>
