<template lang="pug">
  doctype html
  .pi-video-external(:class="mainClass")
    div(
      :style="style"
    )
      vue-plyr(
        v-if="youtubeID"
        :emit="['ready']"
        :options="config"
        @ready="videoIsReady"
        )
        div(data-plyr-provider="youtube" :data-plyr-embed-id="youtubeID")

</template>

<script>
import { debounce, isString } from 'lodash'
import utils from '@/helpers/utils.js'

export default {
  name: 'pi-video-external',
  components: {},
  mixins: [],
  props: [
    'row',
    'nbSection',
    'rowIndex',
    'reponse',
    'conf',
    'wordPicked',
    'courseCategoryId',
    'course',
    'dataset',
    'datasetsAlreadyComputed',
    'rowCount',
  ],
  data () {
    return {
      resize: () => {},
      width: 'inherit',
      isInResizableWidget: false,
      config: {
        hideYouTubeDOMError: true,
        controls: [
          'restart',
          'play-large',
          'play',
          'fast-forward',
          'rewind',
          'progress',
          'current-time',
          'volume',
          'captions',
          'pip',
          'fullscreen',
        ],
        autoplay: true,
        volume: 1,
        clickToPlay: utils.globals.BROWSER.isDesktop,
        hideControls: utils.globals.BROWSER.isDesktop,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
        },
        youtube: {
          noCookie: false,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1,
          cc_load_policy: 1,
        },
        captions: {
          active: true,
          language: 'auto',
          update: true,
        },
      },
    }
  },
  computed: {
    mainClass () {
      return {
        'max-visible-coverage': !this.isInResizableWidget,
      }
    },
    style () {
      return {
        width: this.width,
        margin: 'auto',
      }
    },
    youtubeID () {
      const regexExpression = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?[\w?‌=]*)?/
      if (regexExpression.test(this.row.data.url) && isString(this.row.data.url.match(regexExpression)[1])) {
        return this.row.data.url.match(regexExpression)[1]
      }

      return null
    },
  },
  watch: {},
  created () {
    window._vm_video_external = this
  },
  mounted () {
    this.resize = debounce(this._resizeHandler, 200)
    this.setWidth()
    window.setTimeout(() => this.setWidth(), 1000)
    const resizableParent = this.$el.closest('.resizable') || window
    resizableParent.addEventListener('resize', this.resize)
  },
  beforeDestroy () {
    const resizableParent = this.$el.closest('.resizable') || window
    resizableParent.removeEventListener('resize', this.resize)
  },
  methods: {
    _resizeHandler () {
      this.setWidth()
    },
    setWidth () {
      let width = '100%'
      this.isInResizableWidget = Boolean(this.$el?.closest('.resizable'))
      const coverage = this.$el.closest('.resizable') || this.$el.closest('.brick-part-wrapper') || this.$el
      const isInBrickPage = !this.$el.closest('.resizable') && Boolean(this.$el.closest('.brick-part-wrapper'))
      if (coverage) {
        let { height } = coverage.getBoundingClientRect()
        if (isInBrickPage) {
          height -= coverage.querySelector('.brick-title').getBoundingClientRect().height
        }
        const maxwidth = Math.floor(height * 100 / 56.5) // 56.5 correspond à la valeur de plyr

        if (coverage.getBoundingClientRect().width > maxwidth) {
          width = `${maxwidth}px`
        } else if (!isInBrickPage) {
          width = `${coverage.getBoundingClientRect().width - 10}px`
        }
      }
      this.width = width
    },
    videoIsReady (event) {
      const iframe = event.target.querySelector('.plyr__video-wrapper')
      const player = event.detail.plyr
      if (iframe) {
        iframe.addEventListener('touchstart', () => {
          if (player.paused) {
            player.play()
          } else {
            player.pause()
          }
        }, false)
      }
      if (this.config.autoplay && player.paused) {
        player.play()
      }
    },
  },
}
</script>
