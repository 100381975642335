module.exports = [
	{
		"label": "1 semaine",
		"value": {
			"nbr": 7,
			"unit": "days"
		}
	},
	{
		"label": "1 mois",
		"value": {
			"nbr": 30,
			"unit": "days"
		}
	},
	{
		"label": "3 mois",
		"value": {
			"nbr": 12,
			"unit": "weeks"
		}
	},
	{
		"label": "6 mois",
		"value": {
			"nbr": 6,
			"unit": "months"
		}
	},
	{
		"label": "1 an",
		"value": {
			"nbr": 12,
			"unit": "months"
		}
	}
];