import PiRenderCourseNode from '@/components/Render/RenderCourseNode'
import PiRenderPage from '@/components/Render/RenderPage'
import PiRenderPageActions from '@/components/Render/RenderPageActions'
import PiRenderPageQuestions from '@/components/Render/RenderPageQuestions'
import PiBlockPreview from '@/components/Preview/BlockPreview'
import PiBOPageCorrection from '@/components/Correction/BOPageCorrection'
import PiPageCorrection from '@/components/Correction/PiPageCorrection'
import EvaluateModel from '@/components/Evaluate/Model'
import Pi404 from '@/components/Errors/404'
import Pi403 from '@/components/Errors/403'

const routes = [
  // NODES
  {
    path: '/render/node/:id',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderCourseNode,
    name: 'PiRenderCourseNode',
    props: true,
  },
  // SESSION
  {
    path: '/session/:id',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPage,
    name: 'PiRenderPage',
    props: true,
  },
  {
    path: '/session/:id/actions',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPageActions,
    name: 'PiRenderPageActions',
    props: true,
  },
  {
    path: '/session/:id/questions',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPageQuestions,
    name: 'PiRenderPageQuestions',
    props: true,
  },
  {
    path: '/preview',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiBlockPreview,
    name: 'preview',
  },
  {
    path: '/correction',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
      hasProgression: true,
    },
    component: PiBOPageCorrection,
    name: 'correction',
  },
  {
    path: '/correction/:pageId',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
      hasProgression: true,
    },
    component: PiPageCorrection,
    name: 'PiCorrectionPage',
    props: true,
  },
  // Evaluation de contenus
  {
    path: '/evaluate/:type/:id',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: EvaluateModel,
    name: 'EvaluateModel',
    props: true,
  },
  {
    path: '/404',
    meta: {
      hasOnlyContent: false,
      hasSidebar: false,
    },
    component: Pi404,
    name: '404',
  },
  {
    path: '/403',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
    },
    component: Pi403,
    name: '403',
  },
]

export default routes
