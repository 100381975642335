<template lang="pug">
  doctype html
  .pi-multiple-choice
    .exerciceType
      h4(v-exposant)
        | {{ parseShortCodes(row.message) }}
        pi-pop(:message="getOrder()")

    el-checkbox-group.questions(v-model='reponse.userAnswer', :class="'col-'+_get(row, 'settings.columns', 1)")
      el-checkbox.interactif(
        v-for="choice in shuffledChoices",
        :disabled="isCorrectionMode",
        :key="choice.index",
        :label="choice.index",
        :data-value="`sections[${nbSection}].rows[${rowIndex}].choices[${choice.index}]`",
        :class="correctionClass[choice.index]")
        div(:class="hasBr(choice) ? 'vertical' : 'inline'" v-exposant)
          img(v-if="_get(choice, 'appFile.id')", :src="getSrc(choice)")
          br(v-if="hasBr(choice)")
          template(v-if="!_get(choice, 'appFile.id') || _get(row, 'settings.showDescriptionIfImage')")
            | {{ parseShortCodes(choice.value) }}

</template>

<script>
/*
  settings.columns : nb de colonnes
  settings.showDescriptionIfImage : description des images
*/
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import utils from '@/helpers/utils.js'
import RenderMixin from '@/mixins/Render.js'
import {
  get,
  includes,
  isEmpty,
} from 'lodash'

export default {
  name: 'pi-multiple-choice',
  components: { PiPop },
  mixins: [RenderMixin],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'isCorrectionMode', 'correctAnswer', 'datasetsAlreadyComputed'],
  data () {
    return {
      defaultOrder: 'Je coche la ou les bonnes réponses',
    }
  },
  computed: {
    shuffledChoices () {
      this.row.choices.forEach((choice, index) => {
        choice.index = index
      })
      if (this.hasShuffle()) {
        return this.shuffleSeed(this.row.choices, this.$store.getters.getPageRandomizer)
      }
      return this.row.choices
    },
    onlyImage () {
      for (const i in this.row.choices) {
        const choice = this.row.choices[i]
        const fileId = get(choice, 'appFile.id', '')
        if (isEmpty(fileId)) {
          return false
        }
      }
      return true
    },
    correctionClass () {
      if (!this.isCorrectionMode) return ''
      const out = []
      for (const i in this.shuffledChoices) {
        const choice = this.shuffledChoices[i]
        const expectedAnswer = includes(this.correctAnswer.answer.all, choice.index)
        const goodAnswer = (
          (includes(this.correctAnswer.answer.all, choice.index) && includes(this.reponse.userAnswer, choice.index)) ||
          (!includes(this.correctAnswer.answer.all, choice.index) && !includes(this.reponse.userAnswer, choice.index))
        )
        const wrongAnswer = (includes(this.reponse.userAnswer, choice.index) && !expectedAnswer)

        out[choice.index] = { expectedAnswer: expectedAnswer, goodAnswer: goodAnswer, wrongAnswer: wrongAnswer }
      }
      return out
    },
  },
  watch: {},
  methods: {
    hasShuffle () {
      return !get(this.row, 'settings.keepOrder', false)
    },
    hasBr (choice) {
      return get(choice, 'appFile.id') && get(this.row, 'settings.showDescriptionIfImage') && get(this.row, 'settings.returnToLineAfterImage')
    },
    getSrc (choice) {
      return utils.getFilePath(get(choice, 'appFile.id'))
    },
  },
}
</script>
