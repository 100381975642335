<template lang="pug">
.pdf-wrapper(:class="mainClass")
  template(v-if="canReplacePageControllers")
    portal(
      v-if="numPages > 1 && page > 1"
      to="lesson-page-prev"
    )
        el-button.btn__square.bg-primary(
          @click="prev"
        )
          i.el-icon-pi-fleche-gauche
          span.mobile-display-none Précédent

    portal(
      v-if="numPages > 1 && page < numPages"
      to="lesson-page-next"
    )
        el-button.btn__square.bg-primary(
          @click="next"
        )
          span.mobile-display-none Suivant
          i.el-icon-pi-fleche-bouton.mobile-display-inline-block
  template(v-else-if="!navigationToolsAreVisible")
    .buttons.text-align-center.large-margin-top(v-if="numPages > 1")
      a.btn__square.bg-primary(
        @click="prev"
        :class="{ 'visibility-hidden': page === 1 }"
      )
        i.el-icon-pi-fleche-gauche
      strong.pagination
        | {{ page }} / {{numPages}}
      a.btn__square.bg-primary(
        @click="next"
        :class="{ 'visibility-hidden': page === numPages }"

      )
        i.el-icon-pi-fleche-bouton
  pdf(
    style="margin: 0 auto; width: fit-content"
    ref="pdf"
    :height="height"
    :width="width"
    :page="page"
    :source="url"
    @rendered="handleDocumentRender"
    @loaded="handleLoaded"
    :disableAnnotationLayer='false'
  )
  pi-sound-player.audio-file-description(
    v-if="hasAudioFile"
    :url="audioFileSrc",
    :autoplay="false",
    name="bonjour"
  )
</template>

<script>
import pdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { debounce } from 'lodash'
import utils from '@/helpers/utils.js'
import PiSoundPlayer from '@/components/Templates/PiSoundPlayer/PiSoundPlayer.vue'

const pdf = {
  extends: pdfEmbed,
  methods: {
    getPageDimensions (ratio) {
      let w, h
      if (!ratio) {
        w = this.width
        h = this.height
        return [w, h]
      }
      if (this.height && this.width) {
        h = this.height
        w = h / ratio

        if (w > this.width) {
          w = this.width
          h = w * ratio
        }
      } else if (this.height && !this.width) {
        h = this.height
        w = h / ratio
      } else {
        w = this.width || this.$el.clientWidth
        h = w * ratio
      }

      return [w, h]
    },
  },
}

export default {
  name: 'vue-pdf-viewer',
  components: {
    pdf,
    PiSoundPlayer,
  },
  props: {
    reduceHeight: Number,
    url: String,
    options: {
      type: Object,
      default: () => {},
    },
    audioFileId: {
      type: [Boolean, String],
      default: false,
    },
    canReplacePageControllers: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      observer: null,
      resize: () => {},
      page: 1,
      numPages: 1,
      rotate: 0,
      width: null,
      height: null,
      navigationToolsAreVisible: true,
      isVertical: false,
      transport: null,
    }
  },
  computed: {
    mainClass () {
      return {
        isVertical: this.isVertical,
        isHorizontal: !this.isVertical,
      }
    },
    hasAudioFile () {
      return Boolean(this.audioFileId)
    },
    audioFileSrc () {
      if (!this.hasAudioFile) return
      return utils.getFilePath(this.audioFileId)
    },
    constrain () {
      return true // get(this.options, 'constrain', false)
    },
  },
  watch: {
    isVertical (value) {
      this.setWidth()
      if (value) {
        this.height = null
      } else {
        this.setHeight()
      }
    },
  },
  mounted () {
    window._pdf = this
    this.setWidth()
    this.setHeight()
    this.resize = debounce(this._resizeHandler, 200)

    const resizableParent = this.$el.closest('.resizable') || window
    resizableParent.addEventListener('resize', this.resize)

    this.navigationToolsAreVisible = !document.querySelector('[data-name="lesson-page-next"]') || !document.querySelector('[data-name="lesson-page-prev"]')
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes') {
          this.resize()
        }
      })
    })
    this.observer.observe(document.documentElement, {
      attributes: true,
    })
  },
  beforeDestroy () {
    const resizableParent = this.$el.closest('.resizable') || window
    resizableParent.removeEventListener('resize', this.resize)
    this.observer?.disconnect()
  },
  methods: {
    setHeight () {
      if (this.$el && this.$el.closest('.widget-content')) {
        this.height = this.$el.closest('.widget-content').getBoundingClientRect().height
      } else if (document.querySelector('.temoin')) {
        this.height = document.querySelector('.temoin').getBoundingClientRect().height
      }
    },
    setWidth () {
      this.width = this.$el.getBoundingClientRect().width
      if (this.isVertical) {
        this.width = Math.min(this.width, 980)
        return
      }
      if (this.$el && this.$el.closest('.widget-content')) {
        this.width = this.$el.closest('.widget-content').getBoundingClientRect().width
      } else if (document.querySelector('.temoin')) {
        this.width = document.querySelector('.temoin').getBoundingClientRect().width
      }
    },
    _resizeHandler () {
      this.setWidth()
      this.setHeight()
    },
    handleLoaded (event) {
      this.transport = event._transport
    },
    handleDocumentRender () {
      if (this.transport.pageCache?.[0]?.view?.length >= 4) {
        this.isVertical = this.transport.pageCache[0].view[2] < this.transport.pageCache[0].view[3]
      }
      this.numPages = this.$refs?.pdf?.pageCount || 1
      if (this.$refs.pdf) {
        [...this.$refs.pdf.$el.querySelectorAll('a[href]')].forEach(el => {
          el.target = '_blank'
          el.rel = 'noopener noreferrer'
        })
      }
    },
    next () {
      this.page = Math.min(this.numPages, this.page + 1)
    },
    prev () {
      this.page = Math.max(1, this.page - 1)
    },
  },
}
</script>
<style lang="sass">
.pdf-wrapper
  .audio-file-description
    background-color: white
    box-shadow: 6px 6px 10px 0 rgba(0, 0, 0, 0.12)
    border: 1px solid rgba(0, 0, 0, 0.12)
    margin-top: 0
    width: 340px
    margin: 0 auto
    z-index: 1
    left: calc( 50% - 170px )
    .el-slider__runway
      margin: 10px 0

.vue-pdf-embed
  .annotationLayer
    position: absolute
    top: 0
    display: block
    section
      display: block

section[data-annotation-id]
  display: none
* + .pi-media-internal
  padding-top: 15px
* + .pdf-wrapper
  padding-top: 15px
.pdf-wrapper
  position: relative
  display: block
  padding: 0
  background: white
  .pagination
    margin: 10px
  & > .buttons
    display: flex
    flex-direction: row
    align-content: center
    align-items: center
    justify-content: center
  .btn__square
    margin: 5px
  .btn__square .el-icon-pi-fleche-bouton
    display: inline-block !important
.pdf-wrapper > span > canvas
  border-top: 1px solid var(--secondary-color)
  border-bottom: 1px solid var(--secondary-color)

.pi-resources-modal .pdf-wrapper
  & > .buttons
    margin-top: 10px
  & > span > canvas
    border-top: 1px solid #333
    border-bottom: 1px solid #333

.el-dialog__body > .pdf-wrapper
  width: 90vw
</style>
