<template lang="pug">
  doctype html
  .pi-chrono(
    :class='{ ending: ending, ended: ended }'
  )
    .ui-chrono
      .relative
        i.el-icon-pi-timer-brique
        .radial-progress.smal(:data-progress="percent", :data-duration="duration")
          .circle
            .mask.full
              .fill
            .mask.half
              .fill
              .fill.fix
      strong.time {{ time }}
</template>

<script>
export default {
  name: 'pi-chrono',
  components: {
  },
  mixins: [
  ],
  props: [
    'start',
    'end',
  ],
  data () {
    return {
      progress: 0,
      timer: null,
      endingTime: 5,
    }
  },
  computed: {
    time: function () {
      let hours = Math.floor(this.remaningTime / 3600)
      let minutes = Math.floor((this.remaningTime - (hours * 3600)) / 60)
      let seconds = this.remaningTime - (hours * 3600) - (minutes * 60)

      if (hours < 10) { hours = `0${hours}` }
      if (minutes < 10) { minutes = `0${minutes}` }
      if (seconds < 10) { seconds = `0${seconds}` }
      return `${minutes}"${seconds}`
    },
    ended () {
      return this.remaningTime <= 0
    },
    ending () {
      return this.remaningTime <= this.endingTime && !this.ended
    },
    duration () {
      return this.end
    },
    remaningTime () {
      return this.duration - this.progress
    },
    percent () {
      return Math.round((this.progress * 100) / this.duration)
    },
  },
  watch: {
    ended (value, oldValue) {
      if (value && !oldValue) {
        window.setTimeout(() => {
          this.$emit('chronoEnded')
        }, 500)
        this.progress = this.end
        clearInterval(this.timer)
      }
    },
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  created () {
    this.progress = this.start
    this.timer = setInterval(this.startChrono, 1000)
  },
  methods: {
    startChrono: function () {
      if (this.remaningTime <= 0) {
        clearInterval(this.timer)
      } else {
        this.progress = this.progress + 1
      }
    },
  },
}
</script>
