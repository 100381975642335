<template lang="pug">
  doctype html
  .pi-error.pi-view.text-align-center(:class='`.pi-error-${type}`')
    img(src="../../assets/images/404.png")
    .buttons
      el-button.btn__square.bg-primary(@click="backToHome({ clear: false })")
        | Retour au tableau de bord
        i.el-icon-pi-fleche-bouton
</template>

<script>

export default {
  name: 'pi404',
  props: ['type'],
  data () {
    return {
    }
  },
}
</script>
<style lang="sass">
.pi-error
  img
    max-width: 100%
  .buttons
    margin-top: -70px

</style>
