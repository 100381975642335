<template lang="pug">
.page-dashboard.pi-view
  .large-padding-top.large-padding-bottom.text-align-center.desktop-display-none.print-none
    select.native.width-100(v-model="activeTab")
      option(
        :value="tab.name"
        v-for="(tab, index) in tabs"
        :key="`option-${index}`"
      ) {{ tab.label || $tc(`components.dashboard.tabs.${tab.name}.name`) }}

  el-tabs.view-tabs.large-margin-left(
    v-if="!_isEmpty(tabs)"
    :value="activeTab"
    @tab-click="clickHandler($event.name)"
  )
    el-tab-pane(
      v-for="(tab, index) in tabs"
      :key="index"
      :name="tab.name"
      :class="tab.name"
    )
      h2(slot="label") {{ tab.label || $tc(`components.dashboard.tabs.${tab.name}.name`) }}

    router-view
</template>

<script>
export default {
  name: 'dashboard',
  data () {
    return {
      seeLevels: false,
      seeJobs: false,
    }
  },
  asyncComputed: {
    userStats: {
      get () {
        return this.currentUserRecord.getLastRecord()
      },
      lazy: false,
    },
  },
  computed: {
    hasStats () {
      return false
    },
    activeTab: {
      get () {
        return this.$route.meta.currentTab || null
      },
      set (value) {
        this.clickHandler(value)
      },
    },
    tabs () {
      const tabs = [
        {
          name: 'levels',
        },
        {
          name: 'jobs',
        },
      ]

      if (this.isDesktop) {
        tabs.push({ name: 'stats' })
      }

      if (this.contextAllowAccessToCertificationFeature) {
        tabs.push({
          name: 'certificates',
          label: this.certificationTitle,
        })
      }

      tabs.push({
        name: 'logbook',
      })
      return tabs
    },
  },
  watch: {
  },
  created () {
    window._vm_dashboard = this

    this.$store.dispatch('updateUserScore')
  },
  methods: {
    clickHandler (value) {
      if (this.activeTab === value) return
      switch (value) {
        case 'jobs':
          this.$router.push({ name: 'DashboardJobs' })
          break

        case 'stats':
          this.$router.push({ name: 'DashboardStats' })
          break

        case 'certificates':
          this.$router.push({ name: 'DashboardCertificates' })
          break

        case 'logbook':
          this.$router.push({ name: 'DashboardLogbook' })
          break

        case 'levels':
        default:
          this.$router.push({ name: 'DashboardLevels' })
          break
      }
    },
  },
}
</script>
<style lang="sass">
.page-dashboard
  .loading-tab,
  .no-content
    height: 50vh
</style>
