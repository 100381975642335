<template lang="pug">
  doctype html
  .preview-block
    template(v-if="messageReceived")
      pi-render-node(:id='courseNodeid', :previewMode="previewMode")
    template(v-else)
      br
      br
      br
      center
        strong(v-if="!errorMessage")
          | Attendez 2 secondes
        strong(v-else="!errorMessage")
          | {{ errorMessage }}
</template>z

<script>
import store from '@/api/index.js'
import PiRenderNode from '../Render/RenderCourseNode.vue'

window.store = store

export default {
  name: 'pi-block-preview',
  components: {
    PiRenderNode,
  },
  mixins: [],
  data () {
    return {
      errorMessage: '',
      id: null,
      messageReceived: false,
      courseNodeid: 'id-preview-course-node',
      previewMode: true,
    }
  },
  watch: {
  },
  mounted () {
    if (window.parent) {
      console.log('FRONT SEND: ', 'mounted')
      window.parent.postMessage('mounted', this.contextBackoffice)
    }
  },
  created () {
    window._vm_preview = this
    window.addEventListener('message', (event) => {
      if (~event.origin.indexOf(this.contextBackoffice)) {
        console.log('[ PREVIEW ] données reçues depuis le backoffice', event)
        event.data.id = 'id-block'
        store.add('CourseBlock', event.data)
        this.init()
      } else {
        console.error('error')
        this.errorMessage = `ERREUR : L'administration est "${event.origin}" au lieu de ${this.contextBackoffice}`
      }
    })
  },
  methods: {
    init () {
      this.messageReceived = true
      store.add('Course', {
        id: 'id-preview-course',
        title: 'Parcours aperçu',
        description: 'Parcours auto généré pour créer des aperçus d\'exercice',
        reference: 'ref-preview-course',
        createdAt: '2017-07-19T15:15:12.948Z',
        updatedAt: '2017-07-25T14:19:23.307Z',
        state: 'DRAFT',
        categoryId: 'id-category',
        typeId: 'id-preview-course-type',
        settings: {
          credit: 0,
        },
      })
      store.add('CourseType', {
        id: 'id-preview-course-type',
        title: 'Course Type Preview',
        description: 'Type de Parcours auto généré pour créer des aperçus d\'exercice',
        createdAt: '2017-07-19T15:15:12.948Z',
        updatedAt: '2017-07-25T14:19:23.307Z',
        settings: {
          hints: true,
          feedbacks: true,
          retry: true,
          competencies: false,
          categories: false,
          certificate: false,
        },
      })
      //   - CourseNode Container
      store.add('CourseNode', {
        id: 'id-preview-course-node-container',
        type: 'container',
        settings: {},
        tools: {},
        conditions: {},
        order: 0,
        courseId: 'id-preview-course',
        blockId: null,
        attachmentIds: [],
        createdAt: '2017-07-25T14:19:17.178Z',
        updatedAt: '2017-07-25T14:19:21.605Z',
        parentId: null,
        title: 'section preview',
      })
      //      - CourseNode Block
      store.add('CourseNode', {
        id: this.courseNodeid,
        type: 'block',
        settings: {},
        tools: {},
        conditions: {},
        order: 0,
        courseId: 'id-preview-course',
        blockId: 'id-block',
        attachmentIds: [],
        createdAt: '2017-07-25T14:19:17.178Z',
        updatedAt: '2017-07-25T14:19:21.605Z',
        parentId: 'id-preview-course-node-container',
        content: {},
      })

      // - Category
      store.add('CourseCategory', {
        id: 'id-category',
        title: 'job',
        createdAt: '2017-07-18T08:56:05.264Z',
        updatedAt: '2017-07-25T10:07:30.530Z',
        icon: 'pi-jobs-metier_commercial',
      })
    },
  },
}
</script>
