<template lang="pug">
  doctype html
  .pi-widget-xp-earned
    .pi-widget.interactif(v-if="category")
      figure.pi
        figcaption
          | Votre {{ contextLevelLabel }}
        PiBadge(:conf="badgePi")

      figure.job
        figcaption
          | Votre NIVEAU THÈME
        PiBadge(v-if="_get(category, 'icon')", :conf="badgeJob")

</template>

<script>
import store from '@/api/index.js'
import PiBadge from '@/components/Ui/PiBadge'

export default {
  name: 'pi-widget-xp-earned',
  components: { PiBadge },
  mixins: [],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      category: null,
      badgePi: {
        level: 'or', // or, argent, bronze
        type: 'PI',
        img: this.contextLogoBadge,
        size: 'xl',
      },
    }
  },
  computed: {
    badgeJob: function () {
      return {
        level: 'argent', // or, argent, bronze
        type: 'metier',
        icon: this.category.icon,
        size: 'xl',
      }
    },
  },
  created () {
    this.process()
  },
  methods: {
    process () {
      this.$nextTick(this.getCategory)
    },
    getCategory () {
      const vm = this
      if (!vm.courseCategoryId) return
      const id = vm.courseCategoryId
      store.find('CourseCategory', id).then((record) => {
        this.category = record
      })
    },
  },
}
</script>
