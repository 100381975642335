<template lang="pug">
  doctype html
  .pi-widget-course-title
    .pi-widget.interactif(v-if="type")
      h3.name {{course.title}}.
      h3.type {{type.title}}

</template>

<script>
import store from '@/api/index.js'
import { hasIn } from 'lodash'
export default {
  name: 'pi-widget-course-title',
  components: {},
  mixins: [],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      type: null,
    }
  },
  computed: {},
  watch: {
    course () {
      this.process()
    },
  },
  created () {
    this.process()
    window._vm_widget_course_title = this
  },
  methods: {
    process () {
      this.getType()
    },
    getType () {
      const vm = this
      if (hasIn(vm, 'course.typeId')) {
        const id = vm.course.typeId
        store.find('CourseType', id).then((record) => {
          this.type = record
        })
      }
    },
  },
}
</script>
