<template lang="pug">
  doctype html
  .pi-error.pi-view.pi-error-403
    p Page Unauthorized
</template>

<script>

export default {
  name: 'pi403',
  props: ['type'],
  data () {
    return {
    }
  },
}
</script>
