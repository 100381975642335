<template lang="pug">
.pi-widget-course-competency-list
  .pi-widget.interactif()
    pi-competence.with-shadow(
      v-if="isNotEmptyArray(competencies)",
      v-for="(competency,index) in competencies",
      :key="index",
      :conf="competence[index]")
</template>

<script>
import store from '@/api/index.js'

export default {
  name: 'pi-widget-course-competency-list',
  components: {
    PiCompetence: () => import('@/components/Ui/Competence'),
  },
  mixins: [],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      competencies: [],
    }
  },
  computed: {
    competence: function () {
      const out = []
      for (const i in this.competencies) {
        const competency = this.competencies[i]
        out.push({
          title: competency.title,
          done: 0,
          icon: competency.icon,
        })
      }
      return out
    },
  },
  watch: {},
  created () {
    this.getCompetencies()
    window._vm_widget_course_competency_list = this
  },
  methods: {
    getCompetencies () {
      if (this.row.data.courseCompetencyIds.length > 0) {
        store.findAll('CourseCompetency', { filter: { _id: { $in: this.row.data.courseCompetencyIds } } }).then((records) => {
          this.$set(this, 'competencies', records)
        }).catch((err) => {
          console.warn('error', err)
        })
      } else {
        console.error('Pas de resource trouvée ou atachée')
      }
    },
  },
}
</script>
