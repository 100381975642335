<template lang="pug">
.pi-media-tincan.visible-coverage
  .exerciceType
    iframe(
      style='overflow:hidden;margin-bottom:-5px;'
      scrolling="no"
      :src="url"
      frameborder="0"
      referrerpolicy="no-referrer"
      :width="width"
      :height="height"
    )
</template>

<script>
import { get } from 'lodash'

export default {
  name: 'pi-media-tincan',
  mixins: [],
  props: [
    'row',
    'nbSection',
    'rowIndex',
    'reponse',
    'conf',
    'wordPicked',
    'courseCategoryId',
    'course',
    'dataset',
    'datasetsAlreadyComputed',
    'rowCount',
  ],
  data () {
    return {
      width: '100%',
      height: 600,
    }
  },
  computed: {
    tincan () {
      return { url: this.row.settings.url }
    },
    url () {
      let url = ''
      try {
        url = new URL(this.tincan.url)
      } catch (error) {
        console.error('impossible de lire l\'url', this.tincan.url)
        return ''
      }
      if (this.contextLrsHost) {
        url.searchParams.append('endpoint', this.contextLrsHost)
      }
      if (this.contextLrsBasicAuth) {
        url.searchParams.append('auth', `Basic ${this.contextLrsBasicAuth}`)
      }
      const actor = {
        name: [this.getCurrentUser.tincanIdentifier],
        account: {
          homePage: this.contextFrontoffice,
          name: this.getCurrentUser.id,
        },
      }
      url.searchParams.append('actor', JSON.stringify(actor))
      url.searchParams.append('registration', get(this, '$parent.page.id', '00000000-0000-4000-aaaa-aaaaaaaaaaaa'))
      return url.href
    },
  },
}
</script>
