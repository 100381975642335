<template lang="pug">
.pi-mentions
  .static-page.block-center
    el-row.large-margin.medium-padding
      el-col.large-margin-y(:span="24")
        .legal(v-html="content")
      el-col(:span="12")
        p
          em(v-if="version") Version : "{{ version }}"
          br
          em(v-if="date") Date : "{{ date }}"
      el-col.text-align-right(:span="12")
        router-link(to="/")
          el-button.btn__square.bg-primary(type="primary") Retour à l'accueil

</template>
<script>
export default {
  name: 'pi-mentions',
  components: {
  },
  props: {
    legal: {
      type: String,
      default: 'cgu',
    },
  },
  data () {
    return {}
  },
  computed: {
    version () {
      return this.$store.getters['context/get'](`settings.legal.${this.legal}.version`)
    },
    date () {
      return this.$store.getters['context/get'](`settings.legal.${this.legal}.date`)
    },
    content () {
      return this.$store.getters['context/get'](`settings.legal.${this.legal}.content`)
    },
  },
}
</script>
