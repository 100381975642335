import VueRouter from 'vue-router'
import App from '@/main.js'
import { each, get, isArray } from 'lodash'

import routes from './routes'

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

router.beforeEach((to, from, next) => {
  if (isArray(get(to, 'params.removeFromLocalstorage')) && typeof window.localStorage !== 'undefined') {
    each(to.params.removeFromLocalstorage, (key) => {
      window.localStorage.removeItem(key)
    })
  }
  // Génération par défault des titles, pour les pages d'édition, cf Mixins/Edit.js
  let title
  if (App && App.$store && get(to, 'name') && App.$te(`routes.${to.name}`)) {
    title = `${App.$store.getters['context/get']('name')} - ${App.$tc(`routes.${to.name}`)}`
  } else if (App && App.$store) {
    title = `${App.$store.getters['context/get']('name')} - Espace Apprenant`
  } else {
    title = 'Connecting...'
  }
  document.title = title
  next()
})
router.afterEach((to, from) => {
  if (App && App.$store) {
    if (to.name !== 'PiRenderPage') {
      App.$store.dispatch('courseKillPingers')
    }
    App.$store.commit('STOP_LESSON_REFRESH')
    App.$store.commit('STOP_CLASS_REFRESH')
  }
})

export default router
