<template lang="pug">
.pi-combined-choice
  .exerciceType
    h4(v-exposant) {{ parseShortCodes(row.message) }}
      pi-pop(:message="getOrder()")

  .questions
    el-select.interactif(
      ref="select"
      v-model='item.userAnswer',
      placeholder='Choisissez une réponse',
      :class="correctionClass"
      @visible-change="visibleChangeHandler"
    )
      el-option(
        v-for='choice in shuffledChoices',
        :key="choice.index",
        :label='parseShortCodes(choice.value)',
        :value='choice.index',
        :data-value="`sections[${nbSection}].rows[${rowIndex}].choices[${choice.index}]`",
        :disabled="isCorrectionMode",
        :class="expectedOption[choice.index]")
        img(
          v-if="_get(choice, 'appFile.id')"
          style="float: left; height: 5em;margin-right:1em"
          :src="getSrc(choice)"
        )
        span(v-exposant style="float: left;") {{ parseShortCodes(choice.value) }}

</template>

<script>

import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import RenderMixin from '@/mixins/Render.js'
import utils from '@/helpers/utils.js'
import { shuffle } from 'lodash'

export default {
  name: 'pi-combined-choice',
  components: { PiPop },
  mixins: [RenderMixin],
  props: [
    'row',
    'nbSection',
    'rowIndex',
    'reponse',
    'conf',
    'wordPicked',
    'courseCategoryId',
    'course',
    'dataset',
    'isCorrectionMode',
    'correctAnswer',
    'datasetsAlreadyComputed',
  ],
  data () {
    return {
      fixInScreenInterval: null,
      item: {},
      defaultOrder: 'Je sélectionne la bonne réponse',
    }
  },
  computed: {
    shuffledChoices () {
      this.row.choices.forEach((choice, index) => {
        choice.index = index
      })
      return shuffle(this.row.choices)
    },
    correctionClass () {
      if (!this.isCorrectionMode) return ''
      const goodAnswer = this.reponse.userAnswer === this.correctAnswer.answer.in[0]
      const wrongAnswer = !goodAnswer
      const expectedAnswer = goodAnswer

      return { expectedAnswer: expectedAnswer, goodAnswer: goodAnswer, wrongAnswer: wrongAnswer }
    },
    expectedOption () {
      if (!this.isCorrectionMode) return ''
      const out = []
      for (const i in this.shuffledChoices) {
        const choice = this.shuffledChoices[i]
        const expectedAnswer = choice.index === this.correctAnswer.answer.in[0]

        out[choice.index] = { expectedAnswer: expectedAnswer, isCorrectionMode: true, isCorrect: this.correctionClass.goodAnswer }
      }
      return out
    },
  },
  watch: {
    item: {
      handler (datas) {
        this.reponse = this.item
      },
      deep: true,
    },
  },
  created () {
    window._vm_combined_choice = this
    this.doItem()
  },
  beforeDestroy () {
    this.unbindFixInScreen()
  },
  methods: {
    visibleChangeHandler (visible) {
      if (visible) {
        this.bindFixInScreen()
      } else {
        this.unbindFixInScreen()
      }
    },
    unbindFixInScreen () {
      if (this.fixInScreenInterval !== null) {
        window.clearInterval(this.fixInScreenInterval)
      }
      this.fixInScreenInterval = null
    },
    bindFixInScreen () {
      this.unbindFixInScreen()
      this.fixInScreenInterval = window.setInterval(this.fixInScreen, 100)
    },
    fixInScreen () {
      const select = this.$refs.select.$el
      let el = select
      let z = 1
      while (el) {
        if (el.style.zoom) {
          z *= el.style.zoom
        }
        el = el.parentElement
      }
      const dropDowns = document.querySelectorAll('.el-select-dropdown.el-popper')
      dropDowns.forEach(dropDown => {
        const selectLeft = Math.round(select.getBoundingClientRect().left) * z
        const selectTop = Math.round(select.getBoundingClientRect().top) * z
        const selectHeight = Math.round(select.getBoundingClientRect().height) * z
        const left = `max(0px, min(${selectLeft}px, 100vw - ${Math.round(dropDown.getBoundingClientRect().width)}px))`
        const top = `max(0px, min(${selectTop}px + ${selectHeight}px, 100vH - ${Math.round(dropDown.getBoundingClientRect().height)}px))`
        if (dropDown.style.left !== left) {
          dropDown.style.left = left
        }
        if (z !== 1 && dropDown.style.top !== top) {
          dropDown.style.top = top
        }
      })
    },
    doItem () {
      this.$set(this, 'item', this.reponse)
    },
    getSrc (choice) {
      return utils.getFilePath(this._get(choice, 'appFile.id'))
    },
  },
}
</script>
