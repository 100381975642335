<template lang="pug">
  doctype html
  .pi-interactive-image(:style="autoZoom")
    v-style()
      | .pi-interactive-image {
      |   --current-zoom: {{ zoom }};
      | }
    .contain(
      v-if="interactiveImage && show"
      :class="'size-' + _get(interactiveImage, 'appFile.options.size', '')"
    )
      .elements(
        v-for="(block, index) in interactiveImage.blocks",
        :key="index",
        :style="`top: ${block.y}; left: ${block.x};`",
        :class="`${block.origin.x} ${block.origin.y}`"
        )
        .box
          .subbox
            .exercice(:data-value="block.exo", :data-type="getType(block.exo)", :data-is="getIs(block.exo)")

      img.fond(
        :src="getSrc()"
        @load='onResize'
      )

</template>

<script>
import utils from '@/helpers/utils.js'
import { includes, isString } from 'lodash'

export default {
  name: 'pi-interactive-image',
  props: [
    'sections',
    'interactiveImage',
    'dataset',
  ],
  data () {
    return {
      show: true,
      zoom: 1,
    }
  },
  computed: {
    autoZoom () {
      return {
        zoom: this.zoom,
      }
    },
  },
  watch: {
    interactiveImage () {
      this.rerender()
    },
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      const contain = this.$el.querySelector('.contain')
      const temoin = window.document.querySelector('.temoin')
      if (contain && temoin) {
        this.zoom = Math.max(Math.min(temoin.getBoundingClientRect().width / contain.getBoundingClientRect().width, 1), 0)
      } else {
        this.zoom = 1
      }
    },
    rerender () {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    getIs (s) {
      if (includes(s, 'choices')) {
        return 'answer'
      }
      if (includes(s, 'targets')) {
        return 'question'
      }
    },
    getType (s) {
      if (includes(s, 'choices') || includes(s, 'targets')) {
        return 'drag-n-drop'
      }
      let f
      if (isString(s)) {
        f = `${s.split(']').slice(0, 2).join(']')}]`
        return this._get(this, `${f}.type`)
      }
    },
    getSrc () {
      return utils.getFilePath(this.interactiveImage.appFile.id)
    },
  },
}
</script>
