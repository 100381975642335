<template lang="pug">
  doctype html
  .widget.resizable(
    v-if="visibility",
    :class="{move: ismoving, resize: isresizing}",
    :style="style"
    )

    .dragarea(
      @mousedown.stop.capture="onDragMouseDown",
      @touchestart.stop.capture="onDragMouseDown",
      @mouseup.stop.capture="onDragMouseUp"
      @touchend.stop.capture="onDragMouseUp"
    )
    a.close(@click="close")
      i.el-icon-pi-close
    .widget-content(:span="24", :class="{'withResize': useResize}", :style="widgetContentStyle")
      vue-scroll(ref="vs")
        slot
    .resizearea(v-if="useResize")
      .resize(
        @mousedown.stop.capture="onResizeMouseDown",
        @mouseup.stop.capture="onResizeMouseUp"
      )
</template>

<script>
import {
  get,
  isNaN,
} from 'lodash'
import UtilMixin from '@/mixins/Utils.js'

export default {
  name: 'widget',
  components: {
  },
  mixins: [UtilMixin],
  props: ['visibility', 'useResize', 'isMediaInternalAlone'],
  data () {
    return {
      ismoving: false,
      isresizing: false,
      top: 120,
      left: 0,
      clickX: 0,
      clickY: 0,
      lastX: 0,
      lastY: 0,
      width: 850,
      height: 0,
      lastHeight: 0,
      lastWidth: 0,
      refreshvs: null,
    }
  },
  computed: {
    mqTop: {
      get () {
        this.top = get({ xs: 0 }, this.$mq, this.top)
        return this.top
      },
      set (value) {
        this.top = value
      },
    },
    widgetContentStyle () {
      return {
        height: 'calc(100% - 45px)',
      }
    },
    style () {
      const style = {
        top: `${this.mqTop}px`,
        width: `${this.width}px`,
        'max-width': '100vw',
      }
      if (!isNaN(this.left)) {
        style.left = `${this.left}px`
      }
      const heights = { xs: 'var(--full-height)' }
      if (get(heights, this.$mq, false)) {
        style.height = get(heights, this.$mq, false)
      } else if (!isNaN(this.height) && this.height !== 0) {
        style.height = `${this.height}px`
      } else {
        style.height = '400px'
      }
      return style
    },
  },
  watch: {
    isresizing (value, old) {
      if (old && !value) {
        this.$el.dispatchEvent(new Event('resize'))
      }
    },
    visibility (value) {
      if (value) {
        Object.assign(this.$data, this.$options.data.call(this))
        this.left = (this.getWindowDimensions().width - this.$el.offsetWidth) / 2
      }
    },
  },
  mounted () {
    this.refreshvs = window.setInterval(() => {
      if (this.$refs.vs) {
        this.$refs.vs.refresh()
      }
    }, 200)
    this.$nextTick(() => {
      this.left = (this.getWindowDimensions().width - this.$el.offsetWidth) / 2
      this.width = this.isMediaInternalAlone ? 580 : 850
    })
    if (this.$el.querySelector('.widget-content')) {
      this.refreshHeight()
    }
    window._w = this
  },
  beforeDestroy () {
    window.clearInterval(this.refreshvs)
  },
  methods: {
    refreshHeight () {
      window.setTimeout(() => {
        if (this.$el.querySelector('.widget-content')) {
          const h = this.$el.querySelector('.widget-content').clientHeight
          if (h > 1) {
            this.height = h
          } else {
            this.refreshHeight()
          }
        }
      }, 200)
    },
    close () {
      this.$emit('update:visibility', false)
      this.$emit('close')
    },
    // DRAG
    bindDragEvents () {
      this.ismoving = true
      window.addEventListener('mousemove', this.onDragMouseMove, false)
      window.addEventListener('touchmove', this.onDragMouseMove, false)
      window.addEventListener('mouseup', this.unbindDragEvents, false)
      window.addEventListener('touchend', this.unbindDragEvents, false)
    },
    unbindDragEvents () {
      this.ismoving = false
      window.removeEventListener('mousemove', this.onDragMouseMove, false)
      window.removeEventListener('touchmove', this.onDragMouseMove, false)
      window.removeEventListener('mouseup', this.unbindDragEvents, false)
      window.removeEventListener('touchend', this.unbindDragEvents, false)
    },
    onDragMouseMove (e) {
      const minLeft = 0 - this.$el.offsetWidth / 2
      const maxLeft = this.getWindowDimensions().width - this.$el.offsetWidth / 2
      const minTop = 0
      const maxTop = this.getWindowDimensions().height - this.$el.querySelector('.dragarea').offsetHeight
      this.left = Math.min(Math.max((e.clientX || e.touches?.[0]?.clientX) - this.clickX + this.lastX, minLeft), maxLeft)
      this.mqTop = Math.min(Math.max((e.clientY || e.touches?.[0]?.clientY) - this.clickY + this.lastY, minTop), maxTop)
    },
    onDragMouseDown (e) {
      if (e.button !== 0 && e.type !== 'touchstart') {
        this.unbindDragEvents()
        return
      }
      this.clickX = (e.clientX || e.touches?.[0]?.clientX)
      this.clickY = (e.clientY || e.touches?.[0]?.clientY)
      this.lastX = this.left = this.$el.offsetLeft
      this.lastY = this.mqTop
      this.bindDragEvents()
    },
    onDragMouseUp (e) {
      this.unbindDragEvents()
    },
    // RESIZE
    bindResizeEvents () {
      this.isresizing = true
      window.addEventListener('mousemove', this.onResizeMouseMove, false)
      window.addEventListener('mouseup', this.unbindResizeEvents, false)
    },
    unbindResizeEvents () {
      this.isresizing = false
      window.removeEventListener('mousemove', this.onResizeMouseMove, false)
      window.removeEventListener('mouseup', this.unbindResizeEvents, false)
    },
    onResizeMouseMove (e) {
      const minWidth = 400
      this.width = Math.max(e.clientX - this.clickX + this.lastWidth, minWidth)
      this.height = e.clientY - this.clickY + this.lastHeight
    },
    onResizeMouseDown (e) {
      if (e.button !== 0) {
        this.unbindEvents()
        return
      }

      this.clickX = e.clientX
      this.clickY = e.clientY
      this.lastWidth = this.width
      this.lastHeight = this.height || this.$el.querySelector('.widget-content').clientHeight + 45
      this.bindResizeEvents()
    },
    onResizeMouseUp (e) {
      this.unbindResizeEvents()
    },
  },
}
</script>
<style lang="sass">
.widget
  .__panel > .__view
    width: 100% !important
</style>
