<template lang="pug">
.is-clickable.resource-link(
  href="#"
  v-if="courseNode && courseBlock"
  @click.stop="clickHandler"
)
  i.el-icon-pi-fleche-bouton
  span {{courseBlock.title}}

</template>

<script>
import store from '@/api/index.js'

export default {
  name: 'resource-link',
  components: {},
  mixins: [],
  props: ['courseNode'],
  data () {
    return {
      id: null,
      courseBlock: null,
      section: [],
    }
  },
  created () {
    this.process()
    window._vm_render_resource = this
  },
  methods: {
    process () {
      this.getCourseBlock()
    },
    getCourseBlock () {
      this.id = this.courseNode.blockId
      const recordInStore = store.get('CourseBlock', this.id)
      if (recordInStore) {
        this.getCourseBlockCallback(recordInStore)
      } else if (this.id) {
        store.find('CourseBlock', this.id).then(this.getCourseBlockCallback).catch((err) => { console.error(err) })
      } else {
        console.error(`[ RESSOURCES ] pas d'identifiant pour le NODE ${this.courseNode.id}`)
      }
    },
    getCourseBlockCallback (record) {
      this.courseBlock = record
      this.section = this._get(this, 'courseBlock.content.sections[0]', {})
    },
    clickHandler () {
      this.$emit('openIt', { courseBlock: this.courseBlock, section: this.section })
    },
  },
}
</script>
