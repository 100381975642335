<template lang="pug">
  .pi-sound-player
    .player(v-if="audioElement && playerStatus && duration")
      el-button(
        :icon="statusIcon"
        type="text"
        @click="statusButtonClickHandler"
      )
      p.timer.currentTime {{ getCurrentTimeInMinute }}
      el-slider(
        v-model='currentTime'
        :max='Math.floor(duration)'
        :format-tooltip="getTimeInMinute"
        :step=".1"
        @change='changeTimeHandler'
      )
      p.timer.duration {{ getDurationInMinute }}
      el-button.mobile-display-none(
        v-if="soundPower"
        :icon="soundIcon"
        type="text"
        @click="soundIconClickHandler"
      )
      .desktop-display-none.little-margin-right
</template>

<script>
import { isNumber } from 'lodash'

const ACTIONS = {
  PLAY: 'play',
  PAUSE: 'pause',
}

const SOUND_LEVELS = {
  DOWN: 'down',
  MIDDLE: 'middle',
  UP: 'up',
}

export default {
  name: 'pi-sound-player',
  components: {
  },
  mixins: [],
  props: ['url', 'autoplay'],
  data () {
    return {
      audioElement: null,
      currentTime: 0,
      duration: 0,
      playerStatus: ACTIONS.PAUSE,
      status: {
        pause: ACTIONS.PLAY,
        play: ACTIONS.PAUSE,
      },
      soundPower: SOUND_LEVELS.UP,
      soundScale: {
        [SOUND_LEVELS.DOWN]: SOUND_LEVELS.MIDDLE,
        [SOUND_LEVELS.MIDDLE]: SOUND_LEVELS.UP,
        [SOUND_LEVELS.UP]: SOUND_LEVELS.DOWN,
      },
    }
  },
  computed: {
    getCurrentTimeInMinute (value) {
      return this.getTimeInMinute(this.currentTime)
    },
    getDurationInMinute () {
      return this.getTimeInMinute(this.duration)
    },
    soundIcon () {
      switch (this.soundPower) {
        case SOUND_LEVELS.DOWN:
          return 'el-icon-fa-volume-off'
        case SOUND_LEVELS.MIDDLE:
          return 'el-icon-fa-volume-down'
        case SOUND_LEVELS.UP:
          return 'el-icon-fa-volume-up'
      }

      return undefined
    },
    statusIcon () {
      switch (this.playerStatus) {
        case ACTIONS.PAUSE:
          return 'el-icon-fa-play'
        case ACTIONS.PLAY:
          return 'el-icon-fa-pause'
      }

      return undefined
    },
  },
  watch: {
    url: {
      handler (url) {
        this.audioElement?.pause()
        this.addWatchersAtAudioElement()
        this.stop()
        this.updateSoundLevel()
        this.$nextTick(() => {
          this.autoplayIfRequired()
        })
      },
      immediate: true,
    },
    playerStatus: {
      async handler (datas) {
        this.updateSoundLevel()
        try {
          await this.audioElement[datas]()
        } catch (error) {
          if (datas === ACTIONS.PLAY) {
            this.playerStatus = ACTIONS.PAUSE
          }
        }
      },
      immediate: true,
    },
    soundPower () {
      this.updateSoundLevel()
    },
  },
  beforeDestroy () {
    this.audioElement?.pause()
  },
  created () {
    console.warn('[FRONT SOUNDPLAYER] autoplay', this.autoplay)
    window._vm_sound_player = this
  },
  methods: {
    updateSoundLevel () {
      switch (this.soundPower) {
        case SOUND_LEVELS.DOWN:
          this.audioElement.volume = 0.125
          break
        case SOUND_LEVELS.MIDDLE:
          this.audioElement.volume = 0.45
          break
        case SOUND_LEVELS.UP:
          this.audioElement.volume = 1
          break
      }
    },
    autoplayIfRequired () {
      this.playerStatus = this.autoplay ? ACTIONS.PLAY : ACTIONS.PAUSE
    },
    statusButtonClickHandler () {
      this.playerStatus = this.status[this.playerStatus]
    },
    soundIconClickHandler () {
      this.soundPower = this.soundScale[this.soundPower]
    },
    stop () {
      this.audioElement.currentTime = 0.000001
      this.playerStatus = ACTIONS.PAUSE
      this.setValues()
    },
    addWatchersAtAudioElement () {
      this.audioElement = new Audio(this.url)
      this.audioElement.ontimeupdate = () => {
        this.setValues()
      }
      this.audioElement.onloadeddata = () => {
        this.setValues()
        this.autoplayIfRequired()
      }
      this.audioElement.onloadeddata = () => {
        this.setValues()
        this.autoplayIfRequired()
      }
      this.audioElement.onended = () => {
        this.stop()
      }
    },
    setValues () {
      if (this.audioElement.currentTime) {
        this.currentTime = this.audioElement.currentTime
      }
      if (this.audioElement.duration !== Infinity) {
        this.duration = this.audioElement.duration
      } else {
        this.duration = Math.max(this.currentTime, 10)
      }
    },
    changeTimeHandler (time, evt) {
      if (isNumber(time) && isNumber(this.audioElement.currentTime) && Math.abs(time - this.audioElement.currentTime) > 0.5) {
        this.audioElement.currentTime = time
      }
    },
    getMinutes (nb) {
      const minutes = Math.floor(nb / 60)
      return `${minutes}`.padStart(2, '0')
    },
    getSecondes (nb) {
      const secondes = parseInt(nb % 60)
      return `${secondes}`.padStart(2, '0')
    },
    getTimeInMinute (value) {
      if (!value) return '00:00'

      const minutes = this.getMinutes(value)
      const secondes = this.getSecondes(value)
      return `${minutes}:${secondes}`
    },
  },
}
</script>
