<template lang="pug">
  doctype html
  .pi-media-internal(:class='rootClass')
    .exerciceType
      h4(v-if='!_get(row, "settings.hideTitle", false) && !isPdf' v-exposant) {{ row.title }}

      .media.interactif(v-if="file && (isImage || isPdf)")
        //- Image
        img.block-center.display-block(
          v-if="isImage",
          :src="getSrc")
        //- PDF
        vue-pdf-viewer.pdf-wrapper(
            v-else-if="isPdf"
            :canReplacePageControllers="rowCount === 1"
            :options='options'
            :url="getSrc"
            :reduceHeight='70'
          )
      .text
        p(v-if='!_get(row, "settings.hideDescription", false) && !isPdf', v-exposant)
          | {{ row.message }}
      //- Sound
      .text.medium-padding-bottom(v-if="file && isAudio")
        el-button.btn__square.bg-primary(@click="playSound") Écouter "{{ row.title }}"

</template>

<script>
import store from '@/api/index.js'
import { MIME_TYPE_AUDIO, MIME_TYPE_IMAGE } from '@/api/models/AppFile.js'
import { get, includes } from 'lodash'
import utils from '@/helpers/utils.js'
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import PiSoundPlayer from '@/components/Templates/PiSoundPlayer/PiSoundPlayer.vue'
import VuePDFViewer from '@/components/Ui/VuePDFViewer.vue'

export default {
  name: 'pi-media-internal',
  components: {
    PiPop,
    PiSoundPlayer,
    'vue-pdf-viewer': VuePDFViewer,
  },
  props: [
    'row',
    'nbSection',
    'rowIndex',
    'reponse',
    'conf',
    'wordPicked',
    'courseCategoryId',
    'course',
    'dataset',
    'datasetsAlreadyComputed',
    'rowCount',
  ],
  data () {
    return {
      file: null,
      id: null,
      dialogPDF: false,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
    }
  },
  computed: {
    rootClass () {
      return {
        'pi-media-internal-pdf': this.isPdf,
        'pi-media-internal-image': this.isImage,
      }
    },
    isAudio () {
      return includes(MIME_TYPE_AUDIO, get(this.file, 'type'))
    },
    isPdf () {
      return includes(['application/pdf'], get(this.file, 'type'))
    },
    isImage () {
      return includes(MIME_TYPE_IMAGE, get(this.file, 'type'))
    },
    getSrc () {
      return utils.getFilePath(this.id)
    },
    options () {
      return this.row.settings
    },
  },
  watch: {
    options: {
      handler (options) {
        let url = null
        if (options && options.audioFileId) {
          url = utils.getFilePath(options.audioFileId)
        }
        if (url) {
          this.$store.commit('MAINSOUNDPLAYER_SET_MASTER_SOUND', {
            url,
            name: null,
          })
        }
      },
      deep: true,
      immediate: true,
    },
    'row.appFile.id': {
      handler () {
        this.getFilePath()
      },
      deep: true,
      immediate: true,
    },
  },
  created () {
    window._vm_media_internal = this
    this.$nextTick(this.getAppFile)
  },
  methods: {
    playSound () {
      this.$store.commit('MAINSOUNDPLAYER_SET_TMP_SOUND', {
        url: this.file?.download,
        name: this.file?.fileNameWithoutExtension,
      })
    },
    next () {
      this.page = Math.min(this.numPages, this.page + 1)
    },
    prev () {
      this.page = Math.max(1, this.page - 1)
    },
    getAppFile () {
      const vm = this
      const appFileId = vm.row.appFile.id
      store.find('AppFile', appFileId).then((record) => {
        this.file = record
        this.id = record.id
      }).catch((err) => {
        console.warn('error', err)
      })
    },
    getAutoplaySetting () {
      return get(this.row, 'settings.autoplay', false)
    },
  },
}
</script>
