
<template lang="pug">
.pi-resources-modal

  widget(
    v-if="oneRessource"
    :title='oneRessource.courseBlock.title'
    :visibility.sync='visible'
    size="small"
    top="3px"
    :useResize="true"
    :isMediaInternalAlone="isMediaInternalAlone"
  )

    template(v-if='_get(oneRessource, "courseBlock.type") === "tincan"')
      iframe(
        style='overflow:hidden;width:100%;max-height:100%;aspect-ratio:16/9;'
        scrolling="no"
        :src="tincan.url"
        frameborder="0"
        referrerpolicy="no-referrer"
      )
    template(v-else)
      component.pi-resource(
        v-if="_includes(allowedComponentRessources, row.type)"
        v-for="(row, index) in _get(oneRessource, 'section.rows', [])"
        :key="index"
        :is="`pi-resource-${row.type}`"
        :row="row"
        :rowIndex="index"
        mediaHeight="79vh"
        :className="oneRessource.courseBlock && (row.length > 1 || _get(oneRessource, 'section.rows[0].type') === 'wysiwyg') ? 'group' : 'alone'",)

</template>

<script>
import { get, isNull } from 'lodash'
import Widget from '../DraggableWidget/Widget.vue'
import tincan from './content/tincan/tincan.vue'
import PiResourceMediaInternal from './Resource/Pi-Resource-Media-Internal.vue'
import PiResourceVideoExternal from './content/sections/Pi-Video-External.vue'
import PiResourceWysiwyg from './Resource/Pi-Resource-Wysiwyg.vue'
export default {
  name: 'pi-resources-modal',
  components: {
    tincan,
    Widget,
    PiResourceMediaInternal,
    PiResourceVideoExternal,
    PiResourceWysiwyg,
  },
  mixins: [],
  props: [],
  data () {
    return {
      allowedComponentRessources: [
        'wysiwyg',
        'media-internal',
        'video-external',
      ],
    }
  },
  computed: {
    tincan () {
      let url = ''
      try {
        url = new URL(get(this.oneRessource, 'courseBlock.content.tincan.url'))
      } catch (error) {
        console.error('impossible de lire l\'url')
        return ''
      }
      if (this.contextLrsHost) {
        url.searchParams.append('endpoint', this.contextLrsHost)
      }
      if (this.contextLrsBasicAuth) {
        url.searchParams.append('auth', `Basic ${this.contextLrsBasicAuth}`)
      }
      const actor = {
        name: [this.getCurrentUser.tincanIdentifier],
        account: {
          homePage: this.contextFrontoffice,
          name: this.getCurrentUser.id,
        },
      }
      url.searchParams.append('actor', JSON.stringify(actor))
      url.searchParams.append('registration', get(this, 'oneRessource.courseBlock.id', '00000000-0000-4000-aaaa-aaaaaaaaaaaa'))
      return {
        url: url.href,
      }
    },
    oneRessource () {
      return this.getResourceToDisplayInModal
    },
    isMediaInternalAlone () {
      const row = get(this.oneRessource, 'section.rows', [])
      return row.length === 1 && row[0].type === 'media-internal'
    },
    visible: {
      get () {
        return !isNull(this.oneRessource)
      },
      set (value) {
        if (value === false) {
          this.setResourceToDisplayInModal(null)
        }
      },
    },
  },
}
</script>
