import { defaultsDeep } from 'lodash'
import routesCommon from './routes.common'
import PiRenderPage from '@/components/Render/RenderPage'
import PiPageCorrection from '@/components/Correction/PiPageCorrection'

const defaultRouteSettings = {
  meta: {
    permission: 'grains|client|public',
    fail: '/403',
  },
}

const routes = [
  ...routesCommon,
  {
    path: '/session/:id/page/:pageId',
    meta: {
      hasOnlyContent: true,
      hasProgression: true,
      hasSidebar: false,
    },
    component: PiRenderPage,
    name: 'PiRenderPage',
    props: true,
  },
  {
    path: '/correction/:lessonId/page/:pageId',
    meta: {
      hasOnlyContent: true,
      hasSidebar: false,
      hasProgression: true,
    },
    component: PiPageCorrection,
    name: 'PiCorrectionPage',
    props: true,
  },
]
routes.forEach((el) => {
  defaultsDeep(el, defaultRouteSettings)

  if (el.children) {
    el.children.forEach((child) => {
      defaultsDeep(child, defaultRouteSettings)
    })
  }
})

export default routes
