<template lang="pug">
  doctype html
  .pi-pop
    el-popover(
      ref="popover1",
      :width="width || ''",
      placement="top-start",
      :title="title",
      trigger="hover",
      )
      span(v-exposant) {{ message }}
    i(:class="customIcon || 'el-icon-information'",
      v-popover:popover1,
      @click="emitThis")

</template>

<script>
export default {
  name: 'pi-pop',
  components: {},
  props: ['message', 'customIcon', 'title', 'width'],
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    emitThis (ev) {
      this.$emit('popClick', ev)
    },
  },
}
</script>
