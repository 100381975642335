<template lang="pug">
.main-sound-player.relative
  .closeTmpSound.tc-white.bg-black.absolute(v-if="!isMainTrack" @click="close")
    .showOnHover Fermer ce son&nbsp;
    i.el-icon-pi-close
  pi-sound-player(
    v-if="isVisible"
    :url="url"
    :autoplay="true"
  )
</template>

<script>
import PiSoundPlayer from '@/components/Templates/PiSoundPlayer/PiSoundPlayer.vue'
import { TRACKS } from '@/store/modules/mainSoundPlayer.js'

export default {
  name: 'main-sound-player',
  components: {
    PiSoundPlayer,
  },
  data () {
    return {
    }
  },
  computed: {
    track () {
      return this.$store.getters.mainSoundPlayerSound?.track
    },
    isMainTrack () {
      return this.track === TRACKS.MASTER
    },
    url () {
      return this.$store.getters.mainSoundPlayerSound?.url
    },
    name () {
      return this.$store.getters.mainSoundPlayerSound?.name
    },
    isVisible () {
      return Boolean(this.url)
    },
  },
  methods: {
    close () {
      this.$store.commit('MAINSOUNDPLAYER_SET_MASTER_TRACK')
    },
  },
}
</script>
<style lang="sass">
.main-sound-player > .pi-sound-player
  margin-top: 0px !important
  margin-bottom: 0px !important
.closeTmpSound
  cursor: pointer
  border: 2px transparent solid
  right: 50px
  top: -20px
  border-radius: 20px
  transition: all 250ms linear
  padding: 5px 10px
  @media screen and ( min-width: 768px )
    padding: 5px 7px
    right: 90px
    top: -35px
  .showOnHover
    white-space: nowrap
    display: inline-block
    transition: all 250ms  linear
    overflow: hidden
    line-height: 9px
    max-width: 100px
    @media screen and ( min-width: 768px )
      max-width: 0px
  @media screen and ( min-width: 768px )
    &:hover
      padding: 5px 10px
      .showOnHover
        max-width: 100px
</style>
