<template lang="pug">
  doctype html
  .pi-render-page-next.pi-render-page-actions.display-fullscreen()
    .frame
      h1.text-align-center(v-if="courseTitle") Le parcours "{{ courseTitle }}" est terminé
      template(v-if="hasScore")
        p.font-size-1-5.text-align-center.large-margin-top.large-margin-bottom
          strong Voici votre score:
        pi-competence.with-shadow(
          speed='slow'
          size='big'
          :label="lessonScore + '%'"
          :conf='configurationForRadialScore')

      template(v-if="hasManyUnlockedLessons")
        p.font-size-1-5.text-align-center.large-margin-bottom
          strong Vers quel module souhaitez-vous poursuivre ?
        .chooser.display-flex.align-items-center.justify-content-center.large-margin-top.large-margin-bottom
          .little-margin(
            :key='unlockedLesson.id + "_" + unlockedLessonIndex'
            :class="getClass(unlockedLessonIndex)"
            v-for='(unlockedLesson, unlockedLessonIndex) in unlockedLessons'
          )
            a.display-flex.align-items-center.justify-content-center.little-padding(@click='selectedIndex = unlockedLessonIndex')
              span {{ unlockedLesson.title}}
        p.text-align-center.large-margin-bottom(:class="{ 'visibility-hidden' : canContinue }") Pour continuer, vous devez d'abord choisir vers quel parcours vous serez redirigé
      template(v-else-if='canContinue')
        //- seulement une Lesson d'activée
        h1.text-align-center.large-margin-bottom
          | Le prochain parcours est intitulé
          br
          |"{{ nextLessonName }}"
      .buttons.large-padding-top.display-flex.justify-content-center
        el-button.btn__square.bg-tertiary(
          @click="backToDashBoard"
        )
          | Retour au tableau de bord&nbsp;
        el-button.btn__square.bg-tertiary(
          :disabled="!canContinue"
          @click="goToSession"
          v-if="hasUnlockedLessons"
        )
          | Continuer
          i.el-icon-pi-fleche-bouton
</template>

<script>
import { get, isArray, isNumber, uniqBy } from 'lodash'
import api from '@/api/index.js'

export default {
  name: 'pi-render-page-actions',
  components: {
    PiCompetence: () => import('@/components/Ui/Competence'),
  },
  mixins: [],
  props: ['id'],
  data () {
    return {
      selectedIndex: null,
      configurationForRadialScore: {
        id: '??',
        icon: '',
        title: '',
        done: 0,
      },
    }
  },
  computed: {
    canContinue () {
      return this.selectedIndexHelper !== null
    },
    nextLessonName () {
      if (!this.unlockedLessons[this.selectedIndexHelper]) {
        return ''
      }
      return this.unlockedLessons[this.selectedIndexHelper].title
    },
    selectedIndexHelper () {
      if (this.unlockedLessons.length === 1) {
        return 0
      }
      return this.selectedIndex
    },
    unlockedLessons () {
      if (this.lesson === null || !isArray(get(this, 'lesson.actions.unlockedLessons'))) {
        return []
      }
      // pour tester avec un résultat
      // return [this.lesson.actions.unlockedLessons[0]]
      // pour tester avec 0 résultat
      // return []
      return uniqBy(this.lesson.actions.unlockedLessons, JSON.stringify)
    },
    hasManyUnlockedLessons () {
      return this.unlockedLessons.length > 1
    },
    hasUnlockedLessons () {
      return this.unlockedLessons.length > 0
    },
    courseTitle () {
      if (this.course !== null) {
        return this.course.title
      }
      return null
    },
    courseScore () {
      if (this.course !== null) {
        return this.course.title
      }
      return null
    },
    hasScore () {
      return isNumber(get(this, 'lesson.scoring.global.actual')) &&
        isNumber(get(this, 'lesson.scoring.global.max')) &&
        this.lesson.scoring.global.max > 0
    },
    lessonScore () {
      return Math.floor(this.lessonScoreRatio * 100)
    },
    lessonScoreRatio () {
      if (this.hasScore) {
        return this.lesson.scoring.global.actual / this.lesson.scoring.global.max
      } else {
        return 0
      }
    },
  },
  watch: {
    lessonScoreRatio (value) {
      setTimeout(() => {
        this.configurationForRadialScore.done = value
      }, 500)
    },
  },
  asyncComputed: {
    course: {
      get () {
        if (!this.lesson) {
          return null
        }
        return api.find('Course', this.lesson.courseId)
      },
      default: null,
    },
    lesson: {
      get () {
        return api.find('Lesson', this.id, {
          filter: {
            populate: { path: 'course' },
          },
        }).then(lesson => {
          this.$store.dispatch('setLesson', lesson)
          return lesson
        })
      },
      default: null,
    },
  },
  beforeDestroy () {
  },
  mounted () {
    this.lesson
  },
  created () {
    window._page = this
  },
  methods: {
    getClass (id) {
      return {
        active: id === this.selectedIndexHelper,
      }
    },
    backToDashBoard () {
      this.backToHome({ clear: true })
    },
    async goToSession () {
      const { id } = this.unlockedLessons[this.selectedIndexHelper]
      const choosenLesson = await api.find('Lesson', id, { force: true })
      const fromSectionId = get(choosenLesson, ['enabledSectionIds', 0], null)

      choosenLesson.getCurrentPage({ fromSectionId }).then(page => {
        this.setOpenedLessonInStorage(choosenLesson)
        this.$router.push({ name: 'PiRenderPage', params: { id } })
      })
    },
  },
}
</script>
<style lang="sass">
</style>
