<template lang="pug">
  doctype html
  .pi-render-page-next.pi-render-page-questions.display-fullscreen()
    .frame
      h1.text-align-center(v-if="courseTitle") Le parcours "{{ courseTitle }}" est terminé
      template(v-if="hasScore")
        p.font-size-1-5.text-align-center.large-margin-top.large-margin-bottom
          strong Voici votre score:
        pi-competence.with-shadow(
          speed='slow'
          size='big'
          :label="lessonScore + '%'"
          :conf='configurationForRadialScore')

      p.font-size-1-5.text-align-center.large-margin-bottom
        strong {{ question }}
      .chooser.display-flex.align-items-center.justify-content-center.large-margin-top.large-margin-bottom
        .little-margin(
          :key='choice.id + "_" + choiceIndex'
          :class="getClass(choiceIndex)"
          v-for='(choice, choiceIndex) in choices'
        )
          a.display-flex.align-items-center.justify-content-center.little-padding(@click='selectedIndex = choiceIndex')
            span {{ choice.response}}
      p.text-align-center.large-margin-bottom(:class="{ 'visibility-hidden' : canContinue }") Pour continuer, vous devez d'abord choisir vers quel parcours vous serez redirigé
      .buttons.large-padding-top.display-flex.justify-content-center
        el-button.btn__square.bg-tertiary(
          @click="backToDashBoard"
        )
          | Retour au tableau de bord&nbsp;
        el-button.btn__square.bg-tertiary(
          :disabled="!canContinue"
          @click="goToSession"
          v-if="hasChoices"
        )
          | Continuer
          i.el-icon-pi-fleche-bouton
</template>

<script>
import { get, isArray, isNumber, isString } from 'lodash'
import api from '@/api/index.js'

export default {
  name: 'pi-render-page-actions',
  components: {
    PiCompetence: () => import('@/components/Ui/Competence'),
  },
  mixins: [],
  props: ['id'],
  data () {
    return {
      actionIndex: 0,
      selectedIndex: null,
      configurationForRadialScore: {
        id: '??',
        icon: '',
        title: '',
        done: 0,
      },
    }
  },
  computed: {
    canContinue () {
      return this.selectedIndexHelper !== null
    },
    selectedIndexHelper () {
      if (this.choices.length === 1) {
        return 0
      }
      return this.selectedIndex
    },
    question () {
      if (this.lesson === null || !isString(get(this, ['lesson', 'actions', 'interactive', this.actionIndex, 'label']))) {
        return null
      }
      return this.lesson.actions.interactive[this.actionIndex].label
    },
    choices () {
      if (this.lesson === null || !isArray(get(this, ['lesson', 'actions', 'interactive', this.actionIndex, 'choices']))) {
        return []
      }
      return this.lesson.actions.interactive[this.actionIndex].choices
    },
    hasManyChoices () {
      return this.choices.length > 1
    },
    hasChoices () {
      return this.choices.length > 0
    },
    courseTitle () {
      if (this.course !== null) {
        return this.course.title
      }
      return null
    },
    courseScore () {
      if (this.course !== null) {
        return this.course.title
      }
      return null
    },
    hasScore () {
      return isNumber(get(this, 'lesson.scoring.global.actual')) &&
        isNumber(get(this, 'lesson.scoring.global.max')) &&
        this.lesson.scoring.global.max > 0
    },
    lessonScore () {
      return Math.floor(this.lessonScoreRatio * 100)
    },
    lessonScoreRatio () {
      if (this.hasScore) {
        return this.lesson.scoring.global.actual / this.lesson.scoring.global.max
      } else {
        return 0
      }
    },
  },
  watch: {
    lessonScoreRatio (value) {
      setTimeout(() => {
        this.configurationForRadialScore.done = value
      }, 500)
    },
  },
  asyncComputed: {
    course: {
      get () {
        if (!this.lesson) {
          return null
        }
        return api.find('Course', this.lesson.courseId)
      },
      default: null,
    },
    lesson: {
      get () {
        return api.find('Lesson', this.id, {
          populate: { path: 'course' },
        }).then(lesson => {
          this.$store.dispatch('setLesson', lesson)
          return lesson
        })
      },
      default: null,
    },
  },
  beforeDestroy () {
  },
  mounted () {
    this.lesson
  },
  created () {
    window._page = this
  },
  methods: {
    getClass (id) {
      return {
        active: id === this.selectedIndexHelper,
      }
    },
    backToDashBoard () {
      this.backToHome({ clear: true })
    },
    async goToSession () {
      const id = this.choices[this.selectedIndexHelper].action.target
      return this.lesson.sendActions(this.actionIndex, this.selectedIndexHelper).then(async () => {
        const choosenLesson = await api.find('Lesson', id, { force: true })
        const fromSectionId = get(choosenLesson, ['enabledSectionIds', 0], null)
        await choosenLesson.getCurrentPage({ fromSectionId })
        this.setOpenedLessonInStorage(choosenLesson)
        this.$router.push({ name: 'PiRenderPage', params: { id } })
      }).catch((err) => {
        const errorResponseCode = get(err, 'response.data.code', 'UNDEFINED')
        const message = this.$te(`errors.${errorResponseCode}.message`) ? this.$t(`errors.${errorResponseCode}.message`) : 'MESSAGE'
        const title = this.$te(`errors.${errorResponseCode}.title`) ? this.$t(`errors.${errorResponseCode}.title`) : 'TITLE'
        this.$alert(
          message,
          title,
          {
            customClass: 'error',
            type: 'error',
            confirmButtonText: 'OK',
            callback: action => {
              console.log('ERROR')
            },
          })
      })
    },
  },
}
</script>
<style lang="sass">
</style>
