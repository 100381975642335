import { get, isArray, isError, isString, isUndefined, omitBy, snakeCase } from 'lodash'
import i18n from '@/i18n'
export class ErrorMessage {
  name = ''
  title = ''
  message = ''
  target = null
  code = ''
  quantity = 1 // à utiliser pour les erreurs "aggrégées"

  constructor (datas = {}) {
    const { name, title, message, code, target } = datas
    Object.assign(this, omitBy({ name, title, message, code, target }, isUndefined))
  }

  clone () {
    return new ErrorMessage(this)
  }

  static normalize (rawError) {
    // erreur de programmation
    if (isError(rawError) && rawError.name === 'TypeError') {
      return ErrorMessage.normalizeVanillaError(rawError)
    }
    // erreur api
    if (rawError.response && rawError.config && rawError.request) {
      return ErrorMessage.normalizeApiLoopBackError(rawError)
    }
    // group d'erreur api
    if (rawError.data && isArray(rawError.errors)) {
      return ErrorMessage.normalizeApiLoopBackBatchError(rawError)
    }
    // erreur fetch
    if (rawError.response) {
      return ErrorMessage.normalizeApiLoopBackError(rawError)
    }

    console.error('MISSING ERROR INTERCEPTION', rawError)
    return []
  }

  static getErrorTitle (code) {
    if (i18n.te(`errors.${code}`) && isString(i18n.t(`errors.${code}`))) {
      return i18n.t(`errors.${code}`)
    }

    if (i18n.te(`errors.${code}.title`)) {
      return i18n.t(`errors.${code}.title`)
    }
    return i18n.t('errors.UNDEFINED.title')
  }

  static normalizeVanillaError (rawError) {
    const code = snakeCase(rawError.name).toUpperCase()
    return [new ErrorMessage({
      code,
      name: rawError.name,
      title: ErrorMessage.getErrorTitle(code),
      message: i18n.tc(`errors.${code}.message`),
    })]
  }

  static normalizeApiLoopBackBatchError (rawError) {
    const rawErrors = rawError.errors || []
    return rawErrors.map((input) => {
      const target = input.username || input.email || null
      const err = get(input, 'error')
      if (!err) return undefined
      const code = err.code || err.title
      return new ErrorMessage({
        code,
        name: err.name,
        title: ErrorMessage.getErrorTitle(code),
        message: i18n.t(`errors.${code}.message`),
        target,
      })
    }).filter(Boolean)
  }

  static normalizeApiLoopBackError (rawError) {
    const rawErrors = rawError.response?.data?.errors
    if (isArray(rawErrors)) {
      return rawErrors.map((err) => {
        const code = err.code || err.title
        return new ErrorMessage({
          code,
          name: err.title,
          title: ErrorMessage.getErrorTitle(code),
          message: i18n.t(`errors.${code}.message`),
        })
      })
    }
    const isSingleError = rawError.response?.data?.error
    if (isSingleError) {
      const singleError = rawError.response.data
      const code = singleError.code || singleError.error || 'undefined'
      return [new ErrorMessage({
        code,
        name: singleError.title,
        title: ErrorMessage.getErrorTitle(code),
        message: i18n.t(`errors.${code}.message`),
      }),
      ]
    }
    return []
  }
}
