<template lang="pug">
.logbook
  .text-align-center
    h3.pane-title {{ $tc(`components.dashboard.tabs.logbook.name`)}}
      span.print-only &nbsp;de {{ getCurrentUser.displayName }}

  .actions.display-flex.large-margin-top
    .flex-grow-2
    div
      el-button.btn__square.bg-secondary(
        @click='print'
        v-show="entries.length > 0"
      )
        | Imprimer
        i.el-icon-fa-print
      el-button.btn__square.bg-primary(
        @click='isCreating = !isCreating'
      )
        | Ajouter une note
        i.el-icon-plus

  //- loading screen
  el-collapse-transition
    p.large-margin-top.loading-tab.flex-middle-center(
      v-loading="true"
      v-show="isLoading === true"
    )

  //- no entries
  el-collapse-transition
    p.large-margin-top.no-content.flex-middle-center(
      v-show="entries.length === 0 && isLoading === false && isCreating === false"
    )
        strong Aucune note dans le journal pour l'instant

  .entries.large-margin-top(
    v-if="isLoading === false"
  )

    entry(
      :key="'entry_' + entry.id"
      :entry="entry"
      v-for="entry in entries"
      @edit="edit"

    )

  el-dialog(
    append-to-body
    :modal-append-to-body="true"
    :visible.sync="isCreating"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class='rounded no-title edit-entry-dialog'
    )
    create-entry(
      v-if="isCreating"
      :toUserId="userId"
      :fromUserId="userId"
      @close='isCreating = false'
    )
  el-dialog(
    append-to-body
    :visible.sync="isEditing"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    custom-class='rounded no-title edit-entry-dialog'
    )
    edit-entry(
      v-if="isEditing"
      :entryId="entryId"
      @close='isEditing = false'
    )
</template>

<script>
import api from '@/api'
import moment from 'moment'
import { sortBy } from 'lodash'

export default {
  name: 'user-logbook',
  components: {
    Entry: () => import('./logbook/Entry'),
    CreateEntry: () => import('./logbook/CreateEntry'),
    EditEntry: () => import('./logbook/EditEntry'),
  },
  mixins: [
  ],
  data () {
    return {
      isCreating: false,
      isLoading: true,
      isEditing: false,
    }
  },
  computed: {
    entries () {
      this.$store.getters.DSLogBookRecord
      return sortBy(api.filter('LogBookRecord', { where: { studentId: this.getCurrentUser.id } }), (el) => -moment(el.createdAt))
    },
  },
  watch: {
    isEditing (value) {
      if (!value) {
        this.entryId = null
      }
    },
    'getCurrentUser.id': {
      immediate: true,
      handler () {
        this.fetchLogBookRecords()
      },
    },
  },
  methods: {
    edit (id) {
      this.entryId = id
      this.isEditing = true
      this.isCreating = false
    },
    async fetchLogBookRecords () {
      api.removeAll('LogBookRecord')
      const removeLibraries = api.removeAll('Library', { where: { ownerType: 'LogBookRecord' } })
      api.removeAll('AppFile', { where: { libraryId: { in: removeLibraries.map(el => el.id) } } })

      this.isLoading = true
      try {
        await api.findAll('LogBookRecord', {
          filter: {
            studentId: this.getCurrentUser.id,
            companyId: this.currentCompanyId,
          },
          populate: 'user',
          order: 'createdAt',
        }, {
          force: true,
        })
      } catch (error) {
        console.error(error)
      }
      this.isLoading = false
    },
    print () {
      window.print()
    },
  },
}
</script>
<style lang="sass">
.fr-second-toolbar
  display: none !important
.file-uploads
  &, & *
    cursor: pointer
.el-dialog.edit-entry-dialog
  .message, .header
    border: none
  .el-dialog__body
    width: clamp(360px, 100vw, 750px)
  .library
    @media screen and ( min-width: 768px )
      width: 170px
    h3
      font-weight: normal
      font-size: 1.4rem
      text-transform: none
      margin-bottom: 5px
.logbook
  .btn__square
    .el-icon-fa-print:before
      font-size: 16px
      line-height: 0
      height: 0
      bottom: 1px
    .el-icon-plus:before
      font-size: 12px
      line-height: 0
      height: 0
      bottom: 1px
  .entries
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.2))
.logbook__entry
  position: relative
  background: white
  .avatar
    margin: 0
    filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.2))
  .header,
  .message
    position: relative
    padding: 5px 10px 10px 10px
    border-bottom: 1px solid rgba(0, 0, 0, .1)
  .footer
    padding: 10px
  & + &
    margin-top: 5px
  h3
    margin: 2px 0 0 5px
    padding: 0
  .header
    display: flex
    align-items: center
  .message
  .files
    margin: 5px 0 5px 10px
    border-radius: 5px
    width: 200px
    flex-shrink: 0
    background: rgba(0, 0, 0, .05)
    .el-loading-mask
      background: transparent
</style>
