<template lang="pug">
  doctype html
  .pi-drag-n-drop
    .exerciceType
      h4(v-exposant) {{ parseShortCodes(row.message) }}
        pi-pop(:message="getOrder()")
    .answers
      .answer(
        v-for="choice in shuffledChoices",
        :key="choice.index",
        :class="`ql-${row.type}.sections-${nbSection}-rows-${rowIndex}-choices-${choice.index}`",
        :data-value="`sections[${nbSection}].rows[${rowIndex}].choices[${choice.index}].value`")
        .interactif(
          :class="`ql-${row.type}-sections-${nbSection}-rows-${rowIndex}-choices`"
          :data-index="choice.index"
          draggable="false"

          @mousedown.prevent.stop="touchstart($event, choice.index)"
          @touchstart.prevent.stop="touchstart($event, choice.index)"

          @touchend.prevent.stop="touchend($event, choice.index)"
          @touchmove.prevent.stop="touchmove($event, choice.index)"
        )
          .tag-block.choiceTag(
            v-exposant
            v-if="choice.value",
            :style="cellStyle"
          ) {{ parseShortCodes(choice.value) }}
          img.choiceTag(
            v-else,
            :src="getChoiceSrc(choice.index)",
            :style="cellStyle"
          )
    .questions
      .question(
        v-for="(target, nbTarget) in row.targets",
        :key="nbTarget",
        :class="`ql-${row.type}.sections-${nbSection}-rows-${rowIndex}-targets-${nbTarget}`",
        :data-value="`sections[${nbSection}].rows[${rowIndex}].targets[${nbTarget}].message`")
        img(
          v-if="target.appFile.id"
          :src="getTargetSrc(nbTarget)"
          draggable="false"
        )
        span(v-exposant) {{ parseShortCodes(target.message) }}:
        .interactif.dndInteractif(
          :data-index="nbTarget"
          :class='correctionClass[nbTarget]',
          @mouseover="printCorrection($event, nbTarget)",
          @mouseout="printUserAnswer($event, nbTarget)"

          @touchend.prevent.stop="touchend($event)"
          @mouseup.prevent.stop="touchend($event)"
        )
          img.targetTag(
            v-if="_get(answers[nbTarget], 'appFile.id')",
            :src="getChoiceSrc(answers[nbTarget].index)",
            :style="cellStyle"
            draggable="false"
          )
          .tag-block.targetTag.el-icon-pi-icone_drag-drop(
            v-else,
            v-exposant,
            :class="answers[nbTarget].value ? 'filled' : 'empty'",
            :style="cellStyle")
            | {{ parseShortCodes(_get(answers, `${nbTarget}.value`, '&nbsp;')) }}

</template>

<script>

import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import utils from '@/helpers/utils.js'
import RenderMixin from '@/mixins/Render.js'
import {
  find,
  get,
  hasIn,
  includes,
  isNaN,
  isNull,
  isNumber,
  shuffle,
} from 'lodash'

export default {
  name: 'pi-drag-n-drop',
  components: { PiPop },
  mixins: [RenderMixin],
  props: [
    'row',
    'nbSection',
    'rowIndex',
    'reponse',
    'conf',
    'wordPicked',
    'courseCategoryId',
    'course',
    'dataset',
    'isCorrectionMode',
    'correctAnswer',
    'datasetsAlreadyComputed',
  ],
  data () {
    return {
      ghost: null,
      userChoices: [],
      answers: [],
      choice: null,
      defaultOrder: 'Je dépose les réponses au bon endroit',
    }
  },
  computed: {
    cellStyle () {
      const style = {}
      const defaultWidth = get(this, 'row.settings.defaultWidth', false)
      const defaultHeight = get(this, 'row.settings.defaultHeight', false)
      if (isNumber(defaultWidth)) {
        style['min-width'] = 'inherit'
        style['max-width'] = 'inherit'
        style.width = `${defaultWidth}px`
        style.padding = 0
      }
      if (isNumber(defaultHeight)) {
        style['line-height'] = `${defaultHeight}px`
        style.height = `${defaultHeight}px`
        style.padding = 0
      }
      return style
    },
    shuffledChoices () {
      this.row.choices.forEach((choice, index) => {
        choice.index = index
      })
      return shuffle(this.row.choices)
    },
    correctionClass () {
      if (!this.isCorrectionMode) return ''
      const out = []
      for (const i in this.reponse.userAnswer) {
        const choice = this.reponse.userAnswer[i]
        const answer = this.correctAnswer.answer[i].in[0]
        const goodAnswer = choice === answer
        const wrongAnswer = !goodAnswer
        const expectedAnswer = goodAnswer

        out.push({ expectedAnswer: expectedAnswer, goodAnswer: goodAnswer, wrongAnswer: wrongAnswer })
      }
      return out
    },
  },
  watch: {
    choice: {
      handler () {
        this.updateActive()
      },
      immediate: true,
    },
    userChoices: {
      handler (data) {
        Object.assign(this.reponse, this.userChoices)
      },
      deep: true,
    },
  },
  created () {
    window._vm_drag_n_drop = this
    this.emptyUserChoice()
  },
  methods: {
    updateActive () {
      [
        ...document.querySelectorAll(`.ql-${this.row.type}-sections-${this.nbSection}-rows-${this.rowIndex}-choices`),
      ].forEach(el => el.classList.remove('actif'))
      if (this.choice !== null) {
        document.querySelector(`.ql-${this.row.type}-sections-${this.nbSection}-rows-${this.rowIndex}-choices[data-index="${this.choice}"]`)
          .classList.add('actif')
      }
    },
    touchstart (ev, index) {
      console.log('TOUCH START')

      window._ev = ev
      if (this.isCorrectionMode) {
        return
      }
      if ((ev.touches && ev.touches.length === 1) || (!ev.touches && ev.which !== 3)) {
        this.choice = index
        if (!ev.path) {
          ev.path = []
          let a = ev.target
          while (a) {
            ev.path.push(a)
            a = a.parentNode
          }
        }
        const el = find(ev.path, el => includes(el.classList, 'choiceTag'))
        if (!el) { return }
        this.ghost = el.cloneNode(true)
        this.ghost.id = 'ghost'
        this.ghost.style.display = 'none'
        this.ghost.style.opacity = 0.5

        document.body.appendChild(this.ghost)

        if (!ev.touches && ev.which !== 3) {
          document.addEventListener('mousemove', this.touchmove)
          document.addEventListener('mouseup', this.touchend)
        }
      }
    },
    touchend (ev, fromIndex) {
      console.log('TOUCH END', fromIndex)
      if (this.ghost) {
        this.ghost.style.display = 'none'
      }
      const { clientX, clientY } = get(ev, ['changedTouches', 0]) || ev
      const elem = document.elementsFromPoint(clientX, clientY)
      const dropZone = elem.find(el => includes(el.classList, 'dndInteractif'))
      if (dropZone) {
        const toIndex = get(dropZone, ['attributes', 'data-index', 'value'], null)
        if (!isNull(toIndex) && !isNaN(+toIndex)) {
          this.dropChoiceOn(+toIndex)
        }
      }

      this.ghost = null

      Array.from(document.querySelectorAll('#ghost')).forEach(el => el.remove())

      document.removeEventListener('mousemove', this.touchmove)
      document.removeEventListener('mouseup', this.touchend)
    },
    touchmove (ev) {
      console.log('TOUCH MOVE')

      if (this.ghost) {
        this.ghost.style.display = 'block'
        if (hasIn(ev, ['touches', 0, 'clientY'])) {
          this.ghost.style.top = `${get(ev, ['touches', 0, 'clientY']) + (window.scrollY || 0) - ((this.ghost.clientHeight || 0) / 2)}px`
          this.ghost.style.left = `${get(ev, ['touches', 0, 'clientX']) + (window.scrollX || 0) - ((this.ghost.clientWidth || 0) / 2)}px`
        } else {
          this.ghost.style.top = `${get(ev, 'clientY') + (window.scrollY || 0) - ((this.ghost.clientHeight || 0) / 2)}px`
          this.ghost.style.left = `${get(ev, 'clientX') + (window.scrollX || 0) - ((this.ghost.clientWidth || 0) / 2)}px`
        }
      }
    },
    dropChoiceOn (index) {
      if (isNumber(this.choice)) {
        this.$set(this.userChoices.userAnswer, index, this.choice)
        const value = this.row.choices[this.userChoices.userAnswer[index]]
        this.$set(this.answers, index, value)
      }
      this.choice = null
      this.updateActive()
    },
    getChoiceSrc (index) {
      return utils.getFilePath(this.row.choices[index].appFile.id)
    },
    getTargetSrc (index) {
      return utils.getFilePath(this.row.targets[index].appFile.id)
    },
    emptyUserChoice () {
      this.$set(this, 'userChoices', this.reponse)
      for (let i = 0; i < this.reponse.userAnswer.length; i++) {
        this.answers.push(((this.row.choices[this.userChoices.userAnswer[i]]) ? this.row.choices[this.userChoices.userAnswer[i]] : ''))
      }
    },
    printCorrection (e, index) {
      if (!this.isCorrectionMode || !this.correctionClass[index].wrongAnswer || includes(e.currentTarget.classList, 'expectedAnswer')) return
      e.currentTarget.classList.toggle('expectedAnswer')
      e.currentTarget.classList.toggle('wrongAnswer')
      this.$set(this.answers, index, this._get(this.row.choices, this.correctAnswer.answer[index].in[0]))
    },
    printUserAnswer (e, index) {
      if (!this.isCorrectionMode || !this.correctionClass[index].wrongAnswer) return
      e.currentTarget.classList.toggle('wrongAnswer')
      e.currentTarget.classList.toggle('expectedAnswer')
      this.$set(this.answers, index, this._get(this.row.choices, this.reponse.userAnswer[index], ''))
    },
  },
}
</script>

<style lang="sass">
#ghost
  position: absolute
  z-index: 1
</style>
