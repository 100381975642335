<template lang="pug">
  doctype html
  .tincan
    el-dialog.no-modal.tincan-dialog(
      @close="closedialog",
      v-if='visible',
      :visible="true",
      :before-close="closedialog",
      :modal-append-to-body="false",
      :close-on-press-escape="false",
      :close-on-click-modal="false"
    )
      iframe(
        style='overflow:hidden;',
        scrolling="no",
        :src="tincan.url",
        frameborder="0",
        referrerpolicy="no-referrer",
        :width="width",
        :height="height")
</template>

<script>

export default {
  name: 'tincan',
  components: {},
  mixins: [],
  props: {
    tincan: {
      type: Object,
      default: function () {
        return {}
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      width: 870,
      height: 630,
    }
  },
  computed: {},
  watch: {
    'visible' (value) {
      this.$store.commit('TINCAN_IS_OPEN', value)
    },
  },
  created () {
    this.$store.commit('TINCAN_IS_OPEN', this.visible)
    window._tincan = this
  },
  methods: {
    closedialog () {
      this.$emit('update:visible', false)
      this.$store.commit('TINCAN_IS_OPEN', false)
    },
  },
}
</script>
