<template lang="pug">
.pi-render-node
  template(v-if="hasPage")
    pi-render-course-block.brick(
      :courseId="lesson.courseId",
      :content="page.content",
      :page="page",
      :sectionName="sectionName",
      :isCorrectionMode="isCorrectionMode",
      @showFeedbacks="showFeedbacksHandler"
      @next="nextHandler",
      @previous="previousHandler",
      @check="checkHandler",
      @openCourseTree="openCourseTreeHandler"
      )
      template(v-slot:toolbar)
        pi-chrono.entry.entry-is-invisible(
          v-if="timeLimit",
          :start="timeAlive",
          :end="timeLimit"
          @chronoEnded="pingPage"
        )
        .ressources.entry.desktop-pointer-none(
          @click="ressourcesModalVisibility = true"
          v-if="resources && resources.length > 0"
        )
          .entry__title.desktop-pointer-none
            i.el-icon-pi-acces_ressources.little-margin-right.font-size-2
            span
              span.mobile-display-none Ressources&nbsp;
              span x{{ resources.length }}

          template(v-for="tag in ['div.entry__content.mobile-display-none.liste.no.mobile-display-none.desktop-pointer-all', 'el-dialog']")
            component(
              :is="tag.split('.')[0]"
              :class="tag.split('.').slice(1)"
              append-to-body
              :visible.sync='ressourcesModalVisibility'
              title="Ressources"
            )
              resource-link(
                v-for="(children, index) in resources"
                :key="index"
                :courseNode="children"
                @openIt="openRessourceDialog"
              )

        .calculator.entry.is-clickable(
          v-if="hasCalculator"
          @click="toggleCalculator"
        )
          .entry__title
            i.el-icon-pi-acces_calculette.little-margin-right.font-size-2
            span.mobile-display-none Calculatrice

    .corrigeTool(v-if="isCorrectionMode")
      .helper
        el-button(icon="el-icon-pi-help")
        .info.no
          .title Légende
          .body
            .good
              h3 Réponses Correctes
              el-checkbox.interactif.goodAnswer(v-model="reponse.bool", disabled=true)
                .inline Réponse
              .interactif.dndInteractif.goodAnswer()
                .tag-block.targetTag.el-icon-pi-icone_drag-drop()
                  | Réponse
              el-input.goodAnswer(v-model="reponse.text", style="width:175px;", disabled=true)
              p Les bonnes réponses sont en vert.
            .wrong
              h3 Réponses Fausses
              el-checkbox.interactif.wrongAnswer(v-model="reponse.bool", disabled=true)
                .inline Réponse
              .interactif.dndInteractif.wrongAnswer()
                .tag-block.targetTag.el-icon-pi-icone_drag-drop()
                  | Réponse
              el-input.wrongAnswer(v-model="reponse.text", style="width:175px;", disabled=true)
              p Les mauvaises réponses sont en rouge et rayées.
            .expected
              h3 Réponses Attendues
              el-checkbox.interactif.expectedAnswer(disabled=true)
                .inline Réponse
              .interactif.dndInteractif.expectedAnswer()
                .tag-block.targetTag.el-icon-pi-icone_drag-drop()
                  | Réponse
              el-input.expectedAnswer(v-model="reponse.text", style="width:175px;", disabled=true)
              p Les réponses attendues sont sur fond rouge. (Elles s'affichent lors du survol de la souris.)

    pi-resources-modal
    calculator(v-if='calculatorIsVisible', @close="toggleCalculator")
  div
    .loading-page(v-if="!hasPage" v-loading="!hasPage && !hasAlert")

</template>

<script>
import store from '@/api/index.js'
import { debounce, find, get, includes, isEmpty } from 'lodash'
import Calculator from './Tools/Calculator.vue'
import PiChrono from './Tools/Chrono.vue'
import { Program } from '@/api/models/Program'

import PiResourcesModal from './PiResourcesModal.vue'
import ResourceLink from './ResourceLink.vue'
import piRenderCourseBlock from './RenderCourseBlock.vue'
import Widget from '../DraggableWidget/Widget.vue'

const errorsToHide = ['NO_LESSON_PAGE_AVAILABLE']

export default {
  name: 'pi-render-page',
  components: {
    PiResourcesModal,
    ResourceLink,
    piRenderCourseBlock,
    PiChrono,
    Calculator,
    Widget,
  },
  mixins: [],
  props: ['id', 'pageCorrection', 'pageId', 'isCorrectionMode'],
  data() {
    return {
      ressourcesModalVisibility: false,
      isPingAlive: true,
      course: null,
      calculatorIsVisible: false,
      forceRefresh: null,
      reponse: { text: 'Réponse', bool: true },
      cacheCourseNode: undefined,
      hasAlert: false,
    }
  },
  computed: {
    hasPage() {
      return Boolean(this.page)
    },
    hasTools() {
      return get(this.page, 'content.settings.tools') && get(this.page, 'content.settings.tools.calculator')
    },
    hasCalculator() {
      const hasCalculator = get(this.page, 'content.settings.tools.calculator')
      if (hasCalculator) {
        setTimeout(() => {
          this.calculatorOpacity = 1
          this.calculatorX = 0
        }, 1000)
      }
      return hasCalculator
    },
    timeSpent() {
      return get(this, 'page.timeSpent', 0)
    },
    timeAlive() {
      return get(this, 'page.timeAlive', 0)
    },
    timeLimit() {
      return get(this, 'page.timeLimit', 0)
    },
  },
  watch: {
    '$store.getters.currentPageId'(value) {
      if (value && get(this, 'page.id')) {
        this.refresh()
      }
    },
  },
  asyncComputed: {
    lesson: {
      get() {
        this.forceRefresh
        if (this.isCorrectionMode) {
          const lesson = store.get('Lesson', this.id)
          this.$store.dispatch('setLesson', lesson)
          return lesson.loadRelations('class')
        }

        return store.find('Lesson', this.id).then((lesson) => {
          this.$store.dispatch('setLesson', lesson)
          return lesson.loadRelations('class')
        })
      },
      default: null,
    },
    courseNode: {
      get() {
        if (this.cacheCourseNode) {
          return this.cacheCourseNode
        }
        if (!this.cacheCourseNode && get(this, 'page.nodeId', null) && get(this.page, 'content.type') === 'slide') {
          return store
            .find('CourseNode', get(this, 'page.nodeId', null))
            .then((node) => node.loadRelations('parent'))
            .then((node) => {
              this.cacheCourseNode = node
              return this.cacheCourseNode
            })
        }
        this.cacheCourseNode = undefined
      },
      default: null,
    },
    page: {
      get() {
        // correction
        if (this.pageCorrection) {
          console.log('CORRECTION MODE')
          this.$store.dispatch('setCurrentPage', this.pageCorrection)
          this.$store.commit('SET_PAGE_RANDOMIZER', this.pageCorrection.id)
          return this.pageCorrection.loadRelations('node')
        }
        // parcours
        this.forceRefresh
        if (!this.lesson) {
          return null
        }
        if (!this.pageId) {
          return this.lesson
            .getCurrentPage()
            .catch((error) => {
              return this.alertErrors(error)
            })
            .then((page) => {
              this.$store.dispatch('setCurrentPage', page)
              this.$store.commit('SET_PAGE_RANDOMIZER', page.id)
              return page
            })
        } else {
          return this.lesson
            .getCurrentPage({ fromPageId: this.pageId })
            .catch((error) => {
              return this.alertErrors(error)
            })
            .then((page) => {
              this.$store.dispatch('setCurrentPage', page)
              this.$store.commit('SET_PAGE_RANDOMIZER', page.id)
              this.pageId = null
              return page
            })
        }
      },
      default: null,
    },
    sectionName: {
      get() {
        if (!this.page) {
          return null
        }
        const sortedTree = get(this, 'lesson.sortedTree')
        if (sortedTree) {
          const section = find(sortedTree, (section) => find(section.pages, { id: this.page.id }))
          return get(section, 'title', ' ')
        }
        const node = get(this, 'page.node')
        if (this.page.lessonId === 'id-correction-lesson' && node) {
          return node.loadRelations('parent').then((node) => node.parent.title)
        }
        return null
      },
      default: null,
    },
    resources: {
      get() {
        if (!this.page) {
          return []
        }
        return this.page
          .getResources()
          .then((datas) => {
            this.ressourcesOpacity = 1
            this.ressourcesX = 0
            return datas
          })
          .catch((error) => {
            console.error('[RENDERPAGE] resources -> getResources : ', error)
          })
      },
      default: [],
    },
  },
  beforeDestroy() {
    window.clearInterval(this.$store.state.course.pinger)
    window.clearInterval(this.$store.state.course.timerUpdater)
  },
  mounted() {
    console.log('RENDER_PAGE  MOUNTED')
    this.$store.dispatch('courseKillPingers')
    if (!this.isCorrectionMode) {
      this.$set(
        this.$store.state.course,
        'timerUpdater',
        window.setInterval(() => {
          this.$store.commit('INCREMENT_TIMER')
        }, 1000),
      )

      this.$set(
        this.$store.state.course,
        'pinger',
        window.setInterval(() => {
          this.pingPage()
        }, 10000),
      )
    }
  },
  created() {
    console.log('RENDER_PAGE  CREATED')
    this.goToNextPageDebounced = debounce(this.goToNextPage, 200)
    this.removeResourceToDisplayInModal()
    window._page = this
  },
  methods: {
    pingPage() {
      if (this.isPingAlive) {
        this.lesson
          .ping()
          .then(() => {})
          .catch((error) => {
            this.isPingAlive = false
            this.$store.dispatch('courseKillPingers')
            return this.alertErrors(error)
          })
      }
    },
    showUnlockedLessons() {
      this.$router.push({ name: 'PiRenderPageActions', params: { id: this.lesson.id } })
    },
    showInteractiveItems() {
      this.$router.push({ name: 'PiRenderPageQuestions', params: { id: this.lesson.id } })
    },
    evaluateProgram(id) {
      this.$router.push({ name: 'EvaluateModel', params: { id, type: Program.name } })
    },
    async getProgramToEvaluate() {
      if (this.lesson.isEndOfProgram) {
        let reviews = null

        let programId = null

        const klass = this.lesson.class

        if (
          klass.classSteps &&
          this.lesson.classStepProgramId &&
          klass.classSteps[this.lesson.classStepProgramId]
        ) {
          programId = klass.classSteps[this.lesson.classStepProgramId].resourceId
        } else {
          const { programId: programIdTemp } = await store.find('ClassProgram', this.lesson.classProgramId, {
            force: true,
          })
          programId = programIdTemp
        }

        if (programId) {
          try {
            reviews = await store.findAll(
              'Review',
              {
                filter: { resourceType: Program.name, resourceId: programId, userId: this.getCurrentUser.id },
                options: { limit: 1 },
              },
              { force: true },
            )
          } catch (error) {
            console.error(error)
          }

          if (reviews && reviews.length === 0) {
            return programId
          }
        }
      }
      return null
    },
    async alertErrors(error) {
      const codeError = get(error, 'response.data.code')
      if (codeError === 'NO_LESSON_PAGE_AVAILABLE') {
        // on met à jour la lesson en cours.
        await store.find('Lesson', this.lesson.id, { force: true })

        if (get(this, 'lesson.actions.interactive', []).length > 0) {
          this.showInteractiveItems()
          return
        }

        // si il y a eu un déblocage
        if (get(this, 'lesson.actions.unlockedLessons', []).length > 0) {
          this.showUnlockedLessons()
          return
        }
        const programId = await this.getProgramToEvaluate()
        if (programId) {
          this.evaluateProgram(programId)
          return
        }
      }
      // S'il y a des erreurs à afficher
      // et qui ne sont pas dans les réponses à masquer
      // on les affiche
      return new Promise((resolve, reject) => {
        if (codeError && !includes(errorsToHide, codeError)) {
          this.hasAlert = true

          this.$alert(this.$t(`errors.${codeError}.message`), this.$t(`errors.${codeError}.title`), {
            customClass: `error ${codeError}`,
            type: 'error',
            confirmButtonText: this.$te(`errors.${codeError}.button`) ? this.$t(`errors.${codeError}.button`) : 'OK',
            callback: () => {
              this.hasAlert = false
              this.backToHome({ clear: false })
              resolve()
            },
          })
        } else {
          this.backToHome({ clear: false })
          resolve()
        }
      })
    },
    checkHandler() {
      this.removeResourceToDisplayInModal()
      this.lesson.getCurrentPageHints().then((datas) => {
        console.log('[ CHECK ]')
      })
    },
    toggleCalculator() {
      this.calculatorIsVisible = !this.calculatorIsVisible
    },
    toggleBurger() {
      this.burgerIsVisible = !this.burgerIsVisible
    },
    showFeedbacksHandler() {
      this.removeResourceToDisplayInModal()
      this.calculatorIsVisible = false
    },
    async nextHandler(datas, clear = false, backToHome = false) {
      this.removeResourceToDisplayInModal()
      if (!isEmpty(datas)) {
        console.log('il y a des réponses à transmettre')
      }
      if (backToHome) {
        const lesson = await store.find('Lesson', this.lesson.id, { force: true })
        if (lesson.progress === 'COMPLETED') {
          const programId = await this.getProgramToEvaluate()
          if (programId) {
            this.evaluateProgram(programId)
            return
          }
        }
        this.backToHome({ clear })
      } else {
        this.goToNextPageDebounced({ clear })
      }
    },
    openCourseTreeHandler() {
      this.burgerIsVisible = true
    },
    previousHandler() {
      this.removeResourceToDisplayInModal()
      this.gotToPreviousPage()
    },
    goToNextPage({ clear }) {
      this.lesson
        .nextPage(this.$store.getters.currentPageId)
        .then((nextPage) => {
          if (window) window.scroll(0, 0)
          this.timerConfirm(nextPage, this.refresh)
        })
        .catch((error) => {
          return this.alertErrors(error)
        })
    },
    gotToPreviousPage() {
      this.lesson
        .previousPage()
        .then((nextPage) => {
          this.refresh()
        })
        .catch((error) => {
          return this.alertErrors(error)
        })
    },
    refresh() {
      this.$store.commit('MAINSOUNDPLAYER_HIDE_ALL')
      Object.assign(this.$data, this.$options.data.call(this))
      this.forceRefresh = Date.now()
    },
    openRessourceDialog(obj) {
      this.ressourcesModalVisibility = false
      this.setResourceToDisplayInModal(obj)
    },
    toggleListe() {
      this.active = !this.active
    },
  },
}
</script>
<style lang="sass">
.pi-render-node
  .loading-page
    min-height: 50vh
    height: 640px
    max-height: 80vh
    width: 100%
    .el-loading-mask
      background-color: transparent
</style>
