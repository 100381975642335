<template lang="pug">
.user-levels.text-align-center
  h3.pane-title {{ $tc('components.dashboard.tabs.levels.title') }}

  .pi-competences(v-if="isDesktop")
    pi-competence.with-shadow(
      v-for='competence, key in competenciesMedals'
      :key='`desktop_${key}`'
      :conf='competence.conf'
    )

  .pi-competencies-mobile.large-margin-y(
    v-if="isMobile"
  )
    .large-padding-top(
      v-for='competence, key in competenciesMedals'
      :key='`mobile_${key}`'
      @click="toggle(competence.id)"
    )
      .large-margin-right.large-margin-left.display-flex.justify-content-center.align-items-center
        pi-competence.with-shadow(
          size="thumb"
          :conf='competence.conf'
        )
        .medium-margin-x.text-align-left.flex-grow-1
          span.fs-1-1 {{ competence.title }}
        strong.tc-tertiary(v-if="competence.done !== null") {{ Math.round(competence.done * 100) }}%

        .chevron.text-align-right
          i.el-icon-arrow-down(
            v-if="competence.children.length > 0"
            :class="getChevronClass(competence.id)"
          )

      el-collapse-transition
        .medium-padding-top.large-margin-right.large-margin-left(
          v-show='isVisible(competence.id)'
          v-if='competence.children.length > 0'
        )
          .width-100.display-flex.justify-content-center.align-items-center(
            v-for='subcompetence, key in competence.children'
            :key='subcompetence.id'
          )
            .medium-margin-x.little-margin-top.text-align-left.flex-grow-1(
              style="margin-left:40px"
            )
              span {{ subcompetence.title }}
            span.tc-tertiary(v-if="subcompetence.score !== null") {{ Math.round(subcompetence.score * 100) }}%

            .chevron

  hr(v-show="isDesktop")
  pi-user-levels(v-if='isDesktop')

</template>

<script>
import { get, map } from 'lodash'
import ScoreMixin from '@/mixins/Score.js'
import getCourseCompetenciesMixin from '@/mixins/GetCourseCompetencies.js'

export default {
  name: 'user-levels',
  components: {
    PiCompetence: () => import('@/components/Ui/Competence'),
    PiUserLevels: () => import('./levels/PiUserLevels'),
  },
  mixins: [
    ScoreMixin,
    getCourseCompetenciesMixin,
  ],
  props: {
  },
  data () {
    return {
      activeCompetencies: [],
    }
  },
  computed: {
    competencies () {
      return this.getVisibleRootCompetencies
    },
    competenciesMedals () {
      return map(this.competencies, el => ({
        id: el.id,
        children: el.children.map(subel => ({
          title: subel.title,
          score: get(this.stats, ['computedScoring', 'competencies', subel.id], null),
        })),
        title: el.title,
        done: get(this.stats, ['computedScoring', 'competencies', el.id], null),
        conf: {
          id: el.id,
          icon: el.icon,
          title: el.title,
          done: get(this.stats, ['computedScoring', 'competencies', el.id], null),
        },
      })).filter(el => !this.isDesktop || el.done !== null)
    },
  },
  created () {
    window._vm_user_levels = this
  },
  methods: {
    toggle (id) {
      if (this.isVisible(id)) {
        this.activeCompetencies.splice(this.activeCompetencies.indexOf(id), 1)
      } else {
        this.activeCompetencies.push(id)
      }
    },
    isVisible (id) {
      return this.activeCompetencies.includes(id)
    },
    getChevronClass (id) {
      return {
        'rotate-180': this.isVisible(id),
      }
    },
  },
}
</script>
<style lang="sass">
.user-levels
  hr
    margin: 30px 24px
  .pi-chart
    display: inline-block
  .pi-competencies-mobile
    width: 100%
    .radial-progress
      margin-bottom: 0
    .pi-competence .ui-competence
      margin-top: 0
      > span
        display: none
  .chevron
    width: 30px
    i
      transition: all 250ms linear
</style>
