<template lang="pug">
.pi-widget-competency-xp-earned
  .pi-widget.interactif(v-if="competencies[0]", :style="piWidgetStyle", v-loading="isLoading" :class="isLoadingClass")
    .title.text-align-center {{ $tc(`components['competency-xp-earned'].title`, competencies.length) }}
    .competencies()
      .competency(v-for="(competency, cIndex) in competencies", :key="cIndex")
        pi-competence.with-shadow(v-if="_get(competency, 'icon')", :conf='competency')

</template>

<script>
import moment from 'moment'
import ScoreMixin from '@/mixins/Score.js'
import { get, isEmpty } from 'lodash'
import getCourseCompetenciesMixin from '@/mixins/GetCourseCompetencies.js'

export default {
  name: 'pi-widget-competency-xp-earned',
  components: {
    PiCompetence: () => import('@/components/Ui/Competence'),
  },
  mixins: [
    ScoreMixin,
    getCourseCompetenciesMixin,
  ],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCompetencyId', 'course', 'dataset', 'datasetsAlreadyComputed'],
  data () {
    return {
      statsBeforeStartingLessonAreLoaded: false,
      currentsStatsAreLoaded: false,
      competency: null,
    }
  },
  asyncComputed: {
    lastRecordBeforeStartingLesson: {
      get () {
        this.statsBeforeStartingLessonAreLoaded = true
        return this.currentUserRecord.getLastRecordBefore(this.currentLessonCreatedAt)
      },
      default: {},
    },
    userStats: {
      get () {
        this.currentsStatsAreLoaded = true
        return this.currentUserRecord.getLastRecord()
      },
      lazy: false,
    },
  },
  computed: {
    currentLessonCreatedAt () {
      return moment(get(this.$store.getters, 'currentlesson.createdAt'))
    },
    isLoading () {
      return !this.statsBeforeStartingLessonAreLoaded && !this.currentsStatsAreLoaded
    },
    isLoadingClass () {
      return {
        loading: this.isLoading,
      }
    },
    piWidgetStyle () {
      const nbComp = this.competencies.length
      if (nbComp % 4 === 0) {
        return { padding: `1em ${140}px` }
      } else if (nbComp % 3 === 0) {
        return { padding: `1em ${200}px` }
      } else {
        return { padding: `1em ${80}px` }
      }
    },
    competencies () {
      const out = []
      if (isEmpty(this.getVisibleRootCompetencies)) return
      for (const i in this.getVisibleRootCompetencies) {
        const competency = this.getVisibleRootCompetencies[i]
        const actualRatio = get(this.userStats, ['computedScoring', 'competencies', competency.id])
        const pastRatio = get(this.lastRecordBeforeStartingLesson, ['computedScoring', 'competencies', competency.id]) || 0
        const done = actualRatio
        let deltaStart = actualRatio
        const progression = (actualRatio - pastRatio)

        const delta = Math.abs(progression)
        if (progression >= 0) {
          deltaStart = actualRatio - delta
        }
        out.push({
          icon: competency.icon,
          title: competency.title,
          done,
          delta,
          deltaStart,
          deltaSign: progression < 0 ? -1 : 1,
        })
      }
      return out
    },
  },
  created () {
    this.$store.dispatch('updateUserScore')
    window._vm_widget_competency_xp_earned = this
  },
}
</script>
<style lang="sass">
.pi-widget-competency-xp-earned
  .competency
    transition: opacity .5s linear

</style>
