export default {
  getMatches: function (input, regex, index) {
    index || (index = 1)
    const matches = []
    let match = regex.exec(input)
    let limit = 100
    while (match != null && limit > 0) {
      matches.push(match[index])
      input = input.substring(match.index + match[index].length)
      match = regex.exec(input)
      limit--
      if (limit <= 0) {
        console.error('[ REGEXP ] boucle infinie dans getMatches')
      }
    }
    return matches
  },
  getPermissiveRegFromString (query) {
    const regexp = query.toLowerCase()
      .replace(/[[]]/g, '.')
      .replace(/[eêèéë]/g, '[eêèéëEÊÉÈË]')
      .replace(/[aàäâ]/g, '[aàäâAÂÄÀ]')
      .replace(/[iîï]/g, '[iîïIÎÏ]')
      .replace(/[uûü]/g, '[uûüUÛÜ]')
      .replace(/[oôö]/g, '[oôöOÔÖ]')
      .replace(/[cç]/g, '[cçCÇ]')
      .replace(/[^a-z0-9\-_ eêèéëEÊÉÈËaàäâAÂÄÀiîïIÎÏuûüUÛÜoôöOÔÖcçCÇ[\]]{1}/g, '.')
      .replace(/[ ]{2,}/g, '.*')
      .replace(/[ ]/g, '.')
    return regexp
  },
  getGlossaireRegFromWord (query) {
    const regexp = query
      .replace(/[[]]/g, '.')
      .replace(/[E]/g, '[eêèéëEÊÉÈË]')
      .replace(/[A]/g, '[aàäâAÂÄÀ]')
      .replace(/[I]/g, '[iîïIÎÏ]')
      .replace(/[U]/g, '[uûüUÛÜ]')
      .replace(/[O]/g, '[oôöOÔÖ]')
      .replace(/[C]/g, '[cçCÇ]')
    return regexp
  },
}
