<template lang="pug">
  doctype html
  .pi-calculator.widget(
    :class="{move: ismoving}",
    :style="style"
    @click="focusDisplay"
    @keyup="watchKeyUps" ,
    @keyup.enter="doTotal"
    )
    .dragarea(
      @mousedown.stop.capture.prevent="onMouseDown",
      @touchstart.stop.capture.prevent="onMouseDown",
      @mouseup.stop.capture="onMouseUp"
      @touchend.stop.capture="onMouseUp"
    )
    a.close(@click="close")
      i.el-icon-pi-close
    el-row
      div(class="panel panel-default")
        div(class="panel-heading")
          .calculatorDisplay(
            @click.prevent="selectDisplay",
            @mousedown.stop=""
            )
            el-input(
              v-model="display",
              type="text",
              placeholder="0",
              :maxlength="10",
              readonly)
    el-row

      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number.trigo(@click="setOperation('sin')")
          | sin
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number.trigo(@click="setOperation('cos')")
          | cos
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number.trigo(@click="setOperation('tan')")
          | tan
      el-col(:span="6")
        .el-button.btn__square.bg-primary.clear(@click="clearAll")
          | C

    el-row
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number.trigo(@click="setOperation('asin')")
          | asin
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number.trigo(@click="setOperation('acos')")
          | acos
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number.trigo(@click="setOperation('atan')")
          | atan
      el-col(:span="6")
        .el-button.btn__square.bg-secondary.operator(@click="setOperator('/', $event)")
          | &divide;
    el-row
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(7)")
          | 7
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(8)")
          | 8
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(9)")
          | 9
      el-col(:span="6")
        .el-button.btn__square.bg-secondary.operator(@click="setOperator('*', $event)")
          | &times;
    el-row
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(4)")
          | 4
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(5)")
          | 5
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(6)")
          | 6
      el-col(:span="6")
        .el-button.btn__square.bg-secondary.operator(@click="setOperator('-', $event)")
          | &minus;
    el-row
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(1)")
          | 1
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(2)")
          | 2
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(3)")
          | 3
      el-col(:span="6")
        .el-button.btn__square.bg-secondary.operator(@click="setOperator('+', $event)")
          | &plus;
    el-row
      el-col(:span="6")
        .el-button.btn__square.bg-secondary.operator(@click="plusMinus")
          | &plusmn;
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(0)")
          | 0
      el-col(:span="6")
        .el-button.btn__square.bg-tertiary.number(@click="writeNumber(DOT)")
          | ,
      el-col(:span="6")
        .el-button.btn__square.bg-secondary.operator(@click="doTotal")
          | &equals;
</template>

<script>
/* eslint-disable no-eval */
import { includes } from 'lodash'
import UtilMixin from '@/mixins/Utils.js'

const ERROR = 'ERROR'
const DOT = '.'
const COMMA = ','

export default {
  name: 'calculator',
  components: {
  },
  mixins: [UtilMixin],
  props: [],
  data () {
    return {
      ismoving: false,
      top: 0,
      left: 0,
      clickX: 0,
      clickY: 0,
      lastX: 0,
      lastY: 0,
      DOT,
      calcTotal: 0,
      display: '',
      currentEntry: '',
      calcHistory: [],
      lastOperator: '',
      symbole: '',
      allowedOperators: ['+', '-', '*', '/'],
      allowedNumbers: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
      allowedOperations: ['cos', 'sin', 'tan', 'acos', 'asin', 'atan'],
      allowedDot: ['.', ','],
    }
  },
  computed: {
    style () {
      const style = {
        top: `${this.top}px`,
        left: `${this.left}px`,
      }
      return style
    },
    currentNumberInput () {
      return `${this.symbole}${this.currentEntry}`
    },
    currentNumberInputDisplay () {
      return this.currentNumberInput.replace(DOT, COMMA)
    },
    result () {
      const op = this.calcHistory.join('')
      if (op === '') {
        return ''
      }
      let out = ''
      try {
        out = eval(op)
      } catch (error) {
        out = ERROR
      }
      if (!this.isNumeric(out)) {
        out = ERROR
      } else {
        out = +((+out).toFixed(8))
      }
      return out
    },
    resultDisplay () {
      let out = `${this.result}`
      if (!this.isNumeric(out)) {
        out = ERROR
      } else {
        out = out.replace(DOT, COMMA)
      }
      return out
    },
  },
  watch: {
  },
  mounted () {
    this.focusDisplay()
    this.top = 120
    this.left = (this.getWindowDimensions().width - this.$el.offsetWidth) - 50
  },
  methods: {
    selectDisplay (e) {
      const input = this.$el.querySelectorAll('.calculatorDisplay input')[0]
      input.setSelectionRange(0, input.value.length)
    },
    close () {
      this.$emit('close')
    },
    bindEvents () {
      this.ismoving = true
      window.addEventListener('mousemove', this.onMouseMove, false)
      window.addEventListener('touchmove', this.onMouseMove, false)
      window.addEventListener('mouseup', this.unbindEvents, false)
      window.addEventListener('touchend', this.unbindEvents, false)
    },
    unbindEvents () {
      this.ismoving = false
      window.removeEventListener('mousemove', this.onMouseMove, false)
      window.removeEventListener('touchmove', this.onMouseMove, false)
      window.removeEventListener('mouseup', this.unbindEvents, false)
      window.removeEventListener('touchend', this.unbindEvents, false)
    },
    onMouseMove (e) {
      this.left = (e.clientX || e.touches?.[0]?.clientX) - this.clickX + this.lastX
      this.top = (e.clientY || e.touches?.[0]?.clientY) - this.clickY + this.lastY
    },
    onMouseDown (e) {
      this.$el.querySelectorAll('.calculatorDisplay input')[0].focus()
      e.cancelBubble = true

      if (e.button !== 0 && e.type !== 'touchstart') {
        this.unbindEvents()
        return
      }
      this.clickX = e.clientX || e.touches?.[0]?.clientX
      this.clickY = e.clientY || e.touches?.[0]?.clientY
      this.lastX = this.left
      this.lastY = this.top
      this.bindEvents()
    },
    onMouseUp (e) {
      this.unbindEvents()
    },
    watchKeyUps (e) {
      if (e.key === 'Backspace') {
        this.clearAll()
      }
      if (includes(this.allowedNumbers, e.key)) {
        this.writeNumber(+e.key)
      }
      if (includes(this.allowedDot, e.key)) {
        this.writeNumber(this.DOT)
      }
      if (includes(this.allowedOperators, e.key)) {
        this.setOperator(e.key)
      }
    },
    clearAll () {
      this.$set(this, 'calcTotal', 0)
      this.$set(this, 'display', '')
      this.$set(this, 'currentEntry', '')
      this.$set(this, 'calcHistory', [])
      this.$set(this, 'lastOperator', '')
      this.$set(this, 'symbole', '')
    },
    setOperator (op = '') {
      if (this.display === ERROR) {
        this.clearAll()
      }
      this.lastOperator = op
      if (this.currentEntry === '' && this.calcHistory.length === 0) {
        this.currentEntry = '0'
      }
      if (this.currentEntry.length > 0) {
        if (this.calcHistory.length > 0 && !includes(this.allowedOperators, this.calcHistory[this.calcHistory.length - 1])) {
          this.$set(this, 'calcHistory', [])
        }
        this.calcHistory.push(` ${this.currentNumberInput} `)
        this.currentEntry = ''
        this.symbole = ''
      }
    },
    setOperation (op = '') {
      if (this.display === ERROR) {
        this.clearAll()
      }
      if (!this.allowedOperations.includes(op)) return
      if (this.currentEntry.length === 0) {
        this.currentEntry = '0'
      }
      if (['tan', 'cos', 'sin'].includes(op)) {
        this.currentEntry = `Math.${op}(${this.symbole}${this.currentEntry} * (Math.PI / 180))`
      } else {
        this.currentEntry = `Math.${op}(${this.symbole}${this.currentEntry}) * (180 / Math.PI)`
      }
      this.symbole = ''
      this.lastOperator = ''
      this.doTotal()
      return false
    },
    plusMinus () {
      this.symbole = (this.symbole === '') ? '-' : ''
      this.display = this.currentNumberInputDisplay
    },
    writeNumber (num = '') {
      num += ''
      if (this.display === ERROR) {
        this.clearAll()
      }

      if (this.currentEntry === '' && num === DOT) {
        this.currentEntry = '0'
      }
      if (num === DOT && this.currentEntry.indexOf(DOT) !== -1) {
        // qu'un point par saisie
      } else if (this.currentEntry === '0' && num !== DOT) {
        this.currentEntry = num
      } else {
        this.currentEntry = this.currentEntry + num
      }
      if (this.lastOperator.length > 0 && this.calcHistory.length > 0) {
        if (this.lastOperator !== '') {
          this.calcHistory.push(this.lastOperator)
        }
        this.lastOperator = ''
      }
      this.display = this.currentNumberInputDisplay
    },
    doTotal () {
      this.setOperator()
      this.calcHistory.unshift(' ( ')
      this.calcHistory.push(' ) ')
      this.$set(this, 'calcHistory', [` ${this.result} `])
      this.display = this.resultDisplay
      if (!this.isNumeric(this.result)) {
        this.currentEntry = ''
      } else {
        this.currentEntry = `${this.result}`
      }
    },
    focusDisplay: function () {
      this.$el.querySelectorAll('.calculatorDisplay input')[0].focus()
    },
    isNumeric (x) {
      return !(isNaN(x)) && (typeof x !== 'object') &&
        (x !== Number.POSITIVE_INFINITY) && (x !== Number.NEGATIVE_INFINITY)
    },
  },
}
</script>
