import { defaultsDeep } from 'lodash'
import routesStandalone from './routes.standalone'
import routesEmbeddable from './routes.embeddable'

const routes = [
  ...(process.env.VUE_APP_EMBEDDABLE === 'true' ? routesEmbeddable : routesStandalone),
  {
    path: '*',
    redirect: '404',
  },
]

const defaultRouteSettings = {
  meta: {
    permission: 'grains|client|public',
    fail: '/login',
  },
}

routes.forEach((el) => {
  defaultsDeep(el, defaultRouteSettings)

  if (el.children) {
    el.children.forEach((child) => {
      defaultsDeep(child, defaultRouteSettings)
    })
  }
})

export default routes
