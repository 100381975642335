<template lang="pug">
  doctype html
  .pi-account.test-badges
    table(style='width: 100%')
      tr
        td
          | STATIC
      tr
        td.text-align-center(v-for="ratio in static")
          PiBadge(:conf='ratio')
      tr
        td.text-align-center(v-for="ratio in staticRatios")
          | {{ Math.round(ratio * 100).toFixed() }} %
      tr
        td
          | PROGRESSIONS
      template(v-for="endRatio in endRatios")
        tr
          td.text-align-center(v-for="ratio in staticRatios")
            PiBadge(:conf='getRatioWithProgression(ratio, endRatio)')
        tr
          td.text-align-center(v-for="ratio in staticRatios")
            span(:style="getStyle(ratio, endRatio)")
              | {{ Math.round(ratio * 100).toFixed() }} % -> {{ Math.round(endRatio * 100).toFixed() }} %
</template>

<script>
import ScoreMixin from '@/mixins/Score.js'
import { map } from 'lodash'
const PiBadge = () => import('@/components/Ui/PiBadge')

export default {
  name: 'test-badges',
  components: {
    PiBadge,
  },
  mixins: [
    ScoreMixin,
  ],
  data () {
    return {
      staticRatios: [
        0.2,
        0.4,
        0.5,
        0.6,
        0.7,
        0.75,
        0.85,
        1,
      ],
      endRatios: [
        0.2,
        0.4,
        0.5,
        0.7,
        0.75,
        0.8,
        1,
      ],
    }
  },
  computed: {
    static () {
      return map(this.staticRatios, ratio => {
        return {
          level: this.getMedal(ratio),
          done: this.getMedalAccomplished(ratio),
          percent: ratio,
          type: 'PI',
          img: this.contextLogoBadge,
          size: 'xl',
        }
      })
    },
  },
  created () {
    window._badges = this
  },
  mounted () {
    document.querySelector('#app').style.backgroundImage = 'url()'
  },
  methods: {
    getStyle (from, to) {
      if (from === to) {
        return {
          padding: '10px',
        }
      }
      return {
        padding: '10px',
        color: 'white',
        'background-color': from > to ? 'rgb(255, 0, 0)' : 'rgb(0, 255, 0)',
      }
    },
    getRatioWithProgression (from, to) {
      const progression = (to - from)
      const conf = {
        level: this.getMedal(to), // or, argent, bronze
        done: this.getMedalAccomplished(to),
        percent: to,
        type: 'PI',
        img: this.contextLogoBadge,
        size: 'xl',
      }
      if (progression === 0) {
        return conf
      }
      let delta
      let deltaStart
      const hasChangeMedal = conf.level !== this.getMedal(from)
      if (progression >= 0) {
        if (hasChangeMedal) {
          delta = this.getMedalAccomplished(to)
          deltaStart = 0
        } else {
          delta = this.getMedalAccomplished(to) - this.getMedalAccomplished(from)
          deltaStart = this.getMedalAccomplished(from)
        }
      } else {
        if (hasChangeMedal) {
          deltaStart = this.getMedalAccomplished(to)
          delta = 1 - this.getMedalAccomplished(to)
        } else {
          deltaStart = this.getMedalAccomplished(to)
          delta = this.getMedalAccomplished(from) - this.getMedalAccomplished(to)
        }
      }
      return Object.assign({}, conf, {
        delta,
        deltaStart,
        deltaSign: progression < 0 ? -1 : 1,
      })
    },
  },
}
</script>
