import routeCommon from './routes.common.js'
import PiPortal from '@/components/Authentification/Portal'
import PiReset from '@/components/Authentification/Reset'
import PiAccount from '@/components/Content/Account'
import PiIndex from '@/components/Content/Index'
import TestBadges from '@/components/Test/Badges'
import Dashboard from '@/components/Dashboard/DashboardPage'
import UserCertificates from '@/components/Dashboard/tabs/UserCertificates'
import UserLevels from '@/components/Dashboard/tabs/UserLevels'
import UserJobs from '@/components/Dashboard/tabs/UserJobs'
import UserLogBook from '@/components/Dashboard/tabs/UserLogBook'
import UserStats from '@/components/Dashboard/tabs/UserStats'
import Mentions from '@/components/Legal/Mentions'

// const defaultRouteSettings = {
//   meta: {
//     permission: 'grains|client|public',
//     fail: '/login',
//   },
// }

const routes = [
  {
    path: '/',
    meta: {
      hasSidebar: true,
      breadcrumb: ['Mon espace', 'Mes parcours'],
    },
    component: PiIndex,
    name: 'Home',
  },
  {
    path: '/me',
    meta: {
      hasSidebar: true,
      breadcrumb: ['Mon espace', 'Mon profil'],
    },
    component: PiAccount,
    name: 'Account',
  },
  {
    path: '/login',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['Authentification au portail'],
    },
    component: PiPortal,
    name: 'login',
  },
  {
    path: '/reset/:token',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['Renouvellement de mot de passe'],
    },
    component: PiReset,
    name: 'ResetToken',
    props: true,
  },
  {
    path: '/reset/',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['Renouvellement de mot de passe'],
    },
    component: PiReset,
    name: 'reset',
    props: true,
  },
  ...routeCommon,
  {
    path: '/test-badges',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
      breadcrumb: ['badges'],
    },
    component: TestBadges,
    name: 'TestBadges',
  },
  {
    path: '/tableau-de-bord',
    name: 'Dashboard',
    meta: {
      hasSidebar: true,
      hasOnlyContent: false,
      breadcrumb: ['Mon espace', 'Mon tableau de bord'],
    },
    redirect: '/tableau-de-bord/niveaux',
    component: Dashboard,
    children: [
      {
        path: 'niveaux',
        name: 'DashboardLevels',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'levels',
        },
        component: UserLevels,
      },
      {
        path: 'metiers',
        name: 'DashboardJobs',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'jobs',
        },
        component: UserJobs,
      },
      {
        path: 'statistiques',
        name: 'DashboardStats',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'stats',
        },
        component: UserStats,
      },
      {
        path: 'validations',
        alias: ['certicates'],
        name: 'DashboardCertificates',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'certificates',
        },
        component: UserCertificates,
      },
      {
        path: 'logbook',
        name: 'DashboardLogbook',
        meta: {
          hasSidebar: true,
          hasOnlyContent: false,
          breadcrumb: ['Mon espace', 'Mon tableau de bord'],
          currentTab: 'logbook',
        },
        component: UserLogBook,
      },
    ],
  },
  {
    path: '/legal/mentions',
    name: 'LegalMentions',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
    },
    props: {
      legal: 'cgu',
    },
    component: Mentions,
  },
  {
    path: '/legal/protection-des-donnees',
    name: 'LegalDataProtection',
    meta: {
      permission: '',
      hasSidebar: false,
      hasOnlyContent: false,
    },
    props: {
      legal: 'rgpd',
    },
    component: Mentions,
  },
]

export default routes
