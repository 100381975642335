<template lang="pug">
  doctype html
  .pi-page-correction.page-correction
    template(v-if="messageReceived")
      pi-render-page(:id='lessonId', :isCorrectionMode='true', :pageId='pageId', :pageCorrection='pageCorrection')
    template(v-else)
      br
      br
      br
      center
        strong
        | Attendez 2 secondes

</template>

<script>
import store from '@/api/index.js'
import { isNil } from 'lodash'
import PiRenderPage from '@/components/Render/RenderPage'
window.store = store

export default {
  name: 'pi-page-correction',
  components: {
    PiRenderPage,
  },
  mixins: [],
  props: ['pageId', 'lessonId'],
  data() {
    return {
      messageReceived: false,
      pageCorrection: {},
    }
  },
  watch: {},
  created() {
    window._vm_preview = this
    const vm = this
    console.warn('IS CORRECTION : ', vm.pageId)

    this.init()
  },
  methods: {
    init() {
      if (process.env.VUE_APP_EMBEDDABLE === 'true') {
        // LessonId & PageId is received
        this.messageReceived = true
      } else {
        // Only pageId is received
        const vm = this
        this.pageCorrection = store.get('LessonPage', vm.pageId)

        if (!isNil(this.pageCorrection)) {
          this.lessonId = this.pageCorrection.lessonId
          this.messageReceived = true
        } else {
          this.$router.push({ name: '404' })
        }
      }
    },
  },
}
</script>
