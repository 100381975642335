<template lang="pug">
  doctype html
  .pi-index.pi-view(v-loading='isLoading')
    .medium-margin-top.text-align-center(v-if='!hasSessions && !isLoading')
      img(src="/static/images/desert.gif"
        style="height: auto; max-width: calc(100% - 80px); margin: auto;"
      )
      .large-margin(v-if="$mq === 'xs'")
        p Demandez un module conçu pour smartphone
          br
          | à votre formateur.
        p Retrouvez l'ensemble de vos modules
          br
          | sur tablette ou sur ordinateur.
      .large-margin-top.text-align-left(
        v-else-if="!$store.getters.SEARCH_isDefined"
        style="margin-left:auto;margin-right:auto; max-width:600px"
        )
        h2  Bienvenue !
        div(v-if="userCantBeNotified")
          p.font-size-1-5 Pour l'instant aucune session n'est planifiée.
          p.font-size-1-5 Dès que ça sera le cas, elles apparaîtront ici.
        div(v-else)
          p.font-size-1-5 Vous allez être inscrit(e) à une session de formation dans les prochaines heures.
          p.font-size-1-5 Un email vous sera envoyé pour vous en informer.
        div.font-size-1-5(v-if="contextExternalDemonstrationVideoLink")
          | En attendant, vous pouvez&nbsp;
          a.hover(@click="watchThis") visionner la démonstration
        p.font-size-1-5 À bientôt !
      div(v-else)
        | Aucune session de correspond à votre recherche.

    template(v-for="session in chunk")
      pi-session(
        v-if="session"
        :key="session.id"
        :sessionId="session.id"
        :sessionsOpened.sync="sessionsOpened"
        @see="seeProgramsHandler"
        @hide="hideProgramsHandler"
      )

    .text-align-center.large-margin-top
      el-pagination(
        v-show='sessions.length / pageSize > 1'
        :current-page.sync="currentPage"
        :total="sessions.length"
        :page-size="pageSize"
        background
        layout="prev, pager, next"
      )

</template>

<script>
import '@/libs/polyfill/promiseDelay'
import {
  chunk,
} from 'lodash'
import api from '@/api'
import { LessonRecord } from '@/api/models/Lesson'

export default {
  name: 'pi-index',
  components: {
    PiSession: () => import('../Render/Sessions/PiSession'),
  },
  mixins: [
  ],
  data () {
    return {
      currentPage: 1,
      pageSize: 5,
      isLoading: true,
      lessonsOpened: [],
      programsOpened: [],
    }
  },
  asyncComputed: {},
  computed: {
    hasSessions () {
      return this.sessions.length > 0
    },
    chunks () {
      return chunk(this.sessions, this.pageSize)
    },
    chunk () {
      return this.chunks[this.currentPage - 1]
    },
    sessions () {
      return this.$store.getters.DASHBOARD_classes || []
    },
    userCantBeNotified () {
      return (!this.CAN_USE_INTERNAL_LOGIN || !this.getCurrentUser.email)
    },
  },
  watch: {
    isLoading (value) {
      if (value) return
      const activeClassId = LessonRecord.getActiveClassOnDashboard()
      const currentActiveIndex = this.sessions.findIndex(session => session.id === activeClassId)
      this.currentPage = Math.max(Math.floor(currentActiveIndex / this.pageSize) + 1, 1)
    },
  },
  created () {
    this.$store.dispatch('loadClasses').then(() => {
      this.isLoading = false
    }).then(() => {
      setTimeout(() => {
        this.isLoading = false
        this.$store.dispatch('updateClassVisible')
      }, 1000)
    }).catch(error => {
      console.error('[INDEX created -> loadClasses] catch', error)
    })
    this.$store.dispatch('updateUserScore')
    this.setLayoutBackground(false)
    const lessonId = LessonRecord.getActiveLessonOnDashboard()
    if (lessonId) api.find('Lesson', lessonId)
    const classId = LessonRecord.getActiveClassOnDashboard()
    if (classId) api.find('Class', classId)
  },
  methods: {
    watchThis () {
      this.$store.commit('VIDEODIALOG_SET_VIDEO', this.contextExternalDemonstrationVideoLink)
    },
    seeProgramsHandler (programIds) {
      for (const programId of programIds) {
        this.programsOpened.push(programId)
      }
    },
    hideProgramsHandler (programIds) {
      for (const programId of programIds) {
        const index = this.programsOpened.indexOf(programId)
        if (index > -1) {
          this.programsOpened.splice(index, 1)
        }
      }
    },
    seeLessonsHandler (lessonIds) {
      for (const lessonId of lessonIds) {
        this.lessonsOpened.push(lessonId)
      }
      this.$store.dispatch('updateLessonsVisible', this.lessonsOpened)
    },
    hideLessonsHandler (lessonIds) {
      for (const lessonId of lessonIds) {
        const index = this.lessonsOpened.indexOf(lessonId)
        if (index > -1) {
          this.lessonsOpened.splice(index, 1)
        }
      }
      this.$store.dispatch('updateLessonsVisible', this.lessonsOpened)
    },
  },
}
</script>
<style lang="sass">
.pi-index
  padding-top: 30px
  padding-bottom: 30px
  min-height: 50vh
  .el-loading-mask
    background-color: transparent
</style>
