<template lang="pug">
  doctype html
  .pi-wysiwyg-table.wysiwyg-style
    table(v-if="table && show")
        tr(v-for="(row, rowIndex) in table", :key="rowIndex")
          td(v-for="(cell, columnIndex) in row", :key="columnIndex", :class="_get(cell, 'options.class', []).join(' ') + ' ' + `${cell.type}-cell`", :data-loc="`table:column-${columnIndex}:line-${rowIndex}`")

            .exercice(
              v-if="cell.type === 'exercice'",
              :data-value="_get(cell.value, 'value', cell.value)",
              :data-is="_get(cell.value, 'type')"
              )
            .text(v-if="cell.type === 'text' && cell.value !== null") {{ parseShortCodes(cell.value) }}

            .image(v-if="cell.type === 'image'")
              img(:src="getSrc(cell)")

</template>

<script>
import utils from '@/helpers/utils.js'

import RenderMixin from '@/mixins/Render.js'

export default {
  name: 'pi-wysiwyg-table',
  components: {},
  mixins: [RenderMixin],
  props: ['table', 'sections', 'dataset', 'datasetsAlreadyComputed'],
  data () {
    return {
      show: true,
    }
  },
  watch: {
    table () {
      this.rerender()
    },
  },
  beforeUpdate () {
    Object.assign(this.$data, this.$options.data.call(this))
  },
  created () {
    window._vm_wysiwyg_table = this
  },
  methods: {
    rerender () {
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    getSrc (obj) {
      return utils.getFilePath(obj.appFile.id)
    },
    getType (row) {
      if (row.is !== undefined) {
        return 'drag-n-drop'
      } else {
        return this._get(this, `${row.value}.type`)
      }
    },
  },
}
</script>
