<template lang="pug">
  doctype html
  .pi-resource-media-internal
      .media.interactif(v-if="appFile")
        img(
          v-if="appFile.fileType === 'image'"
          :class="className"
          :src="appFile.download"
        )
        vue-pdf-viewer(
          v-else-if="appFile.fileType === 'pdf'"
          :url="appFile.download"
          :audioFileId='row && row.settings && row.settings.audioFileId'
          height="100%"
          style="margin: 0;"
        )
        pi-sound-player(
          v-else-if="appFile.fileType === 'audio'"
          :url="appFile.download"
        )
</template>

<script>
import store from '@/api/index.js'
import VuePDFViewer from '@/components/Ui/VuePDFViewer.vue'
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import PiSoundPlayer from '@/components/Templates/PiSoundPlayer/PiSoundPlayer.vue'

export default {
  name: 'pi-resource-media-internal',
  components: {
    PiPop,
    'vue-pdf-viewer': VuePDFViewer,
    PiSoundPlayer,
  },
  mixins: [],
  props: [
    'row',
    'mediaHeight',
    'className',
  ],
  data () {
    return {}
  },
  asyncComputed: {
    appFile () {
      const appFileId = this.row.appFile.id
      return store.find('AppFile', appFileId).then((record) => {
        return record
      }).catch((err) => {
        console.warn('error', err)
      })
    },
  },
}
</script>
