<template lang="pug">
.user-stats
  .global
    h3.global-title Jusqu'à ce jour&nbsp;:

    el-row.datas
      el-col(:span="24")
        p Nombre de grains réalisés&nbsp;:
          strong &nbsp;{{ totalPageCount }}
        p(v-if="totalLessonTimeAlive") Temps passé sur les parcours&nbsp;:
          strong &nbsp;{{ totalLessonTimeAlive }}

  .charts.large-margin-top
    .chart__wrapper
      .charts__item(v-if="currentIndex === 0")
        h3.chart-title.text-align-center
          span Activité

        activity-chart(
          :competencies="competencies"
          :durationSelected="durationSelected"
          :showGlobal="showGlobal"
        )

      .charts__item(v-if="currentIndex === 1")
        h3.chart-title.text-align-center
          span Progression

        progression-chart(
          :competencies="competencies"
          :durationSelected="durationSelected"
          :showGlobal="showGlobal"
        )

      .charts__item(v-if="currentIndex === 2")
        h3.chart-title.text-align-center
          span Temps

        time-chart(
          :competencies="competencies"
          :durationSelected="durationSelected"
          :showGlobal="showGlobal"
        )

    .legend
      .medium-padding
        a.el-button.btn__square.btn__square-small(
          @click="moveTo(0)"
          :class="isIndex(0) ? 'bg-primary' : 'bg-tertiary'"
          :disabled="isIndex(0)"
        ) Activité
        a.el-button.btn__square.btn__square-small(
          @click="moveTo(1)"
          :class="isIndex(1) ? 'bg-primary' : 'bg-tertiary'"
          :disabled="isIndex(1)"
        ) Progression
        a.el-button.btn__square.btn__square-small(
          @click="moveTo(2)"
          :class="isIndex(2) ? 'bg-primary' : 'bg-tertiary'"
          :disabled="isIndex(2)"
        ) Temps

      charts-legend(
        :competencies="competencies"
        :durationOptions="durationOptions"
        :showGlobal.sync="showGlobal"
      )
</template>

<script>
import { get, isNull } from 'lodash'
import ScoreMixin from '@/mixins/Score.js'
import dates from '@/components/Dashboard/charts/config/dates.yml'
import getCourseCompetenciesMixin from '@/mixins/GetCourseCompetencies.js'

import utils from '@/helpers/utils.js'

export default {
  name: 'user-stats',
  components: {
    ChartsLegend: () => import('../stats/ChartsLegend.vue'),
    ActivityChart: () => import('../stats/Activity.vue'),
    ProgressionChart: () => import('../stats/Progression.vue'),
    TimeChart: () => import('../stats/Time.vue'),
  },
  mixins: [
    getCourseCompetenciesMixin,
    ScoreMixin,
  ],
  data () {
    return {
      currentIndex: 0,
      durationOptions: dates,
      legends: [
        'Utilisez le sélecteur pour choisir une durée sur laquelle visionner l\'évolution.',
        'Cliquez sur les éléments de la légende pour faire apparaitre/disparaitre les barres.',
      ],
      showGlobal: true,
    }
  },
  computed: {
    totalPageCount () {
      return get(this.getCurrentUser, 'donePageCount.global', 0)
    },
    totalLessonTimeAlive () {
      const globalTimeAlive = get(this.getCurrentUser, 'timeAlive.global', 0)
      return utils.humanizedDuration(globalTimeAlive)
    },
    competencies () {
      return this.getVisibleRootCompetencies
    },
    durationSelected () {
      const { chartTimeZone } = this.$store.state.optionsInterface
      if (isNull(chartTimeZone)) {
        return this.durationOptions[0].value
      } else {
        return chartTimeZone
      }
    },
  },
  created () {
    window._vm_user_stats = this
  },
  methods: {
    isIndex (i = 0) {
      return this.currentIndex === i
    },
    moveTo (index) {
      this.currentIndex = index
    },
  },
}
</script>
