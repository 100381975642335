<template lang="pug">
  doctype html
  .pi-badge-hexa
    .ui-badge-hexa
      i(v-if="icon", :class="icon")
      <svg width="106px" height="119px" viewBox="0 0 106 119" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs></defs>
          <g id="parcours-electricite" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="badge-hexa" transform="translate(-654.000000, -258.000000)" fill="#F5A623">
                  <g id="MESSAGE-intro" transform="translate(276.000000, 125.000000)">
                      <g id="picto-meca" transform="translate(378.000000, 133.000000)">
                          <path d="M100.868346,26.6393478 L56.84594,1.11586957 C54.2848677,-0.372391304 50.9514497,-0.371086957 48.3929735,1.11456522 L4.30826102,26.6380435 C1.70565079,28.1471739 0,30.9606522 0,33.9802174 L0,85.0284783 C0,88.0480435 1.69916049,90.8615217 4.30047266,92.3706522 L48.338455,117.895435 C49.6196402,118.638913 51.108515,119.009348 52.5779189,119.009348 C54.0460247,119.009348 55.524515,118.638913 56.8069982,117.895435 L100.863153,92.3719565 C103.465764,90.8628261 105.142857,88.0493478 105.142857,85.0297826 L105.142857,33.9815217 C105.142857,30.9619565 103.470956,28.1484783 100.868346,26.6393478" id="Fill-1"></path>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
</template>

<script>

/** @module View */
export default {
  name: 'pi-badge-hexa',
  components: {},
  mixins: [],
  props: ['icon'],
  data () {
    return {
    }
  },
  computed: {},
  methods: {},
}
</script>
