<template lang="pug">
  doctype html
  .bo-page-correction.page-correction
    template(v-if="messageReceived")
      pi-render-page(:id='lessonId', :pageCorrection='pageCorrection' :isCorrectionMode='true')
    template(v-else)
      br
      br
      br
      center
        strong
        | Attendez 2 secondes

</template>
z

<script>
import store from '@/api/index.js'

import PiRenderPage from '@/components/Render/RenderPage'
window.store = store

export default {
  name: 'pi-bo-page-correction',
  components: {
    PiRenderPage,
  },
  mixins: [],
  data() {
    return {
      lessonId: null,
      messageReceived: false,
      pageCorrection: {},
    }
  },
  watch: {},
  mounted() {
    if (window.parent) {
      window.parent.postMessage('mounted', 'http://localhost:4321')
    }
  },
  created() {
    window._vm_preview = this
    window.addEventListener('message', this.onMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage)
  },
  methods: {
    init() {
      this.pageCorrection = store.get('LessonPage', 'id-correction-page')
      this.lessonId = this.pageCorrection.lessonId
      this.messageReceived = true
    },
    onMessage(event) {
      const vm = this
      // if (~event.origin.indexOf(vm.contextBackoffice)) {
      const datas = event.data
      datas.page.id = 'id-correction-page'
      datas.page._id = 'id-correction-page'
      datas.page.lessonId = 'id-correction-lesson'
      store.add('LessonPage', datas.page)
      datas.lesson.id = 'id-correction-lesson'
      datas.lesson._id = 'id-correction-lesson'
      store.add('Lesson', datas.lesson)
      vm.init()
      // }
    },
  },
}
</script>
