<template lang="pug">
  doctype html
  .pi-widget-job-xp-earned
    .pi-widget.interactif(v-if="category")
      figure.job
        figcaption.title
          | {{ $t(`components['job-xp-earned'].title`) }}
        PiBadge(v-if="_get(category, 'icon')", :conf="category", size="large")

</template>

<script>
import store from '@/api/index.js'
import PiBadge from '@/components/Ui/PiBadge'

import ScoreMixin from '@/mixins/Score.js'

export default {
  name: 'pi-widget-job-xp-earned',
  components: { PiBadge },
  mixins: [ScoreMixin],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
    }
  },
  asyncComputed: {
    category: {
      get () {
        const vm = this
        if (!vm.courseCategoryId) return
        const id = vm.courseCategoryId
        return store.find('CourseCategory', id).then((record) => {
          const ratio = this.getRatioCategory(record.id)
          return {
            level: this.getMedal(ratio),
            type: 'metier',
            icon: record.icon,
            done: this.getMedalAccomplished(ratio),
            size: 'xl',
          }
        })
      },
      default: null,
    },
  },
  created () {
    window._vm_widget_job_xp_earned = this
  },
  methods: {},
}
</script>
