<template lang="pug">
  .user-jobs.text-align-center
    h3.pane-title {{ $tc('components.dashboard.tabs.jobs.title') }}

    .pi-competences
      .pi-competence(
        v-for='category, key in categories'
        v-if="category.isStarted"
        :key="category.category.id"
      )
        PiBadge(:conf="category", size="large")
        br
        span {{ category.category.title }}
        br
        span(:class="`tc-${category.levelColor}`") {{ category.percent }} / 100

</template>

<script>
import { get, isNumber, map } from 'lodash'
import api from '@/api'
import ScoreMixin from '@/mixins/Score.js'
export default {
  name: 'user-jobs',
  components: {
    PiBadge: () => import('@/components/Ui/PiBadge'),
  },
  mixins: [
    ScoreMixin,
  ],
  data () {
    return {
    }
  },
  computed: {
    categories () {
      this.$store.getters.DSCourseCategory
      const categories = api.getAll('CourseCategory')

      return map(categories, el => {
        const ratio = get(this.stats, ['computedScoring', 'categories', el.id], false)
        return {
          levelColor: this.getColor(ratio),
          category: el,
          isStarted: isNumber(ratio),
          ratio,
          percent: (`${Math.round(ratio * 100)}`).replace('.', ','),
          level: this.getMedal(ratio),
          type: 'metier',
          icon: el.icon,
          done: this.getMedalAccomplished(ratio),
          size: 'xl',
        }
      })
    },
  },
  created () {
    window._vm_user_jobs = this
  },
}
</script>
<style lang="sass">
.user-jobs
  .pi-competences
    margin-top: 32px
</style>
