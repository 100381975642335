<template lang="pug">
  doctype html
  .pi-medias-by-mail
    h4 {{ title }}
      pi-pop(:message="order")
    .pi-widget()
        el-row.medias(v-for="resource, resourceIndex in resources", :key="`resource-${resourceIndex}`")
          el-col.description(:span="20")
            el-checkbox(:true-label="resource.id", @change="changeCheckbox($event, resource.id)")
            i(:class="`el-icon-${resource.icon}`")
            strong {{ resource.name }}

          el-col.text-align-right(:span="4")
            el-button.btn__square.btn__square-small.bg-tertiary(@click="see(resource)") Aperçu
        el-collapse-transition
          .text-align-center(v-show="!_isEmpty(choosenMedias)")
            el-button.btn__square.btn__square-small.bg-primary(@click="send") Recevoir ma sélection par mail
              i(class="el-icon-fa-envelope-o")

</template>

<script>
import store from '@/api/index.js'
import promiseMap from 'promise-map'
import { indexOf } from 'lodash'
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'

export default {
  name: 'pi-medias-by-mail',
  components: { PiPop },
  mixins: [],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      title: 'Documentation',
      order: 'Sélectionnez les documents que vous souhaitez recevoir par mail.',
      choosenMedias: [],
    }
  },
  computed: {
    attachmentIds: {
      get () {
        return this.row.data.attachmentIds
      },
      set (value) {
        this.row.data.attachmentIds = value
      },
    },
  },
  asyncComputed: {
    resources: {
      get () {
        return Promise.resolve(this.attachmentIds).then(promiseMap((attachmentId) => {
          return store.find('AppFile', attachmentId)
        }))
      },
      default: [],
    },
  },
  watch: {},
  created () {
    window._vm_pi_medias_by_mail = this
  },
  methods: {
    send () {
      this.contactDocument(this.choosenMedias)
    },
    see (resource) {
      const r = {
        courseBlock: {
          type: 'slide',
        },
        section: {
          rows: [
            {
              appFile: {
                id: resource.id,
              },
              type: 'media-internal',
              title: resource.name,
            },
          ],
        },
      }
      this.setResourceToDisplayInModal(r)
    },
    changeCheckbox (evt, id) {
      const indexof = indexOf(this.choosenMedias, id)
      if (indexof === -1) {
        this.choosenMedias.push(id)
      } else {
        this.choosenMedias.splice(indexof, 1)
      }
    },
  },
}
</script>
