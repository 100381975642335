<template lang="pug">
  doctype html
  .pi-widget-course-icon
    .pi-widget.interactif(v-if="category")
      PiBadgeHexa(:icon="`el-icon-${category.icon}`")
</template>

<script>
import store from '@/api/index.js'
import PiBadgeHexa from '@/components/Ui/BadgeHexa.vue'

export default {
  name: 'pi-widget-course-icon',
  components: {
    PiBadgeHexa,
  },
  mixins: [],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      category: null,
    }
  },
  computed: {},
  watch: {
    courseCategoryId () {
      this.process()
    },
  },
  created () {
    this.process()
    window._vm_widget_course_icon = this
  },
  methods: {
    process () {
      this.$nextTick(this.getCategory)
    },
    getCategory () {
      const vm = this
      if (!vm.courseCategoryId) return
      const id = vm.courseCategoryId
      store.find('CourseCategory', id).then((record) => {
        vm.$set(vm, 'category', record)
      }).catch((error) => {
        console.warn('CATEGORY NOT FOUND', error)
      })
    },
  },
}
</script>
