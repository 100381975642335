<template lang="pug">
.evaluate-model.display-fullscreen()
  .frame
    .frame-content
      h1.text-align-center Évaluez ce {{ $tc(`models.${type}.title`) | toLowerCase}} !

      el-form.block-center(
        style="max-width:480px;"
        ref='evaluate'
        :model="form"
        label-width="120px"
        label-position='top'
        :rules='rules'
      )
        el-rate.block-center.flex-middle-center.rate-medium(
          :max="maxRate"
          v-model='form.rate'
          :disabled='alreadySent'
          )
        el-form-item.el-form-item--hidden.block-center(
          prop="rate"
        )
          el-input(
            type="hidden"
            v-model="form.rate"
          )

        el-form-item.block-center(
          label="Laissez-nous un commentaire :"
          prop="message"
        )
          el-input(
            :rows="5"
            type="textarea"
            v-model="form.message"
            :disabled='alreadySent'
          )
          .counter.tc-base-silver
            small {{ count }} / {{ messageLimit }}

        el-alert.medium-margin-bottom(
          v-if="!_isEmpty(alert)",
          :title="alert.title",
          :type="alert.type",
          :description="alert.description",
          @close="closeAlert",
          show-icon
        )

        .buttons.large-padding-top.display-flex.justify-content-center(
          v-if="!alreadySent"
        )
          el-button.btn__square.bg-tertiary(
            @click="submitForm('evaluate')"
            :loading="isSending"
          )
            | Envoyer&nbsp;

        .buttons.large-padding-top.display-flex.justify-content-center(
          v-if="canContinue"
        )
          el-button.btn__square.bg-tertiary(
            @click="backToHome({ clear: false })"
          )
            | Retour au tableau de bord&nbsp;

</template>

<script>
import { Program } from '@/api/models/Program'
import { isString } from 'lodash'
import api from '@/api'
import { ErrorMessage } from '@/domains/ErrorMessage/ErrorMessage'

export default {
  name: 'evaluate-program',
  components: {
  },
  mixins: [],
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator (value) {
        return [
          Program.name,
        ].includes(value)
      },
    },
  },
  data () {
    return {
      maxRate: 5,
      alert: {},
      canContinue: false,
      alreadySent: false,
      messageLimit: 1000,
      form: {
        rate: null,
        message: '',
      },
      isSending: false,
      rules: {
        rate: [
          {
            required: true,
            validator: (_rule, value, callback) => {
              if (value === null || value === 0) {
                callback(new Error('Veuillez choisir une note'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        message: [
          {
            required: false,
            validator: (_rule, value, callback) => {
              if (isString(value) && value.length > 1000) {
                callback(new Error('Veuillez saisir un message de moins de 1000 caractères'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    count () {
      return this.form.message.length
    },
  },
  asyncComputed: {
  },
  watch: {
    'form.rate' (value) {
      if (value) {
        this.$refs.evaluate.validateField('rate')
      }
    },
  },
  created () {
    window._page = this
  },
  methods: {
    submitForm (formName) {
      this.closeAlert()
      this.$refs[formName].validate((valid) => {
        this.closeAlert()
        if (valid) {
          this.isSending = true
          this.sendEvaluation()
        } else {
          return false
        }
      })
    },
    async sendEvaluation () {
      console.log('sendEvaluation')
      const review = {
        classId: this.$store.getters.currentlesson?.classId,
        comment: this.form.message,
        resourceType: this.type,
        resourceId: this.id,
        ratio: this.form.rate / this.maxRate,
      }

      try {
        await api.create('Review', review)
        this.$set(this, 'alert', {
          type: 'success',
          title: 'Merci',
          description: 'Nous avons bien reçu votre évaluation.',
        })
        this.alreadySent = true
      } catch (error) {
        ErrorMessage.normalize(error).forEach(error => {
          this.$set(this, 'alert', {
            type: 'error',
            title: error.title,
            description: error.message,
          })
        })
      }
      this.canContinue = true
      this.isSending = false
    },
    closeAlert () {
      this.$set(this, 'alert', {})
    },
  },
}
</script>
<style lang="sass">
.evaluate-model
  .counter
    position: absolute
    right: 0
    line-height: 1rem
    padding-top: 5px
  .el-form-item__error
    max-width: calc(100% - 50px)
  .el-form-item--hidden
    max-width: 200px
    .el-input
      display: none
    & + *
      margin-top: 40px
    .el-form-item__error
      max-width: calc(100% - 10px)
      width: calc(100% - 10px)
      text-align: center
  .el-alert__icon
    display: none
</style>
