
<template lang="pug">
  doctype html
  .pi-image-cliquable
    .exerciceType
      h4(v-exposant) {{ parseShortCodes(row.message) }}
        pi-pop(:message="getOrder()")
    .contain.interactif(
      v-if="getSrc()"
      :class="'size-' + _get(row, 'settings.appFile.options.size', '')"
    )
      svg(
        :viewBox="viewBox"
        preserveAspectRatio="xMidYMid meet"
        height="100%",
        width="100%")
        defs
          pattern(id="wrongAnswer" patternUnits="userSpaceOnUse" width="6" height="6")
            path(d="M-1,1 l2,-2 M0,6 l6,-6 M5,7 l2,-2")
          mask(id="mask")
            rect(height="100%" width="100%" style="fill: url(#wrong)")

        template(v-for="(choice, choiceIndex) in row.choices")
          circle.svg-answer(
            :class="getClass(choiceIndex)"
            v-if="choice.svg.tag == 'circle'",
            :cx="choice.svg.x",
            :cy="choice.svg.y",
            :r="choice.svg.r",
            stroke-width="3",
            @click.stop.prevent="clickHandler(choiceIndex)")
          rect.svg-answer(
            :class="getClass(choiceIndex)"
            v-else-if="choice.svg.tag == 'rectangle'",
            :x="choice.svg.x",
            :y="choice.svg.y",
            :width="choice.svg.width",
            :height="choice.svg.height",
            stroke-width="3",
            @click.stop.prevent="clickHandler(choiceIndex)")
          polygon(
            :class="getClass(choiceIndex)"
            v-else-if="choice.svg.tag == 'polygon'",
            :points="formatPolygonPoints(choice.svg.points)"
            stroke-width="3",
            @click.stop.prevent="clickHandler(choiceIndex)")

      img.fond(
        :src="getSrc()"
        )

</template>

<script>

import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import utils from '@/helpers/utils.js'
import RenderMixin from '@/mixins/Render.js'
import {
  get, includes,
} from 'lodash'

const SIZER = 808 // largeur du contenu

export default {
  name: 'pi-image-cliquable',
  components: { PiPop },
  mixins: [RenderMixin],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'isCorrectionMode', 'correctAnswer', 'datasetsAlreadyComputed'],
  data () {
    return {
      viewBox: `0 0 ${SIZER} ${SIZER}`,
      defaultOrder: 'Je clique dans l\'image sur la ou les bonnes réponses',
    }
  },
  computed: {
    indexedChoices () {
      this.row.choices.forEach((choice, index) => {
        choice.index = index
      })
      return this.row.choices
    },
  },
  watch: {
    row: {
      deep: true,
      immediate: true,
      handler () {
        const img = new Image()
        const scope = this
        img.onload = function () {
          const h = (this.height * (SIZER / this.width))
          scope.viewBox = `0 0 ${SIZER} ${h}`
        }
        const src = utils.getFilePath(get(this, 'row.settings.appFile.id'))
        img.src = src
      },
    },
  },
  created () {
    window._ic = this
  },
  methods: {
    formatPolygonPoints (points = []) {
      return points.map(point => { return [point.x, point.y] }).join(', ')
    },
    getClass (index) {
      const correctAnswers = get(this, 'correctAnswer.answer.all', [])
      const userAnswers = get(this, 'reponse.userAnswer', [])
      const expectedAnswer = this.isCorrectionMode && includes(correctAnswers, index)
      const goodAnswer = this.isCorrectionMode && (
        (includes(correctAnswers, index) && includes(userAnswers, index)) ||
        (!includes(correctAnswers, index) && !includes(userAnswers, index))
      )
      const wrongAnswer = this.isCorrectionMode && (includes(userAnswers, index) && !expectedAnswer)

      return {
        selected: includes(userAnswers, index),
        expectedAnswer,
        goodAnswer,
        wrongAnswer,
      }
    },
    clickHandler (index) {
      if (this.isCorrectionMode) return
      if (includes(this.reponse.userAnswer, index)) {
        this.reponse.userAnswer.splice(this.reponse.userAnswer.indexOf(index), 1)
      } else {
        this.reponse.userAnswer.push(index)
      }
    },
    getSrc () {
      const src = utils.getFilePath(get(this, 'row.settings.appFile.id'))
      return src
    },
  },
}
</script>
