<template lang="pug">
  doctype html
  .pi-pick-words
    .exerciceType
      template(v-if="!_get(row, 'settings.questionIsHidden', false)")
        h4(v-exposant)
          | {{ parseShortCodes(row.message) }}
          pi-pop(:message="getOrder()")
        .interactif
        //- pour que l'exo reste visible

</template>

<script>

import RenderMixin from '@/mixins/Render.js'
import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'

export default {
  name: 'pi-pick-words',
  components: { PiPop },
  mixins: [RenderMixin],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      item: [],
      defaultOrder: 'Je clique dans le texte sur la ou les bonnes réponses',
    }
  },
  computed: {},
  watch: {
    wordPicked: {
      handler (datas) {
        this.item = this.wordPicked
        this.reponse.userAnswer = this.item
      },
      deep: true,
    },
  },
  created () {
    window._vm_pick_words = this
    this.doItem()
  },
  methods: {
    doItem () {
      this.item = this.wordPicked
    },
  },
}
</script>
