<template lang="pug">
.pi-free-answer
  .exerciceType
    h4(v-exposant) {{ row.message }}
      pi-pop(:message="getOrder()")
  .questions
    .interactif(
      @mouseover="printCorrection($event)"
      @mouseout="printUserAnswer($event)"
    )
      el-input(
        :data-order='row.order ?  1 : 0'
        v-model='reponse.userAnswer',
        :placeholder="$t(`components['free-answer'].placeholder`)",
        :style="style",
        :disabled="isCorrectionMode",
        :class="correctionClass")
      pi-pop(
        v-if="row.order"
        :message="getOrder()"
      )

</template>

<script>

import PiPop from '@/components/Templates/PiPop/Pi-Pop.vue'
import RenderMixin from '@/mixins/Render.js'
import { get, isNumber } from 'lodash'
export default {
  name: 'pi-free-answer',
  components: { PiPop },
  mixins: [RenderMixin],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'isCorrectionMode', 'correctAnswer', 'datasetsAlreadyComputed'],
  data () {
    return {
      defaultOrder: 'Je saisis la bonne réponse',
    }
  },
  computed: {
    style () {
      let width = 175
      const defaultWidth = get(this.row, 'settings.defaultWidth')
      if (defaultWidth && isNumber(defaultWidth)) {
        width = defaultWidth
      }
      const s = {
        width: `${width}px`,
      }
      return s
    },
    correctionClass () {
      if (!this.isCorrectionMode) return ''
      const goodAnswer = (this.correctAnswer.result === 1)
      const wrongAnswer = !goodAnswer
      const expectedAnswer = goodAnswer

      return { expectedAnswer: expectedAnswer, goodAnswer: goodAnswer, wrongAnswer: wrongAnswer }
    },
  },
  watch: {},
  created () {
    window._vm_free_answer = this
  },
  methods: {
    printCorrection (e) {
      if (!this.isCorrectionMode || !this.correctionClass.wrongAnswer) return
      e.currentTarget.firstChild.classList.toggle('expectedAnswer')
      e.currentTarget.firstChild.classList.toggle('wrongAnswer')
      const correctArray = []
      for (const i in this.correctAnswer.answer.in) {
        const text = this.correctAnswer.answer.in[i]
        correctArray.push(this.parseShortCodes(text))
      }
      e.currentTarget.firstChild.querySelector('input').value = `"${correctArray.join('" ou "')}"`
    },
    printUserAnswer (e) {
      if (!this.isCorrectionMode || !this.correctionClass.wrongAnswer) return
      e.currentTarget.firstChild.classList.toggle('wrongAnswer')
      e.currentTarget.firstChild.classList.toggle('expectedAnswer')
      e.currentTarget.firstChild.querySelector('input').value = this.reponse.userAnswer
    },
  },
}
</script>
