<template lang="pug">
  doctype html
  .pi-widget-pi-xp-earned
    .pi-widget.interactif(v-loading="isLoading"  :class="isLoadingClass")
      figure.pi(v-if="showPi")
        figcaption.title
          | Votre {{ contextLevelLabel }}
        PiBadge(:conf="piScoreBadgeWithProgression")

      figure.score(v-if="getParcoursStats")
        figcaption.title
          | {{ $t('components.parcours-xp.title') }}
        PiBadge(:conf='getParcoursStats.categoryProperties', size="large")
        .points
          rate-with-info(
            :percent="getParcoursStats.lessonRatio",
            :tooltip='true'
            )

</template>

<script>
import RateWithInfo from '@/components/Ui/RateWithInfo'
import PiBadge from '@/components/Ui/PiBadge'
import ScoreMixin from '@/mixins/Score.js'
import store from '@/api'
import _ from 'lodash'
import moment from 'moment'
const { get, isEmpty, isNull, isNaN } = _
export default {
  name: 'pi-widget-pi-xp-earned',
  components: { PiBadge, RateWithInfo },
  mixins: [ScoreMixin],
  props: ['row', 'nbSection', 'rowIndex', 'reponse', 'conf', 'wordPicked', 'courseCategoryId', 'course', 'dataset', 'datasetsAlreadyComputed', 'rowCount'],
  data () {
    return {
      statsBeforeStartingLessonAreLoaded: false,
    }
  },
  asyncComputed: {
    lastRecordBeforeStartingLesson: {
      get () {
        return this.currentUserRecord
          .getLastRecordBefore(this.currentLessonCreatedAt)
          .then(datas => {
            this.statsBeforeStartingLessonAreLoaded = true
            return datas
          })
      },
      default: {},
    },
    userStats: {
      get () {
        return this.currentUserRecord.getLastRecord()
      },
      lazy: false,
    },
    currentLessonScoring: {
      get () {
        if (!this.$store.getters.currentlesson) return false
        return store.find('Lesson', this.$store.getters.currentlesson.id, { force: true }).then((lesson) => {
          return this._get(lesson, 'scoring.global', { actual: 0, max: 0 })
        })
      },
      default: { actual: 0, max: 0 },
    },
  },
  computed: {
    currentLessonCreatedAt () {
      return moment(get(this.$store.getters, 'currentlesson.createdAt'))
    },
    isLoading () {
      return !this.statsBeforeStartingLessonAreLoaded
    },
    isLoadingClass () {
      return {
        loading: this.isLoading,
      }
    },
    showPi () {
      return get(this.row, 'data.showPi', true)
    },
    getParcoursStats () {
      if (!get(this.row, 'data.showScore', true)) return false
      this.$store.getters.DSCourseCategory
      const category = store.get('CourseCategory', this.courseCategoryId)
      if (isEmpty(category)) return false

      let lessonScore = { actual: 0, max: 0 }
      if (this.currentLessonScoring) {
        lessonScore = this.currentLessonScoring
      }
      if (isNull(lessonScore)) return false
      let lessonRatio = lessonScore.actual / lessonScore.max
      if (isNaN(lessonRatio)) {
        lessonRatio = 0
      }

      const out = {
        categoryProperties: {
          level: this.getMedal(lessonRatio),
          type: 'metier',
          icon: category.icon,
          done: lessonRatio,
          size: 'xl',
        },
        lessonRatio,
      }
      return out
    },
    piScoreBadgeWithProgression () {
      const from = get(this.lastRecordBeforeStartingLesson, ['computedScoring', 'pi', 'competencies']) || 0
      const to = get(this.userStats, ['computedScoring', 'pi', 'competencies']) || 0
      return this.getPiBadgeWithProgression(from, to)
    },
  },
  created () {
    this.$store.dispatch('updateUserScore')
  },
}
</script>
<style lang="sass">
.pi-widget-pi-xp-earned
  figure
    transition: opacity .5s linear

</style>
