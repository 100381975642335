import store from '@/api'
import { INTERNAL_GENERIC_CONTEXT_ID } from '@/api/models/Context'

const orderBy = ['order', 'title']

export default {

  computed: {
    getVisibleCompetenciesTreeWithContexts () {
      return [{
        value: this.contextId,
        label: this.getCurrentContextCompetencyTitle('title', 2),
        disabled: true,
        children: this.getContextCompetenciesTree,
      }, {
        value: INTERNAL_GENERIC_CONTEXT_ID,
        label: this.getGenericCompetencyTitle('title', 2),
        disabled: true,
        children: this.getVisibleGenericCompetenciesTree,
      }]
    },
    getContextCompetenciesTree () {
      this.$store.getters.DSCourseCompetency
      return this.getCompetenciesTree(false)
    },
    getGenericCompetenciesTree () {
      this.$store.getters.DSCourseCompetency
      return this.getCompetenciesTree(true)
    },
    getVisibleGenericCompetenciesTree () {
      this.$store.getters.DSCourseCompetency
      let tree = this.getCompetenciesTree(true)
      for (let i = 1; i < this.contextGenericCompetenciesReportingLevel; i++) {
        tree = tree.map(el => el.children).filter(Boolean).flat()
      }
      return tree
    },
    getVisibleRootCompetencies () {
      const roots = []
      roots.push(this.getContextCompetenciesTree.map(c => store.get('CourseCompetency', c.value)))
      if (!this.is_generic) {
        roots.push(this.getVisibleGenericCompetenciesTree.map(c => store.get('CourseCompetency', c.value)))
      }
      return roots.flat()
    },

  },
  methods: {
    getCurrentContextCompetencyTitle (prop = 'title', qty = 1) {
      let something = this.$tc(`models.CourseCompetency.${prop}`, qty)
      something += ` ${this.contextName}`
      return something
    },
    getGenericCompetencyTitle (prop = 'title', qty = 1) {
      let something = this.$tc(`models.CourseCompetency.${prop}`, qty)
      something += ` ${this.$tc('models.Context.generic.adj', qty)}`
      return something
    },
    getCompetenciesTree (isGeneric = false) {
      this.$store.getters.DSCourseCompetency
      const setNode = (node) => {
        this.$store.getters.DSCourseCompetency
        const where = { parentId: { '==': node.id }, isGeneric }
        const childs = store.filter('CourseCompetency', { where, orderBy })
        const el = {
          value: node.id,
          label: node.title,
        }
        const children = childs.map(setNode)
        if (!this._isEmpty(children)) {
          el.children = children
        }
        return el
      }
      const where = { parentId: { '==': null }, isGeneric }
      const racines = store.filter('CourseCompetency', { where, orderBy })
      return racines.map(setNode)
    },
  },
}
