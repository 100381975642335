<template lang="pug">
  doctype html
  .pi-signin-portal
    .recover-container
      template(v-if="currentUserRecord && !ended")
        el-row
          el-col(:span='12', :offset='6' :xs='{ span: 24, offset: 0 }')
            h1 {{ $t('actions.reset.action') }}
            p(style="line-height: 1.25;")
              | Votre mot de passe doit contenir au moins 6 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial.

            el-form(
              label-position="top"
              :rules="resetRules"
              label-width="100px"
              :model="resetForm"
              ref="resetForm"
            )
              el-form-item(
                :label="$t('attributes.password.new')"
                prop="newpassword"
              )
                .el-input
                  input.el-input__inner(
                    v-model="resetForm.newpassword"
                    autocomplete="new-password"
                    :type="passwordType"
                    @change="trimFields"
                    @keyup.enter="onSubmit"
                  )
                .pwdHelper.hover(@click="togglePwd")
                  i.el-icon-fa-eye

      el-row
        el-col(
          :span='12'
          :offset='6'
          :xs='{ span: 24, offset: 0 }'
        )
          pi-notifications.large-margin-bottom

      template(v-if="currentUserRecord && !ended")
        el-row
          el-col.auth-actions(
            :span='12'
            :offset='6'
            :xs='{ span: 24, offset: 0 }'
          )
            a.el-button.btn__square.bg-primary(@click="onSubmit") {{ $t('actions.validate.action') }}
            a.el-button.btn__square.bg-secondary(@click="backToHome") {{ $t('actions.cancel.name') }}

</template>

<script>
import api from '@/api'
import { trim } from 'lodash'

export default {
  name: 'pi-reset',
  components: {
    piNotifications: () => import('@/components/Ui/Notifications'),
  },
  props: ['token'],
  data () {
    return {
      ended: false,
      resetForm: {
        newpassword: '',
      },
      passwordType: 'password',
    }
  },
  computed: {
    resetRules () {
      const rules = {
        newpassword: [
          {
            required: true,
            min: 6,
            max: 254,
            message: 'Taille requise entre 6 et 254 caractères',
            trigger: 'blur',
          },
        ],
      }
      rules.newpassword.push({
        pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,}$/,
        message: 'Doit contenir au moins : une majuscule, une minuscule, un chiffre et un caractère spécial',
        trigger: 'blur',
      })
      return rules
    },
  },
  mounted () {
    if (!this.is_authenticated) {
      this.$store.dispatch('loadUserFromToken', this.token)
    }
  },
  methods: {
    togglePwd () {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
      } else {
        this.passwordType = 'password'
      }
    },
    trimFields (value) {
      this.resetForm.newpassword = trim(this.resetForm.newpassword)
    },
    onSubmit () {
      this.$refs.resetForm.validate((valid) => {
        if (valid) {
          this.currentUserRecord.password = this.resetForm.newpassword
          api.update('AppUser', this.getCurrentUser.id, { password: this.resetForm.newpassword }).then((response) => {
            this.ended = true
            this.$store.dispatch('logout').then(() => {
              this.$store.commit('addAlert', {
                icon: true,
                type: 'success',
                message: 'Votre mot de passe a bien été mis à jour ! Vous pouvez désormais vous connecter',
                persist: false,
                duration: 7000,
              })
            })
          }).catch((error) => {
            console.log('ERROR', error)
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>
