<template lang="pug">
  doctype html
  .ui-badge(:class="'ui-badge-'+conf.size+' ui-badge-'+conf.level+' ui-badge-'+conf.type+' '+conf.level")
    .radial-progress(
      v-if="conf.level && conf.size === 'xl' && conf.type === 'PI'"
      :data-progress="formatForPercent(conf.done)"
      :data-delta="formatForPercent(conf.delta)"
      :data-delta-start="formatForPercent(conf.deltaStart)")
      .circle
        .mask.full
          .fill
        .mask.half
          .fill
          .fill.fix
      .circle.delta(v-if="conf.delta")
        .mask.full
          .fill(:style="deltaStyle")
        .mask.half
          .fill(:style="deltaStyle")
          .fill.fix(:style="deltaStyle")
      .inset
        .icon
          i(v-if='conf.icon', :class="'el-icon-'+conf.icon")
    i(v-else-if='conf.icon', :class="'el-icon-'+conf.icon")
    .logo-badge(v-if='conf.img', :style="bgLogoBadgeStyle")
    img.certif-icon-image(v-else-if="conf.certif", src="../../../static/images/Badge-certification.png")
    img(:src="imgUrl", :alt="`${conf.type} ${conf.level}`")
</template>

<script>
import ScoreMixin from '@/mixins/Score.js'

/** @module View */

export default {
  name: 'pi-badge',
  components: {
  },
  mixins: [ScoreMixin],
  props: ['conf', 'size'],
  data () {
    return {
    }
  },
  computed: {
    bgLogoBadgeStyle () {
      return `background-image: url(${this.conf.img})`
    },
    bgsize () {
      if (this.size) {
        return `_${this.size}`
      }
      return ''
    },
    deltaStyle () {
      return {
        'background-color': this.conf.deltaSign > 0 ? '#00FF00' : '#FF0000',
      }
    },
    imgUrl: function (path) {
      return `/static/images/badges/badge-${this.conf.type}_${this.conf.level}${this.bgsize}.png`
    },
  },
  methods: {
    formatForPercent (n) {
      return Math.round(n * 100)
    },
  },
}
</script>
