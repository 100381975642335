<template lang="pug">
.pi-render-node(v-if="courseNode")
  template(v-if="currentNode")

    pi-render-course-block.brick(
      :courseId="currentNode.courseId",
      :id="currentNode.blockId",
      :sectionName="sectionName",
      :previewMode="previewMode",
      @next="nextStep"
      @showFeedbacks="showFeedbacksHandler"
      )
      template(v-slot:toolbar)
        pi-chrono.entry.entry-is-invisible(
          v-if="currentNode.settings && currentNode.settings.timeLimit",
          :start="0",
          :end="currentNode.settings.timeLimit"
          )
        .ressources.entry.desktop-pointer-none(
          @click="ressourcesModalVisibility = true"
          v-if="resources && resources.length > 0"
        )
          .entry__title.desktop-pointer-none
            i.el-icon-pi-acces_ressources.little-margin-right.font-size-2
            span
              span.mobile-display-none Ressources&nbsp;
              span x{{ resources.length }}

          template(v-for="tag in ['div.entry__content.mobile-display-none.liste.no.mobile-display-none.desktop-pointer-all', 'el-dialog']")
            component(
              :is="tag.split('.')[0]"
              :class="tag.split('.').slice(1)"
              append-to-body
              :visible.sync='ressourcesModalVisibility'
              title="Ressources"
            )
              resource-link(
                v-for="(children, index) in resources"
                :key="index"
                :courseNode="children"
                @openIt="openRessourceDialog"
              )

        .calculator.entry.is-clickable(
          v-if="currentNode.tools && currentNode.tools.calculator"
          @click="toggleCalculator"
        )
          .entry__title
            i.el-icon-pi-acces_calculette.little-margin-right.font-size-2
            span Calculatrice

    pi-resources-modal
    calculator(v-if='calculatorIsVisible', @close="toggleCalculator")
  .choose-block-node-for-multiblock.block-center(v-if="showCourseNodeBlockChoice")
    .text-align-center
      | Cette étape est un noeud de parcours, choisissez parmis les possibilités suivantes :
    .blockNodeTitleContainer
      .blockNodeTitle(
        v-for="courseNodeblock, courseNodeblockIndex in courseNode.children"
        @click="multiblockIndex = courseNodeblockIndex"
        ) {{ courseNodeblockIndex }} - {{ _get(courseNodeblock, 'block.title') }}
</template>

<script>
import store from '@/api/index.js'
import { get, hasIn, isNumber } from 'lodash'

import Calculator from './Tools/Calculator.vue'
import PiChrono from './Tools/Chrono.vue'

import PiResourcesModal from './PiResourcesModal.vue'
import ResourceLink from './ResourceLink.vue'
import piRenderCourseBlock from './RenderCourseBlock.vue'

export default {
  name: 'pi-render-node',
  components: {
    Calculator,
    PiResourcesModal,
    ResourceLink,
    piRenderCourseBlock,
    PiChrono,
  },
  mixins: [],
  props: ['id', 'previewMode'],
  data () {
    return {
      ressourcesModalVisibility: false,
      calculatorIsVisible: false,
      multiblockIndex: null,
    }
  },
  computed: {
    showCourseNodeBlockChoice () {
      return this.isMultiblock && !isNumber(this.multiblockIndex)
    },
    isMultiblock () {
      return this.courseNode.type === 'multiblock'
    },
    currentNode () {
      if (!this.courseNode) {
        return null
      }
      if (this.isMultiblock && isNumber(this.multiblockIndex)) {
        return this.courseNode.children[this.multiblockIndex]
      } else if (this.isMultiblock) {
        return null
      } else {
        return this.courseNode
      }
    },
    sectionName () {
      if (hasIn(this, 'currentNode.parent.parent.parent.title')) {
        return get(this, 'currentNode.parent.parent.parent.title', '-')
      } else if (hasIn(this, 'currentNode.parent.parent.title')) {
        return get(this, 'currentNode.parent.parent.title', '-')
      }
      return get(this, 'currentNode.parent.title', '-')
    },
  },
  watch: {
    sectionName: {
      handler (value, old) {
        this.$store.commit('SET_BLOCK_SECTION_NAME', value || '')
      },
      immediate: true,
    },
    'id' (value) {
      console.info('[ RENDER NODE ] ID : ', value)
      this.process()
    },
  },
  asyncComputed: {
    course () {
      if (get(this, 'currentNode.courseId')) {
        const id = this.currentNode.courseId
        const recordInStore = store.get('Course', id)
        if (recordInStore) {
          return recordInStore.loadRelations('type,category')
        } else {
          return store.find('Course', id, { params: { include: 'type,category' } })
            .catch((err) => { console.error(err) })
        }
      }
      return null
    },
    courseNode () {
      if (!this.id) {
        return null
      }
      // preview = 'id-preview-course-node'
      let p = Promise.resolve()
      let recordInStore = store.get('CourseNode', this.id, {})
      if (recordInStore) {
        p = p.then(() => recordInStore)
        return recordInStore.loadChildren()
          .then(() => {
            const blockIds = recordInStore.children.map(el => el.blockId)
            return store.findAll('CourseBlock', { filter: { _id: { $in: blockIds } } })
          })
          .then(() => recordInStore)
      } else {
        p = p.then(() => store.find('CourseNode', this.id)).then(record => {
          recordInStore = record
          return recordInStore
        })
      }
      return p.then(record => {
        recordInStore = record
        return recordInStore.loadRelations('parent')
      })
        .then(() => recordInStore.loadChildren({ populate: { path: 'block' } }))
        .then(() => recordInStore)
        .catch((err) => {
          console.error('[ RENDER NODE ]', err)
        })
    },
    resources () {
      if (this.id && this.currentNode) {
        return this.currentNode.loadChildren().then((records) => {
          return records.map((o) => {
            return o.toJSON()
          })
        })
      }
      return []
    },
  },
  created () {
    window._vm_render_node = this
    if (this.id) {
      this.process()
    }
  },
  methods: {
    toggleCalculator () {
      this.calculatorIsVisible = !this.calculatorIsVisible
    },
    showFeedbacksHandler () {
      this.removeResourceToDisplayInModal()
      this.calculatorIsVisible = false
    },
    nextStep (datas, clear = false) {
      this.removeResourceToDisplayInModal()
      this.course.loadSteps().then(course => {
        const nextNode = course.getNextStep(this.id)
        if (nextNode) {
          this.$router.push({ name: 'PiRenderCourseNode', params: { id: nextNode.id } })
        } else {
          this.backToHome({ clear })
        }
      })
    },
    openRessourceDialog (obj) {
      this.ressourcesModalVisibility = false
      this.setResourceToDisplayInModal(obj)
    },
    toggleListe () {
      this.active = !this.active
    },
    process () {
      this.multiblockIndex = false
      this.calculatorIsVisible = false
      this.$store.commit('MAINSOUNDPLAYER_HIDE_ALL')
    },
  },
}
</script>

<style lang="sass">
.choose-block-node-for-multiblock
  width: 400px
  .text-align-center
    color: #333
.blockNodeTitleContainer
  border-radius: 5px
  color: white
  overflow: hidden
  margin-top: 20px
  .blockNodeTitle
    padding: 10px 15px
    cursor: pointer
    background-color: #333
    &:hover
      background-color: black
    & + .blockNodeTitle
      margin-top: 1px
</style>
