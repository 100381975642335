import { get, isNil, isUndefined } from 'lodash'

export default {
  data () {
    return {
    }
  },
  methods: {
    getRatioCompetency (parentId, input) {
      if (isUndefined(input)) {
        input = this.stats
      }
      return get(input, ['computedScoring', 'competencies', parentId])
    },
    getRatioCategory (categoryId, input) {
      if (isUndefined(input)) {
        input = this.stats
      }
      return get(input, ['computedScoring', 'categories', categoryId])
    },
    getMedal (ratio) {
      if (ratio === 0 || isNil(ratio)) {
        return ''
      } else if (ratio < 0.50) {
        return 'bronze'
      } else if (ratio < 0.75) {
        return 'argent'
      }
      return 'or'
    },
    getColor (ratio) {
      if (ratio < 0.50) {
        return 'danger'
      } else if (ratio < 0.75) {
        return 'warning'
      }
      return 'success'
    },
    getMedalAccomplished (ratio, value) {
      if (isUndefined(value)) {
        value = ratio
      }
      if (ratio === 0) {
        return ''
      } else if (ratio < 0.50) {
        return (value - 0.0) * (1 / (0.5 - 0.0))
      } else if (ratio < 0.75) {
        return (value - 0.5) * (1 / (0.75 - 0.5))
      }
      return (value - 0.75) * (1 / (1 - 0.75))
    },
    getPiBadgeWithProgression (from, to) {
      const progression = (to - from)
      const conf = {
        level: this.getMedal(to), // or, argent, bronze
        done: this.getMedalAccomplished(to),
        percent: to,
        type: 'PI',
        img: this.contextLogoBadge,
        size: 'xl',
      }
      if (progression === 0) {
        return conf
      }
      let delta
      let deltaStart
      const hasChangeMedal = conf.level !== this.getMedal(from)
      if (progression >= 0) {
        if (hasChangeMedal) {
          delta = this.getMedalAccomplished(to)
          deltaStart = 0
        } else {
          delta = this.getMedalAccomplished(to) - this.getMedalAccomplished(from)
          deltaStart = this.getMedalAccomplished(from)
        }
      } else {
        if (hasChangeMedal) {
          deltaStart = this.getMedalAccomplished(to)
          delta = 1 - this.getMedalAccomplished(to)
        } else {
          deltaStart = this.getMedalAccomplished(to)
          delta = this.getMedalAccomplished(from) - this.getMedalAccomplished(to)
        }
      }
      return Object.assign({}, conf, {
        delta,
        deltaStart,
        deltaSign: progression < 0 ? -1 : 1,
      })
    },
  },
  computed: {
    stats () {
      return this.$store.getters.getCurrentUserStats
    },
    piScoreRatio () {
      return get(this.stats, ['computedScoring', 'pi', 'competencies'])
    },
    piScoreBadge () {
      return {
        level: this.getMedal(this.piScoreRatio), // or, argent, bronze
        done: this.getMedalAccomplished(this.piScoreRatio),
        percent: this.piScoreRatio,
        type: 'PI',
        img: this.contextLogoBadge,
        size: 'xl',
      }
    },
  },
}
