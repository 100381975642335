import {
  isEmpty,
  isString,
  isUndefined,
} from 'lodash'

export default {
  data () {
    return {
      randomizedVariation: 0,
    }
  },
  methods: {
    parseShortCodes (txt) {
      if (isUndefined(this.datasetsAlreadyComputed)) {
        console.warn('DATASETS UNDEFINES => parseShortCodes()')
      }
      if (isEmpty(this.datasetsAlreadyComputed)) {
        return txt
      }
      const vm = this
      const shortcodes = vm.getShortcodesFromTxt(txt)
      for (const i in shortcodes) {
        const sc = shortcodes[i]
        try {
          txt = txt.replace(sc, this.datasetsAlreadyComputed[sc])
        } catch (error) {
          console.error(`ERREUR DANS L'INTERPRETATION DES SHORTCODES => parseShortCodes(), sc = ${sc}, this.datasetsAlreadyComputed = ${this.datasetsAlreadyComputed}`)
        }
      }
      return txt
    },
    getShortcodesFromTxt (txt) {
      if (!isString(txt)) {
        return []
      }
      const scRegexp = /\[\[((?:(?!\]\])(?!\[\[).)+\]?)\]\]/ig
      const shortcodes = txt.match(scRegexp)
      return shortcodes
    },
    getOrder () {
      if (this.row.order) {
        return this.row.order
      } else {
        return this.defaultOrder
      }
    },
  },
}
