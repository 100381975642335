<template lang="pug">
  doctype html
  .rate-with-info()
    el-tooltip(:disabled="!tooltip", :content="`${result} %`", placement="top")
      el-rate(:value="score", disabled, :max="stars", allow-half, :disabled-void-color="color")
</template>

<script>
export default {
  name: 'rate-with-info',
  components: {},
  mixins: [],
  props: [
    'percent',
    'message',
    'tooltip',
    'theme',
  ],
  data () {
    return {
      stars: 3,
      precision: 1,
    }
  },
  asyncComputed: {},
  computed: {
    color () {
      return this.theme === 'dark' ? 'rgba( 255, 255, 255, .3)' : 'rgba( 0, 0, 0, .2)'
    },
    score () {
      return Math.floor(this.percent * this.stars * 2) / 2
    },
    result () {
      const precision = Math.pow(10, this.precision)
      return Math.floor(this.percent * 100 * precision) / precision
    },
  },
}
</script>

<style lang="sass">
.rate-with-info
  .el-rate__item
    margin-top: 7px
    margin-bottom: 7px
</style>
